import React, { useEffect, useState } from "react";
import BirthsForm from "../../../../Forms/BirthsForm";
import "./styles.css";
import { gestationInfoByID } from "../../../../../servicces";
import translateDate from '../../../../../utils/translateDate'

const Modal = ({ openCloseModal,  gestationId }) => {
  const [loading, setLoading] = useState(true);
  const [gestationInfo, setgestationInfo] = useState();
  useEffect(() => {
    const getInfo = async () => {
      try {
        const result = await gestationInfoByID(gestationId);
        setgestationInfo(result.data.gestation);
        setLoading(false);
      } catch (error) {
        console.log(error);
      }
    };
    if (gestationId !== "") {
      getInfo();
    }
  }, [gestationId]);

  const handleClose = () => {
    openCloseModal();
  };
  
  return (
    <div className="ModalContainer">
      <div className="FormContainer">
        <div onClick={handleClose} className="openCloseModal">
          <p>X</p>
        </div>
        <div className="titleFormPalpation">
          <h2 className="title">Gestacion</h2>
        </div>
        {loading ? (
          <div>CARGANDO</div>
        ) : (
          <form name="currentForm" className="currentForm">
            <div className='label-gestation'>
              <label>
                Fecha de reproducción: {" "}  
              </label>
                {translateDate(gestationInfo.palpation.date) }
            </div>
            <div className='label-gestation'>
              <label>
                Meses de gestación: {" "}  
              </label>
                {gestationInfo.months}
            </div>
            <div className='label-gestation'>
              <label>
                Palpada: {" "}  
              </label>
                {gestationInfo.palpation.palpated}
            </div>
            <div className='label-gestation'>
              <label>
                Confirmación: {" "}  
              </label>
                {gestationInfo.palpation.confirmation ?? "sin confirmacion"}
            </div>
            <div className='label-gestation'>
              <label>
                Observaciones: {" "}  
              </label>
                {gestationInfo.palpation.observations ?? "sin observaciones"}
            </div>
            <div className='label-gestation'>
              <label>
                Metodo de reproducción: {" "}  
              </label>
                {gestationInfo.palpation.reproduction}
            </div>
            <div className='label-gestation'>
              <label>
                Semen: {" "}  
              </label>
                {gestationInfo.palpation.semen ?? 'sin informacion'}
            </div>
            <div className='label-gestation'>
              <label>
                SINIIGA M: {" "}  
              </label>
                {gestationInfo.palpation.siniigaM ?? 'Sin siniiga'}
            </div>
            <div className="contentDinamicForm">
              <BirthsForm cowID={gestationId}  handleClose={handleClose} />
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export default Modal;
