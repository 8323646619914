import React from 'react';
import GestacionProvider from '../Context/GestacionStore/context'
import GestacionComponent from '../Components/Main/Gestacion'
import Hato from '../layout/Hato_Theme';


const Palpacion = ({children,match}) => {
  return (
    <>
    <Hato>
      <GestacionProvider children={children}>
        <GestacionComponent match={match}/>
      </GestacionProvider>
    </Hato>
    </>
  )
}

export default Palpacion
