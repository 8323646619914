import React, { createContext, useEffect, useState } from 'react';
import { withRouter, useLocation } from 'react-router-dom';
import {
  getDataIDCabezaService,
  getPupsListService,
  sendNewCabezaService,
  getUrlFromRanch,
  createSiniiga,
  deleteSiniiga,
  updateSiniiga,
  getCalves,
} from '../../servicces';
import Store from './store';
export const ListadoDeCriasContext = createContext(Store);
const ListadoDeCriasProvider = ({ children }) => {
  const { pathname } = useLocation();
  const splitPathname = pathname.split('/');
  const motherID = splitPathname[2];
  const [info, setInfo] = useState([]);
  const [isOpenModalGanado, setisOpenModalGanado] = useState(false);
  const [RanchoID, setRanchoID] = useState(null);
  const [Cabeza, setCabeza] = useState(Store.Cabeza);
  const [Cabezas, setCabezas] = useState([]);
  const [CabezasRow, setCabezasRow] = useState([]);
  const [isOpenModalDetails, setisOpenModalDetails] = useState(false);
  const [urlExcel, seturlExcel] = useState('');
  const [dataFromID, setdataFromID] = useState([]);
  const [listGanado, setListGanado] = useState([]);
  const [loading, setLoading] = useState(false);
  const [paginationInfo, setpaginationInfo] = useState();
  const [inputSearch, setinputSearch] = useState('');
  const [breedFilter, setBreedFilter] = useState('');
  const [sexFilter, setsexFilter] = useState('');
  const [periodFilter, setPeriodFilter] = useState('');
  const [statusFilter, setstatusFilter] = useState('');
  const [submitErrors, setsubmitErrors] = useState('');
  const [page, setPage] = useState(1);
  //siniiga
  const initSiniiga = {
    id: '',
    livestockId: '',
    siniiga: '',
  };

  const [modalnewSiniiga, setmodalnewSiniiga] = useState(false);
  const [siniigaModel, setsiniigaModel] = useState(initSiniiga);
  //endsiniiga
  const configData = {
    page: 1,
    date: '',
    limit: 10,
    id: motherID,
  };
  const getInitialData = async ({ page, limit, id, period }) => {
    setLoading(true);
    if (!id) {
      setRanchoID(id);
    }
    try {
      const response = await getCalves({
        page,
        period,
        limit,
        livestock: id,
      });
      setInfo(response.data.info);
      setpaginationInfo(response.data.data);
      setListGanado(response.data.data.calves);
      setLoading(false);
    } catch (error) {
      console.log('error context ranch', error.response);
    }
  };

  const handleCowFormInputs = ({ name, value }) => {
    setCabeza((prev) => {
      return { ...prev, [name]: value };
    });
  };

  const submitNewCow = async () => {
    setsubmitErrors('');
    try {
      const response = await sendNewCabezaService(Cabeza);
      if (response.data.status) {
        getInitialData(configData);
        getDownloadUrl();
        setisOpenModalGanado(false);
      }
    } catch (error) {
      setsubmitErrors(error.response.data.msg);
      console.log(error.response);
    }
  };

  const detailsCabeza = async (id) => {
    const response = await getDataIDCabezaService(id);
    if (response.data.status === true) {
      setdataFromID(response.data);
    }
  };

  const openModalDetails = (booleano, id) => {
    setisOpenModalDetails(booleano);
    if (booleano) {
      detailsCabeza(id);
    }
  };

  const filterRaza = (e) => {
    if (e.target.value === '') getInitialData(configData);
    setListGanado({
      ...listGanado,
      livestocks: listGanado.livestocks.filter((el) => el.breed.name === e.target.value),
    });
  };
  const filterSexo = (sexo) => setCabeza(CabezasRow.filter((el) => el.Sexo === sexo));
  const filterSttatus = (status) => setCabeza(CabezasRow.filter((el) => el.Statuds === status));
  const filterRangoDeNacimiento = (fecha) =>
    setCabeza((el) => new Date(el.Fecha).getTime() > new Date(fecha));

  useEffect(() => {
    getInitialData(configData);
  }, []);

  const getDownloadUrl = async () => {
    try {
      const result = await getUrlFromRanch(motherID);
      seturlExcel(result.data.filename);
    } catch (error) {
      seturlExcel(null);
      console.log(error.response);
    }
  };

  const handleSiniigaValue = ({ target: { name, value } }) => {
    setsiniigaModel({
      ...siniigaModel,
      [name]: value,
    });
  };

  const handleSubmitSiniigaForm = async () => {
    //create
    if (siniigaModel.id == '') {
      try {
        const response = await createSiniiga(siniigaModel);
        if (response.status == 200) {
          setmodalnewSiniiga(false);
        }
      } catch (ex) {}
    } else {
      try {
        const response = await updateSiniiga(siniigaModel);
      } catch (ex) {}
    }
  };

  const handleDeleteSiniiga = async (siiniga, livestock) => {
    const response = await deleteSiniiga(siiniga, livestock);
    console.log(response);
  };

  const state = {
    handleCowFormInputs,
    submitNewCow,
    openModalDetails,
    setisOpenModalGanado,
    filterRaza,
    filterSexo,
    filterSttatus,
    filterRangoDeNacimiento,
    submitErrors,
    isOpenModalGanado,
    Cabezas,
    info,
    isOpenModalDetails,
    dataFromID,
    getInitialData,
    listGanado,
    loading,
    setLoading,
    paginationInfo,
    setpaginationInfo,
    page,
    setPage,
    inputSearch,
    setinputSearch,
    breedFilter,
    setBreedFilter,
    periodFilter,
    setPeriodFilter,
    sexFilter,
    setsexFilter,
    statusFilter,
    setstatusFilter,
    setCabeza,
    urlExcel,
    seturlExcel,
    getDownloadUrl,

    modalnewSiniiga,
    setmodalnewSiniiga,
    siniigaModel,
    setsiniigaModel,
    initSiniiga,
    handleSiniigaValue,
    handleSubmitSiniigaForm,
    handleDeleteSiniiga,
  };

  useEffect(() => {}, [info, isOpenModalDetails, dataFromID]);

  return <ListadoDeCriasContext.Provider value={state}>{children}</ListadoDeCriasContext.Provider>;
};

export default withRouter(ListadoDeCriasProvider);
