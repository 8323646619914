import React from 'react';
import ReactDOM from 'react-dom';
import Routes from './Router';
import { BrowserRouter } from 'react-router-dom';
import MainContext from './MainContext';
import './index.css'
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from "react-toastify";

const AppHato = () => {
  return (
    <BrowserRouter>
      <MainContext>
        <Routes />
        <ToastContainer />
      </MainContext>
    </BrowserRouter>
  )
}

ReactDOM.render(
  <AppHato />,
  document.getElementById('root')
);


