import React, { useContext } from 'react';
import { GestacionContext } from '../../../Context/GestacionStore/context';
import GestationTable from './GestationTable';

import CircularProgress from '@mui/material/CircularProgress';
import InputSearch from './InputSearch/InputSearch';
import BreedSelector from './BreedSelector/BreedSelector';
import StatusSelector from './StatusSelector/StatusSelector';
import RanchSelector from './RanchSelector/RanchSelector';

import './styles.css';
import Pagination from '@mui/material/Pagination';
import { useLocation, useHistory } from 'react-router-dom';

const Gestacion = ({ item, match }) => {
  const { pathname } = useLocation();
  const history = useHistory();
  const splitPathname = pathname.split('/');
  const ranchID = splitPathname[2];
  const GestacionCtx = useContext(GestacionContext);
  const {
    getInitialData,
    listGanado,
    loading,
    setLoading,
    paginationInfo,
    inputSearch,
    page,
    setPage,
    breedFilter,
    sexFilter,
    statusFilter,
  } = GestacionCtx;

  const getId = (e) => {
    history.push(`Gestacion/${e}`);
  };
  const handleChangePage = (_, newPage) => {
    setLoading(true);
    setPage(newPage);
    const config = {
      page: newPage,
      limit: 10,
      id: ranchID,
      search_text: inputSearch,
      sex: sexFilter,
      breed: breedFilter,
      status: statusFilter,
    };
    getInitialData(config);
  };

  return (
    <div className="MainDetailsComponentContainer">
      <h1 className="misRanchos">Gestación</h1>
      <div className="ranch-filters-row">
        <div className="mainContainerFiltersDetails">
          <InputSearch />
          <RanchSelector />
          <BreedSelector />
          <StatusSelector />
        </div>
      </div>
      {loading ? (
        <div className="loading">
          <CircularProgress color="warning" size={100} />
        </div>
      ) : (
        <GestationTable getIdGanadero={getId} tablesInfo={listGanado} />
      )}
      <div className="ganaderos-pagination">
        <Pagination
          count={paginationInfo?.totalPages}
          page={page}
          onChange={handleChangePage}
          shape="rounded"
        />
        <label style={{ marginTop: '7px' }}>
          Ganado en total : {paginationInfo?.totalLivestocks}
        </label>
      </div>
    </div>
  );
};

export default Gestacion;
