import React, { useEffect, useContext, useState } from 'react';
import './styles.css';
import { birthsForm } from '../../constants';
import { birhtStatusOptions, Ganado, add } from '../../constants';
import Store from '../../../Context/GestacionStore/store';
import { GestacionContext } from '../../../Context/GestacionStore/context';

const BirthsForm = ({ isEditableForm, values, cowID }) => {
  const GestacionCtx = useContext(GestacionContext);
  const { historialErros, newHistorialValues, setNewHistorialValues, submitNewBirth } =
    GestacionCtx;

  useEffect(() => {
    setNewHistorialValues((prev) => {
      return { ...prev, livestockId: cowID };
    });
    return () => {
      setNewHistorialValues(Store.Parto);
    };
  }, []);

  const returnValuesName = (name) => {
    if (name === 'Fecha') {
      return new Date(values[name]).toISOString().split('T')[0];
    }
    return values[name];
  };

  const handleChange = (e) => {
    setNewHistorialValues((prev) => {
      //console.log( ...prev,  e.target.value)
      return { ...prev, [e.target.name]: e.target.value };
    });
  };

 
  return (
    <>
      <div className="inputsPalpacionContainer">
        <div className="titleFormPalpation">
          <h2 className="title">{birthsForm.title}</h2>
        </div>
        <div className="inputsPalpaptionContent">
          {birthsForm.form.map((el, i) => (
            <div key={`key-${i}-${el.label}`} className="eachContainerInputs">
              <div className="labelsContainerPalpation">
                <img src={el.icon} alt={el.label} />
                <label className="labelInputPalpation">{el.label}</label>
              </div>
              <div>
                {el.type === 'text' || el.type === 'date' ? (
                  <input
                    defaultValue={isEditableForm ? returnValuesName(el.name) : ''}
                    value={newHistorialValues.dueDate}
                    className="inputPalpation"
                    type={el.type}
                    name={el.name}
                    onChange={handleChange}
                    required={true}
                  />
                ) : (
                  <select
                    className="create-cow-selector"
                    style={{ width: '100%' }}
                    value={newHistorialValues.status}
                    name={el.name}
                    onChange={handleChange}
                  >
                    <option value="">Seleccion el status</option>
                    {birhtStatusOptions.map((item, k) => (
                      <option key={k} value={item.value}>
                        {item.label}
                      </option>
                    ))}
                  </select>
                )}
              </div>
            </div>
          ))}
        </div>
        {historialErros !== '' && <label style={{ color: 'red' }}>{historialErros}</label>}
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          marginTop: '10px',
        }}
      >
        <button
          style={{ margin: '0' }}
          type="button"
          className={`newRanchButtonAccept`}
          onClick={submitNewBirth}
        >
          Confirmar parto
        </button>
      </div>
    </> 
  );
};

export default BirthsForm;
