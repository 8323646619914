import React  from 'react'
import { Link } from 'react-router-dom'
import { arrowdown, logo } from '../constants'
import './styles.css'

function Banner({opencloseFunction, userInSession}) {


  return (
    <div className="bannerContainer" >
      <div className="logoBannerContainer">
        <Link to='/Rancho'>
        <img src={logo} alt="Hato"/>
        </Link>
      </div>
      <div className="menuContainer">

        <div className="menuContent">
          <span className="letterContent">{ userInSession?.charAt(0)?.toUpperCase() }</span>
            <p className="userName">{userInSession}</p>
        </div>

        <span 
        className="arrowMenu" 
        title="arrow" 
        onClick={( e ) => opencloseFunction(e)}>

          <img src={arrowdown} alt="show menu"/>
        </span>

      </div>
    </div>
  )
}
 
export default Banner
