import React, { useContext, useEffect, memo } from "react";
import { find } from "../../constants";
import "./styles.css";
import debounce from "lodash.debounce";
import { GanaderosContext } from "../../../Context/GanaderosStore/context";
import { PieContext } from "../../../Context/PieCria/Context";

const SearchBox = memo(() => {
  const detailsCtx = useContext(PieContext);
  const {
    getInitialData,
    setPage,
    inputSearch,
    setLoading,
    setinputSearch,
    monthInputSearch
  } = detailsCtx;
  const searchFunction = async (config) => {
    setLoading(true);
    setPage(1);
    getInitialData(config);
  };
  const debouncedSearch = React.useRef(
    debounce(async (config) => {
      await searchFunction(config);
    }, 300)
  ).current;
  async function handleChange(e) {
    setinputSearch(e.target.value);
    const config = {
      page: 1,
      limit: 5,
      search_text: e.target.value,
      search_months:monthInputSearch
    };
    debouncedSearch(config);
  }
  useEffect(() => {
    return () => {
      debouncedSearch.cancel();
    };
  }, [debouncedSearch]);
  return (
    <div className="inpurSearchBoxGanaderos">
      <img className="findIcon" src={find} alt="Find" />
      <input
        className="findInput"
        type='number'
        min={0}
        value={inputSearch}
        name="searchBox"
        placeholder="Buscar por SINIIGA"
        onChange={handleChange}
      />
    </div>
  );
});
export default SearchBox;
