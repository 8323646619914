import React, { useState, useContext, useEffect } from "react";
import {
  updateFieldCabezaService,
  newGanaderosPetition,
  InitialDataPetitionGanaderos,
  updateGanaderosService,
  getBreeds,
  InitialDataPetition,
  getAllGanaderos,
  disableGanaderoService,
} from "../../../../servicces";
import { edit } from "../../../constants";
import "./styles.css";
import { DetailsContext } from "../../../../Context/Details/context";
import { PieContext } from "../../../../Context/PieCria/Context";
import { sexoOptions, statusOptions } from "../../../constants";
import {  toast } from "react-toastify";

const ModalDetails = ({ closeFunction, data, getInitialData, getId,configGetPages }) => {
  const success = (text) => toast.success(text, { autoClose: 3000 });
  const Error = (text) => toast.error(text, { autoClose: 3000 });

  const pieContext = useContext(PieContext);
  const datailsCtx = useContext(DetailsContext);
  const { page, inputSearch } = pieContext;
  const [nameInput, setnameInput] = useState("");
  const [palpacion, setpalpacion] = useState("");
  const [Observaciones, setObservaciones] = useState("");
  const [criaDetails, setcriaDetails] = useState(null);
  const [ranchosSelect, setRanchosSelect] = useState([]);
  const [breedSelect, setbreedSelect] = useState([]);
  const [ranchersSelect, setranchersSelect] = useState([]);
  const [loading, setloading] = useState(true);

  const getInitialFormData = async (data) => {
    try {
      if (data !== null) {
        const ranchersResponse = await InitialDataPetition({
          page: 1,
          limit: 100,
          search_text: "",
        });
        const breedResponse = await getBreeds();
        const ranchersRespose = await getAllGanaderos();
        setranchersSelect(ranchersRespose.data.data.ranchers);
        setbreedSelect(breedResponse.data.data.breeds);
        setRanchosSelect(ranchersResponse.data.data.ranchs);
        setcriaDetails(data.livestock);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getInitialFormData(data);
    return () => {
      setcriaDetails(null);
      setloading(true);
    };
  }, [data]);

  useEffect(() => {
    if (criaDetails) {
      setloading(false);
    }
  }, [criaDetails]);

  const updateField = async (e) => {
    if (e.target.value === "" || e.target.value < 3) {
      setnameInput("");
      return;
    }
    if (
      e.target.name == "Palpacion" &&
      e.target.value.length < 3 &&
      Observaciones.length < 3
    ) {
      return;
    }
    const data = {
      [e.target.name]: e.target.value,
    };
    try {
      const response = await updateFieldCabezaService(data, criaDetails._id);
      if (response.data.status) {
        let config = {
          page,
          limit: 5,
          search_text:inputSearch,
        }
        if(configGetPages) config = configGetPages
        getInitialData(config);
        if (e.target.name === "ranch" || e.target.name === "status") {
          closeFunction();
          return;
        }
        getId(criaDetails._id);
      }
      success(`Los campos fueron guardados correctamente`);

    } catch (error) {
      console.log('edit cow',error.response);
      Error(error.response.data.msg)
    }
    setnameInput("");
    return document.removeEventListener("keyup", eventEmitter, false);
  };

  function eventEmitter(e) {
    if (e.code === "Enter") return updateField(e);
    if (e.code === "Escape") {
      document.removeEventListener("keyup", eventEmitter, false);
      return setnameInput("");
    }
  }

  const getNameInput = (e) => {
    setnameInput(e.target.title);
    document.addEventListener("keyup", eventEmitter, false);
  };

  const selectChange = async (e) => {
    updateField(e);
  };

  if (loading)
    return (
      <div className="modalDetails">
        <div className="contentInfo"> loading data.. </div>
      </div>
    );
  if (!criaDetails)
    return (
      <div className="modalDetails">
        <div className="contentInfo"> no data</div>
      </div>
    );
  return (
    <div className="modalDetails">
      <div className="contentInfo">
        <div className="openCloseSection">
          <h3> Siniiga : {criaDetails.siniiga}</h3>
          <p className="close" onClick={closeFunction}>
            X
          </p>
        </div>

        <div className="contentData">
          {/* COLUMNA 1 */}
          <div className="columnDetails">
            <div className="contentEachField">
              <h5>
                Rancho{" "}
                <img
                  title="ranch"
                  onClick={(e) => getNameInput(e)}
                  src={edit}
                  alt="Editar"
                />{" "}
              </h5>
              {nameInput !== "ranch" && <p>{criaDetails.ranch.name}</p>}

              {nameInput === "ranch" && (
                <select
                  name="ranch"
                  onChange={selectChange}
                  className="selectDetails"
                >
                  <option value="">Selecciona</option>
                  {ranchosSelect.map((optRanch, i) => (
                    <option
                      key={i}
                      value={optRanch._id}
                      selected={optRanch._id === criaDetails.ranch._id}
                    >
                      {optRanch.name}
                    </option>
                  ))}
                </select>
              )}
            </div>
            <div className="contentEachField">
              <h5>
                Raza{" "}
                <img
                  src={edit}
                  title="breed"
                  onClick={(e) => getNameInput(e)}
                />{" "}
              </h5>
              {nameInput !== "breed" && <p>{criaDetails.breed.name}</p>}

              {nameInput === "breed" && (
                <select
                  name="breed"
                  onChange={selectChange}
                  className="selectDetails"
                >
                  <option value="">Selecciona</option>
                  {breedSelect.map((breed, i) => (
                    <option
                      key={i}
                      value={breed._id}
                      selected={breed._id === criaDetails.breed._id}
                    >
                      {breed.name}
                    </option>
                  ))}
                </select>
              )}
            </div>
            <div className="contentEachField">
              <h5>
                Ganadero{" "}
                <img
                  src={edit}
                  title="rancher"
                  onClick={(e) => getNameInput(e)}
                />{" "}
              </h5>
              {nameInput !== "rancher" && <p>{criaDetails.rancher.name}</p>}

              {nameInput === "rancher" && (
                <select
                  name="rancher"
                  onChange={selectChange}
                  className="selectDetails"
                >
                  <option value="">Selecciona</option>
                  {ranchersSelect.map((rancher, i) => (
                    <option
                      key={i}
                      value={rancher._id}
                      selected={rancher._id === criaDetails.rancher._id}
                    >
                      {rancher.name}
                    </option>
                  ))}
                </select>
              )}
            </div>
          </div>
          {/* COLUMNA 2 */}
          <div className="columnDetails">
            <div className="contentEachField">
              <h5>
                Sexo
                <img
                  src={edit}
                  title="sex"
                  onClick={(e) => getNameInput(e)}
                  alt="edit"
                />{" "}
              </h5>
              {nameInput !== "sex" && (
                <p>{criaDetails.sex === "H" ? "Hembra" : "Macho"}</p>
              )}

              {nameInput === "sex" && (
                <select
                  name="sex"
                  onChange={selectChange}
                  className="selectDetails"
                >
                  <option value="">Selecciona</option>
                  {sexoOptions.map((sex, i) => (
                    <option
                      key={i}
                      value={sex.value}
                      selected={sex.value === criaDetails.sex}
                    >
                      {sex.label}
                    </option>
                  ))}
                </select>
              )}
            </div>

            <div className="contentEachField">
              <h5>
                {" "}
                Estatus{" "}
                <img
                  src={edit}
                  title="status"
                  alt="edit"
                  onClick={(e) => getNameInput(e)}
                />{" "}
              </h5>
              {nameInput !== "status" && <p>{criaDetails.status}</p>}

              {nameInput === "status" && (
                <select
                  name="status"
                  onChange={selectChange}
                  className="selectDetails"
                >
                  <option value="">Selecciona</option>
                  {statusOptions.map(
                    (stat, i) => (
                      /* data.Cabeza.Sexo === "H" && el.Nombre === "Semental" ? (
                        <></>
                      ) : el.Nombre === "Cría" &&
                        new Date(criaDetails.date).getTime() <
                          new Date(
                            new Date().getTime() - 3600 * 1000 * 24 * 360
                          ).getTime() ? (
                        <></>
                      ) : el.Nombre === "Vacía" &&
                        new Date(criaDetails.date).getTime() >
                          new Date(
                            new Date().getTime() - 3600 * 1000 * 24 * 360
                          ).getTime() ? (
                        <></>
                      ) : el.Nombre === "Cargada" &&
                        new Date(criaDetails.date).getTime() >
                          new Date(
                            new Date().getTime() - 3600 * 1000 * 24 * 360
                          ).getTime() ? (
                        <></>
                      ) : ( */
                      <option
                        key={i}
                        value={stat.value}
                        selected={stat.value === criaDetails.status}
                      >
                        {stat.label}
                      </option>
                    )
                    /* ) */
                  )}
                </select>
              )}
            </div>

            <div className="contentEachField">
              <h5>
                Fecha de nacimiento{" "}
                <img
                  src={edit}
                  title="date"
                  onClick={(e) => getNameInput(e)}
                  alt="edit"
                />{" "}
              </h5>
              {nameInput !== "date" && (
                <p>
                  {new Date(criaDetails.date).toLocaleDateString("es-MX", {
                    year: "numeric",
                    month: "long",
                    day: "2-digit",
                    timeZone: "UTC",
                  })}
                </p>
              )}
              {nameInput === "date" && (
                <input type="date" name="date" onBlur={(e) => updateField(e)} />
              )}
            </div>
          </div>
          {/* COLUMNA 3 */}
          <div className="columnDetails">
            {/* {criaDetails.status === "Cargada" &&
              criaDetails.sex !== "M" &&
              new Date(criaDetails.date).getTime() <
                new Date().getTime() - 3600 * 1000 * 24 * 360 && (
                <div className="contentEachField">
                  <h5>
                    Fecha de monta{" "}
                    <img
                      src={edit}
                      title="Monta"
                      onClick={(e) => getNameInput(e)}
                      alt="edit"
                    />{" "}
                  </h5>
                  {nameInput !== "Monta" && (
                    <p>
                      {new Date(data?.Cabeza?.Monta).toLocaleDateString(
                        "es-MX",
                        {
                          year: "numeric",
                          month: "long",
                          day: "2-digit",
                          timeZone: "UTC",
                        }
                      )}
                    </p>
                  )}
                  {nameInput === "Monta" && (
                    <input
                      type="date"
                      name="Monta"
                      defaultValue={new Date(
                        data?.Cabeza?.Monta
                      ).toLocaleString()}
                      onBlur={(e) => updateField(e)}
                    />
                  )}
                </div>
              )} */}

            {/* {criaDetails.status === "Vacía" &&
              criaDetails.sex !== "M" &&
              new Date(criaDetails.date).getTime() <
                new Date().getTime() - 3600 * 1000 * 24 * 360 && (
                <div className="contentEachField">
                  <h5>
                    {" "}
                    Ultimo parto{" "}
                    <img
                      src={edit}
                      title="Parto"
                      onClick={(e) => getNameInput(e)}
                      alt="edit"
                    />{" "}
                  </h5>
                  {nameInput !== "Parto" && (
                    <p>
                      {new Date(data?.Cabeza?.Parto).toLocaleDateString(
                        "es-MX",
                        {
                          year: "numeric",
                          month: "long",
                          day: "2-digit",
                          timeZone: "UTC",
                        }
                      )}
                    </p>
                  )}
                  {nameInput === "Parto" && (
                    <input
                      type="date"
                      name="Parto"
                      defaultValue={new Date(
                        data?.Cabeza?.Parto
                      ).toLocaleString()}
                      onBlur={(e) => updateField(e)}
                    />
                  )}
                </div>
              )} */}

            {/* {criaDetails.sex === "H" &&
              criaDetails.sex !== "M" &&
              new Date(criaDetails.date).getTime() <
                new Date(new Date().getTime() - 3600 * 1000 * 24 * 360) && (
                <div className="contentEachField">
                  {data?.Cabeza.Status === "Cargada" && (
                    <>
                      <h5> Días de gestación </h5>
                      <p>
                        Días:{" "}
                        {Math.round(
                          (new Date().getTime() -
                            new Date(data?.Cabeza?.Monta).getTime()) /
                            (1000 * 3600 * 24)
                        )}{" "}
                        - Meses:{" "}
                        {(
                          (new Date().getTime() -
                            new Date(data?.Cabeza?.Monta).getTime()) /
                          (1000 * 3600 * 24) /
                          30
                        ).toFixed(1)}{" "}
                      </p>
                    </>
                  )}
                  {data?.Cabeza.Status === "Vacía" && (
                    <>
                      <h5> Ultimo parto </h5>
                      <p>
                        Días:{" "}
                        {Math.round(
                          (new Date().getTime() -
                            new Date(data?.Cabeza?.Parto).getTime()) /
                            (1000 * 3600 * 24)
                        )}{" "}
                        - Meses:{" "}
                        {(
                          (new Date().getTime() -
                            new Date(data?.Cabeza?.Parto).getTime()) /
                          (1000 * 3600 * 24) /
                          30
                        ).toFixed(1)}{" "}
                      </p>
                    </>
                  )}
                </div>
              )} */}
            <div className="contentEachField">
              <h5>
                {" "}
                Observaciones{" "}
                <img
                  src={edit}
                  title="observations"
                  onClick={(e) => getNameInput(e)}
                  alt="edit"
                />{" "}
              </h5>
              {nameInput !== "observations" && (
                <p>
                  {criaDetails.observations ?? "sin observaciones agregadas"}
                </p>
              )}
              {nameInput === "observations" && (
                <textarea
                  type="text"
                  defaultValue={criaDetails.observations}
                  rows="10"
                  maxLength={250}
                  name="observations"
                  onBlur={(e) => updateField(e)}
                />
              )}
            </div>
          </div>
        </div>

        <div style={{ border: "none" }} className="contentData">
          <div className="columnDetails">
            <div className="contentEachField">
              <h5>
                Kg al nacimiento{" "}
                <img
                  title="kg_birth"
                  onClick={(e) => getNameInput(e)}
                  src={edit}
                  alt="Editar"
                />{" "}
              </h5>
              {nameInput !== "kg_birth" && (
                <p>{criaDetails.kg_birth ?? "agregar kg al nacimiento"}</p>
              )}
              {nameInput === "kg_birth" && (
                <input
                  type="number"
                  name="kg_birth"
                  onBlur={(e) => updateField(e)}
                />
              )}
            </div>
            <div className="contentEachField">
              <h5>
                Kg al destete{" "}
                <img
                  src={edit}
                  title="kg_weaning"
                  onClick={(e) => getNameInput(e)}
                />{" "}
              </h5>
              {nameInput !== "kg_weaning" && (
                <p>{criaDetails.kg_weaning ?? "agregar kg al destete"}</p>
              )}
              {nameInput === "kg_weaning" && (
                <input
                  type="number"
                  name="kg_weaning"
                  onBlur={(e) => updateField(e)}
                />
              )}
            </div>
            <div className="contentEachField">
              <h5>
                Kg al año{" "}
                <img
                  src={edit}
                  title="kg_annual"
                  onClick={(e) => getNameInput(e)}
                />{" "}
              </h5>
              {nameInput !== "kg_annual" && (
                <p>{criaDetails.kg_annual ?? "agregar kg al año"}</p>
              )}
              {nameInput === "kg_annual" && (
                <input
                  type="number"
                  name="kg_annual"
                  onBlur={(e) => updateField(e)}
                />
              )}
            </div>
          </div>
        </div>

        {/* <div className="contentData"> */}

        {/* <div className="columnDetailsProgenitores">
                            <h4> FAMILIA </h4>
                        <div className="titlesProgenitores">
                            <h5 > Siniiga       </h5>
                            <h5 > Rancho        </h5>
                            <h5 > Ganadero      </h5>
                            <h5 > Pedigree      </h5>
                            <h5>  Sexo          </h5>
                            <h5>  Procedencia   </h5> */}

        {/* { nameInput !== 'KgNacimiento' &&<p>{data?.Cabeza?.KgNacimiento}</p>} */}

        {/* { nameInput === 'KgNacimiento' && <input type="text"  name="KgNacimiento"  onBlur={(e) =>updateField(e)}/> } */}

        {/* </div> */}

        {/* <div className="rowProgenitores">
                            <h5 > 360345-1174   </h5>
                            <h5 > Rancho        </h5>
                            <h5 > 3A             </h5>
                            <h5 > Pedigree      </h5>
                            <h5 > H          </h5>
                            <h5 > Procedencia   </h5> */}

        {/* { nameInput !== 'KgNacimiento' &&<p>{data?.Cabeza?.KgNacimiento}</p>} */}

        {/* { nameInput === 'KgNacimiento' && <input type="text"  name="KgNacimiento"  onBlur={(e) =>updateField(e)}/> } */}

        {/* </div> */}
        {/* <div className="rowProgenitores">
                            <h5> 306518-5527   </h5>
                            <h5> Rancho        </h5>
                            <h5> 3A      </h5>
                            <h5> Pedigree      </h5>
                            <h5> M          </h5>
                            <h5> Procedencia   </h5> */}

        {/* { nameInput !== 'KgNacimiento' &&<p>{data?.Cabeza?.KgNacimiento}</p>} */}

        {/* { nameInput === 'KgNacimiento' && <input type="text"  name="KgNacimiento"  onBlur={(e) =>updateField(e)}/> } */}

        {/* </div> */}
        {/* <div className="contentEachField">
                            <h5>Kg al destete  <img src={edit} title="KgNacimientoDestete" onClick={(e)=>getNameInput(e)} /> </h5>
                            { nameInput !== 'KgNacimientoDestete' &&<p>{data?.Cabeza?.KgNacimientoDestete}</p>}
                            { nameInput === 'KgNacimientoDestete' &&  <input type="text"  name="KgNacimientoDestete"  onBlur={(e) =>updateField(e)}/> }

                        </div>
                        <div className="contentEachField">
                            <h5>Kg al año <img src={edit} title="KgAnual" onClick={(e)=>getNameInput(e)}/> </h5>
                            { nameInput !== 'KgAnual' &&<p>{data?.Cabeza?.KgAnual}</p>}
                            { nameInput === 'KgAnual' &&   <input type="text"  name="KgAnual"  onBlur={(e) =>updateField(e)}/> }                
                        </div> */}
        {/* </div> */}
        {/* </div> */}

        {/* <div className="contentData">

<div className="columnDetailsCartilla">
        <h4> CARTILLA / VACUNAS </h4>
    <div className="titlesCartila">
        <h5>  NOMBRE    </h5>
        <h5>   VÍA      </h5>
        <h5>  DÓSIS	    </h5>
        <h5>  X KG PESO </h5> */}

        {/* { nameInput !== 'KgNacimiento' &&<p>{data?.Cabeza?.KgNacimiento}</p>} */}

        {/* { nameInput === 'KgNacimiento' && <input type="text"  name="KgNacimiento"  onBlur={(e) =>updateField(e)}/> } */}

        {/* </div>

    <div className="rowCartila">
        <h5 > 3INTRANASAL TSV-3   </h5>
        <h5 > Subcutanea        </h5>
        <h5 > 3ml             </h5>
        <h5 > 3ml x kg </h5> */}

        {/* { nameInput !== 'KgNacimiento' &&<p>{data?.Cabeza?.KgNacimiento}</p>} */}

        {/* { nameInput === 'KgNacimiento' && <input type="text"  name="KgNacimiento"  onBlur={(e) =>updateField(e)}/> } */}
        {/* 
    </div>
    <div className="rowCartila">
        <h5> CATOSAL   </h5>
        <h5> Oral        </h5>
        <h5> 12ml      </h5>
        <h5> 3mg x Kg      </h5> */}

        {/* { nameInput !== 'KgNacimiento' &&<p>{data?.Cabeza?.KgNacimiento}</p>} */}

        {/* { nameInput === 'KgNacimiento' && <input type="text"  name="KgNacimiento"  onBlur={(e) =>updateField(e)}/> } */}

        {/* </div> */}
        {/* <div className="contentEachField">
        <h5>Kg al destete  <img src={edit} title="KgNacimientoDestete" onClick={(e)=>getNameInput(e)} /> </h5>
        { nameInput !== 'KgNacimientoDestete' &&<p>{data?.Cabeza?.KgNacimientoDestete}</p>}
        { nameInput === 'KgNacimientoDestete' &&  <input type="text"  name="KgNacimientoDestete"  onBlur={(e) =>updateField(e)}/> }

    </div>
    <div className="contentEachField">
        <h5>Kg al año <img src={edit} title="KgAnual" onClick={(e)=>getNameInput(e)}/> </h5>
        { nameInput !== 'KgAnual' &&<p>{data?.Cabeza?.KgAnual}</p>}
        { nameInput === 'KgAnual' &&   <input type="text"  name="KgAnual"  onBlur={(e) =>updateField(e)}/> }                
    </div> */}
        {/* </div>
</div> */}
      </div>
    </div>
  );
};

export default ModalDetails;
