import React from "react";
import { newGanaderosForm } from "../constants";
import "./styles.css";
const Modal = ({ children, openCloseModal, submitNewItem, ID }) => {
  const onSubmit = () => {
    if (ID) return submitNewItem(ID);
    return submitNewItem();
  };
  const handleClose = () => {
    openCloseModal();
  };

  return (
    <div className="ModalContainer">
      <div className="FormContainer">
        <div onClick={handleClose} className="openCloseModal">
          <p>X</p>
        </div>
        <form name="currentForm" className="currentForm">
          <div className="contentDinamicForm">{children}</div>
          {submitNewItem && (
            <div className="buttonsFormContainer">
              <button
                type="button"
                onClick={onSubmit}
                className={`${newGanaderosForm.typeClassAccept}`}
              >
                {newGanaderosForm.accept}
              </button>
              <button
                type="button"
                onClick={handleClose}
                className={`${newGanaderosForm.typeClassCancel}`}
              >
                {newGanaderosForm.cancelar}
              </button>
            </div>
          )}
        </form>
      </div>
    </div>
  );
};

export default Modal;
