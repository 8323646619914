import React, { useContext } from "react";
import SectionSelector from "../SectionSelector/SectionSelector";
import "./styles.css";
import { VacunasContext } from "../../../../Context/VacunasStore/context";
const Filtros = () => {
  const vacunasCtx = useContext(VacunasContext);
  const {
    grupos,
    periodos,
    sectionSelector,
    getLivestockTables,
    periodSelector,
    grupoSelector,
    setPeriodSelector,
    setgrupoSelector,
  } = vacunasCtx;

  const handleSetSelectGroup = (e) => {
    setgrupoSelector(e.target.value);
  };

  const handlePeriods = (e) => {
    setPeriodSelector(e.target.value);
  };

  const validateButton = () => {
    if (periodSelector === "" || grupoSelector === "") return true;
    return false;
  };

  const onClickSearch = () => {
    const config = {
      page: 1,
      limit: 10,
      group: grupoSelector,
      section: sectionSelector, //no - expired- vaccinate
    };
    getLivestockTables(config);
  };

  return (
    <div className="Row">
      <div className="Col">
        <div className="Row">
          <div className="mainFiltrosContainer">
            <div className="SelectPeriodos">
              <select onChange={handlePeriods}>
                <option value="">Selecciona el periodo</option>
                {periodos.map((el) => (
                  <option value={el.value} key={el._id}>
                    {el.label}
                  </option>
                ))}
              </select>
            </div>

            <div className="SelectPeriodos">
              {grupos.length === 0 ? (
                <label>Este periodo no posee grupos</label>
              ) : (
                <select value={grupoSelector} onChange={handleSetSelectGroup}>
                  <option value="">Selecciona un grupo</option>
                  {grupos.map((el) => (
                    <option value={el._id} key={el._id}>
                      {el.name}
                    </option>
                  ))}
                </select>
              )}
            </div>
          </div>
        </div>
        <div className="Row">
          <SectionSelector />
        </div>
      </div>

      <div className="Col">
        
          <div className="ButtonFiondArea">
            <button
              disabled={validateButton()}
              onClick={onClickSearch}
              name="findCartilla"
              className="findCartilla" /* onClick={buscar} */
            >
              Buscar
            </button>
          </div>
        </div>
 
    </div>
  );
};

export default Filtros;
