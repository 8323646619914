import React from 'react';
import styles from './styles.module.css';


const  SignUp = ({getDataInputs}) => {

  return (
    <>

      <div className={styles.SignUpContainer} >

        <div className={styles.containerInputSignup}>
        <input 
        className={styles.InputsSignup}
        type="email" 
        name="email" 
        placeholder="Correo*" 
        onChange={(e)=> getDataInputs(e)}
        />
        </div>
        <div className={styles.containerInputSignup}>
        <input 
        className={styles.InputsSignup}
        type="text" 
        name="Name" 
        placeholder="Nombre*" 
        onChange={(e)=> getDataInputs(e)}
        />
        </div>

        <div 
        className={styles.containerInputSignup}>
          <input 
          className={styles.InputsSignup}
          type="password" 
          name="password" 
          placeholder="Contraseña*" 
          onChange={(e)=> getDataInputs(e)}
        />
            {/* <span className="material-icons-outlined">
                visibility
            </span> */}
        </div>

        <div className={styles.containerInputSignup}>
        <input 
        className={styles.InputsSignup}
        type="password" 
        name="confirmPassword" 
        placeholder="Confirmar Contraseña*"
        onChange={(e)=> getDataInputs(e)}
        />
{/*         
        <span className="material-icons-outlined">
                visibility
            </span> */}
        </div>
        

      </div>
    </>
  )
}

export default SignUp;
