export default {
  Rol: {
    id:undefined,
    name: "",
    description: "",
    permissions: {
      ranchs: {
        read: true,
        add: false,
        modified: false,
        delete: false,
      },
      ranchers: {
        read: true,
        add: false,
        modified: false,
        delete: false,
      },
      vaccines: {
        read: true,
        add: false,
        modified: false,
        delete: false,
      },
      palpations: {
        read: true,
        add: false,
        modified: false,
        delete: false,
      },
      breeding: {
        read: true,
        add: false,
        modified: false,
        delete: false,
      },
      births: {
        read: true,
        add: false,
        modified: false,
        delete: false,
      },
    },
  },
  Usuario: {
    email: "",
    name: "",
    permission: "",
  },
  Raza: {
    name: "",
    description: "",
  },
  EditRaza: {
    id: '',
    name: "",
    description: "",
  },
  Tipo_de_Muerte: {
    Nombre: "",
    Descripcion: "",
  },
  Admin: {
    username: "",
    name: "",
  },
};
