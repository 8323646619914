import React, { useContext, useEffect, useState } from "react";
import { PalpacionesContext } from "../../../../Context/PalpacionesStore/context";
import "./styles.css";
import { useLocation } from "react-router-dom";
import { statusOptions } from "./contants";
const SexSelector = () => {
  const { pathname } = useLocation();
  const splitPathname = pathname.split("/");
  const ranchID = splitPathname[2];
  const PalpacionesCxt = useContext(PalpacionesContext);
  const [customStatus, setcustomStatus] = useState([]);
  const {
    getInitialData,
    setPage,
    breedFilter,
    setstatusFilter,
    setLoading,
    statusFilter,
    inputSearch,
    sexFilter,
    ranchFilter,
  } = PalpacionesCxt;
useEffect(() => {
  let newStatus = []
  statusOptions.forEach(res=>{
    if(res.value==='vacia')newStatus.push(res) 
    if(res.value==='cargada')newStatus.push(res) 
  })
  setcustomStatus(newStatus)
}, [statusOptions]);
  const handleChange = (e) => {
    const config = {
      page: 1,
      limit: 5,
      id: ranchID,
      search_text: inputSearch,
      sex: sexFilter,
      breed: breedFilter,
      ranch:  ranchFilter,
      status: e.target.value,
    };
    setstatusFilter(e.target.value);
    setLoading(true);
    setPage(1);
    getInitialData(config);
  };

  return (
    <div className="mainContainerFiltersDetails">
      <div className="inpurSearchBox colorSelect">
        <select
          defaultValue={statusFilter}
          name="SexoDetailsSelect"
          className="SelecteSexoDetails"
          onChange={handleChange}
        >
          <option value="">Estatus</option>
          {customStatus.map((option) => {
            return (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            );
          })}
        </select>
      </div>
    </div>
  );
};
export default SexSelector;
