const Cabeza = {
  sex: '',
  status: '',
  date: '',
  siniiga: '',
  siniigaM: '',
  rancherM: '',
  pedigreeM: '',
  ranchM: '',
  siniigaH: '',
  rancherH: '',
  pedigreeH: '',
  ranchH: '',
  breed: '',
  ranch: '',
  rancher: '',
  calvesH: '',
  calvesM: '',
};

const Parto = {
  dueDate: '',
  status: '',
};

const Gestacion = {
  reproductionDate: '',
};

export default {
  Cabeza,
  Parto,
  Gestacion,
};

/* Siniiga:'',
        Rancho:'',
        Raza:'',
        Ganadero:'',
        Sexo:'',
        Status:'',
        Fecha:'',
        Ciclo:'',
        Observaciones:'',
        SiniigaM:'',
        RanchoM:'',
        GanaderoM:'',
        PedigreeM:'',
        SiniigaH:'',
        RanchoH:'',
        GanaderoH:'',
        PedigreeH:'', */
