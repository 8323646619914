import React, { useContext } from 'react';
import { PieContext } from '../../../../Context/PieCria/Context';
import { TITULO_LISTADO_DE_CRIAS } from '../../../constants';
import './styles.css';
import translateDate from '../../../../utils/translateDate';
const Tabla = ({ tablesInfo, pie }) => {
  //const { inputFilterRaza } = useContext(DetailsContext);
  const { inputFilterSiniiga } = useContext(PieContext);
  let originTable = [...tablesInfo];
  /* if (match?.url?.includes("Rancho")) {
    originTable = tablesInfo.filter((item) =>
      item.Raza.includes(inputFilterRaza)
    );
  } */
  if (pie) {
    originTable = tablesInfo.filter((item) => item.Siniiga.includes(inputFilterSiniiga));
  }
  if (originTable.length === 0)
    return <div className="MainTablasContainer">"No se encontraron resultados"</div>;
  const renderPieRanchTitles = TITULO_LISTADO_DE_CRIAS.map((el, i) => (
    <th key={`titlesTableKey${i}`} className="eachCeld">
      {el}
    </th>
  ));

  return (
    <div className="MainTablasContainer">
      <table className="tableElement">
        <tbody className="tableTbody">
          <tr className="tableTitles">{renderPieRanchTitles}</tr>
          {originTable.map((el, i) => (
            <tr className="eachRow" key={`row_${i}`}>
              <th className="thValues">{translateDate(el.date)}</th>
              <th className="thValues">{el.calvesH === '' ? ' - ' : el.calvesH}</th>
              <th className="thValues">{el.calvesM === '' ? ' - ' : el.calvesM}</th>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Tabla;
