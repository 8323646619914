import React, { useContext } from "react";
import { useLocation } from "react-router-dom";
import { PieContext } from "../../Context/PieCria/Context";
import Switch from "../Auxiliars/switch";
import { edit, titleTableRanchos } from "../constants";
import "./styles.css";
import translateDate from '../../utils/translateDate'

const Tabla = ({
  tablesInfo,
  setActiveRanch,
  getIdGanadero,
  redirect,
  match,
  pie,
}) => {
  const { pathname } = useLocation();
  //const { inputFilterRaza } = useContext(DetailsContext);
  const { inputFilterSiniiga } = useContext(PieContext);
  let originTable = [...tablesInfo];
  /* if (match?.url?.includes("Rancho")) {
    originTable = tablesInfo.filter((item) =>
      item.Raza.includes(inputFilterRaza)
    );
  } */
  if (pie) {
    originTable = tablesInfo.filter((item) =>
      item.Siniiga.includes(inputFilterSiniiga)
    );
  }
  // console.log( "tablesInfo ========>", tablesInfo );
  if (originTable.length === 0)
    return (
      <div className="MainTablasContainer">"No se encontraron resultados"</div>
    );
  const renderPieRanchTitles = titleTableRanchos.map((el, i) => (
    <th key={`titlesTableKey${i}`} className="eachCeld">
      {el}
    </th>
  ));
  const renderOtherTitles = Object.keys(originTable[0]).map((el, i) =>
    el === "createdAt" ||
    el === "updatedAt" ||
    el === "_id" ||
    el === "owner" ||
    el === "RanchoID" ||
    el === "RazaID" ||
    el === "Confirmacion" ||
    el === "Ciclo" ||
    el === "Monta" ||
    el === "KgNacimiento" ||
    el === "KgAnual" ||
    el === "KgNacimientoDestete" ||
    el === "SiniigaH" ||
    el === "GanaderoM" ||
    el === "PedigreeM" ||
    el === "RanchoH" ||
    el === "SiniigaM" ||
    el === "GanaderoH" ||
    el === "Palpada" ||
    el === "PedigreeH" ||
    // eslint-disable-next-line no-mixed-operators
    (el === "Grupo" && originTable[0][el]?.length > 20) ? null : (
      <th key={`titlesTableKey${i}`} className="eachCeld">
        {el.includes("Metodo")
          ? "MÉTODO"
          : el === "Fecha"
          ? "FECHA DE NACIMIENTO"
          : el === "Status"
          ? "ESTATUS"
          : el === "x_kg_Peso"
          ? "X KG PESO"
          : el?.toUpperCase()}
      </th>
    )
  );

  return (
    <div className="MainTablasContainer">
      <table className="tableElement">
        <tbody className="tableTbody">
          <tr className="tableTitles">
            {pathname.includes("Rancho") ||
            pathname.includes("Gestacion") ||
            pie ||
            pathname.includes("Palpacion")
              ? renderPieRanchTitles
              : renderOtherTitles}
            <th className="tableActions">ACCIONES</th>
            {/*  {!pathname.includes("Palpacion") && (
            )} */}
          </tr>

          {originTable.map((el, i) => (
            <tr className="eachRow" key={`row_${i}`}>
              {el?.Correo && (
                <th className="thValues">{el?.Correo ? el?.Correo : ""} </th>
              )}
              {el?.upp && (
                <th className="thValues">{el?.upp ? el?.upp : ""} </th>
              )}
              {el?.SobreNombre && (
                <th className="thValues">
                  {el?.SobreNombre ? el?.SobreNombre : ""}{" "}
                </th>
              )}
              {el?.name && (
                <th className="thValues">{el?.name ? el?.name : ""} </th>
              )}
              {el?.email && (
                <th className="thValues">{el?.email ? el?.email : ""} </th>
              )}
              {(el.ranch || el.Rancho) && (
                <th className="thValues">{el.ranch?.name ?? el.Rancho}</th>
              )}
              {(el.rancher || el.Ganadero) && (
                <th className="thValues">{el.rancher?.name ?? el.Ganadero}</th>
              )}
              {(el.status || el.Status) ? (
                <th
                  style={{ textTransform: "capitalize" }}
                  className="thValues"
                >
                  {el.status ?? el.Status}{" "}
                </th>
              ):
              <th className="thValues">-</th>
              }
              {(el.date || el.Fecha) && (
                <th className="thValues">
                  {translateDate(el.date ?? el.Fecha)}
                </th>
              )}
              {console.log(el)}
               {el.cycle && (
                <th className="thValues">{el.cycle}</th>
              )}
              {el.siniiga || el.Siniiga ? (
                <th className="thValues">{el.siniiga ?? el.Siniiga} </th>
              ) : (
                <th className="thValues">-</th>
              )}
              {el.priv && <th className="thValues">{el.priv} </th>}
              {el?.Lote && (
                <th className="thValues">{el?.Lote ? el?.Lote : ""} </th>
              )}
              {el?.MetodoDeReproduccion && (
                <th className="thValues">
                  {el?.MetodoDeReproduccion ? el?.MetodoDeReproduccion : ""}{" "}
                </th>
              )}
              {el?.Observaciones && (
                <th className="thValues">
                  {el?.Observaciones ? el?.Observaciones : ""}{" "}
                </th>
              )}
              {el?.Palpada && (
                <th className="thValues">{el?.Palpada ? el?.Palpada : ""} </th>
              )}

              {el?.Grupo?.length < 12 && (
                <th className="thValues">{el?.Grupo ? el?.Grupo : ""} </th>
              )}
              {el?.Vacuna && (
                <th className="thValues">{el?.Vacuna ? el?.Vacuna : ""} </th>
              )}

              {el["Presentación"] && (
                <th className="thValues">
                  {el["Presentación"] ? el["Presentación"] : ""}{" "}
                </th>
              )}
              {el?.Via && (
                <th className="thValues">{el?.Via ? el?.Via : ""} </th>
              )}
              {el?.Dosis && (
                <th className="thValues">{el?.Dosis ? el?.Dosis : ""} </th>
              )}
              {el?.x_kg_Peso && (
                <th className="thValues">
                  {el?.x_kg_Peso ? el?.x_kg_Peso : ""}{" "}
                </th>
              )}
              {(el.Raza || el.breed) && (
                <th className="thValues">{el.Raza ?? el.breed?.name} </th>
              )}
              {el?.Tipo && (
                <th className="thValues">{el?.Tipo ? el?.Tipo : ""} </th>
              )}
              {el?.Descripcion && (
                <th className="thValues">
                  {el?.Descripcion ? el?.Descripcion : ""}{" "}
                </th>
              )}
              {el?.Rol && (
                <th className="thValues">{el?.Rol ? el?.Rol : ""} </th>
              )}

              {/* { el?.Ciclo               && <th className="thValues">{ el?.Ciclo                   ? el?.Ciclo        : "" } </th> } */}
              {el?.Sexo && (
                <th className="thValues">{el?.Sexo ? el?.Sexo : ""} </th>
              )}

              {redirect ? (
                <th className="thValues">
                  <div className="linkRedirect">
                    <button
                      type="button"
                      id={el._id}
                      onClick={(e) => {
                        getIdGanadero(e.target.id);
                      }}
                    >
                      {">"}
                    </button>
                  </div>
                </th>
              ) : (
                <>
                  <th className="thValues">
                    {match.url.includes("Configuracion") ? null : (
                      <img
                        className="editable_Btn"
                        src={edit}
                        alt="edit"
                        id={el?._id}
                        onClick={(e) => {
                          getIdGanadero(e.target.id);
                        }}
                      />
                    )}
                    <Switch
                      id={el.id}
                      identificador={`${el?.SobreNombre}-${el?._id}`}
                      setActiveIDSwitch={setActiveRanch}
                    />
                  </th>
                </>
              )}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Tabla;
