import React, { memo, useState, useEffect } from "react";
import "./Ciclos.css";
import { ToastContainer, toast } from "react-toastify";
import { saveCicles, getCiclesService } from "../../../servicces";
import { Button } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
const initialValue = {
  weaning: 1,
  growth: "",
};
const Ciclos = memo(() => {
  const success = (text) => toast.success(text, { autoClose: 3000 });
  const Error = (text) => toast.error(text, { autoClose: 3000 });
  const [values, setvalues] = useState(initialValue);
  const [loading, setloading] = useState(true);
  useEffect(() => {
    const getCicles = async () => {
      try {
        const results = await getCiclesService();
        const { weaning, growth } = results.data.cycle;
        setvalues({ weaning, growth });
        setloading(false)
      } catch (error) {
        console.log(error);
      }
    };
    getCicles();
  }, []);
  const handleWrite = (e) => {
    e.preventDefault();
  };
  const saveClicles = async (e) => {
    e.preventDefault();
    try {
      saveCicles(values);
      success("Sus ciclos fueron guardados correctamente");
    } catch (error) {
      console.log(error);
      Error(error.response.data);
    }
  };
  const changeWeaming = (e) => {
    setvalues({ ...values, weaning: e.target.value, growth: "" });
  };
  const changeGrowth = (e) => {
    setvalues({ ...values, growth: e.target.value });
  };
  const valid = () => {
    const weaning = parseInt(values.weaning);
    const growth = parseInt(values.growth);
    if (weaning < growth) return false;
    return true;
  };
  if (loading)
    return (
      <div className="containerCiclos">
        <div className="loadingContainer">
          <CircularProgress style={{color:'rgba(215, 108, 28, 1)'}}/>
        </div>
      </div>
    );

  return (
    <div className="containerCiclos">
      <h1 className="titleGrupo ciclostitle">Configurar ciclos</h1>
      <h5 className="ciclos-caption">
        Configure los ciclos de vida donde considere que su ganado
      </h5>
      <h5 className="ciclos-caption">
        se encuentra en su etapa de destete y desarrollo.
      </h5>

      <form onSubmit={saveClicles}>
        <div className="input-ciclos">
          <div className="label-ciclos">
            {/* <img src={vaccineColor} alt="nueva Vacuna" /> */}
            <label> Seleccione el periodo de destete (meses) </label>
          </div>
          <input
            value={values.weaning}
            onChange={changeWeaming}
            onKeyDown={handleWrite}
            type="number"
            min={1}
            name="weaning" /* onChange={handleChange} */
          />
          <div className="customBorder"></div>

          <span className="span-ciclos">
            Una vez cumplido el periodo de destete el estatus de todo el ganado
            que se encuentre en esta etapa pasara a estar en desarrollo.
          </span>
        </div>

        <div className="input-ciclos">
          <div className="label-ciclos">
            {/* <img src={vaccineColor} alt="nueva Vacuna" /> */}
            <label>Seleccione el periodo de desarrollo (meses) </label>
          </div>
          <input
            onChange={changeGrowth}
            value={values.growth}
            onKeyDown={handleWrite}
            min={parseInt(values.weaning) + 1}
            type="number"
            name="weaning" /* onChange={handleChange} */
          />
          <div className="customBorder"></div>
          <span className="span-ciclos">
            Una vez cumplido el periodo de desarrollo el estatus de todo el
            ganado que se encuentre en esta etapa pasara a ser adulto.
          </span>
        </div>
        <div className="submit-ciclos-container">
          <Button disabled={valid()} type="submit" variant="outlined">
            Guardar configuracion
          </Button>
        </div>
        <ToastContainer />
      </form>
    </div>
  );
});

export default Ciclos;
