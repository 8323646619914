import React, { useContext, useEffect, memo } from "react";
import { find } from "../../constants";
import "./styles.css";
import debounce from "lodash.debounce";
import { PieContext } from "../../../Context/PieCria/Context";

const SearchByMonths = memo(() => {
  const detailsCtx = useContext(PieContext);
  const {
    getInitialData,
    setPage,
    inputSearch,
    setLoading,
    setmonthInputSearch,
    monthInputSearch
  } = detailsCtx;
  const searchFunction = async (config) => {
    setLoading(true);
    setPage(1);
    getInitialData(config);
  };
  const debouncedSearch = React.useRef(
    debounce(async (config) => {
      await searchFunction(config);
    }, 300)
  ).current;
  async function handleChange(e) {
    setmonthInputSearch(e.target.value);
    const config = {
      page: 1,
      limit: 5,
      search_text: inputSearch,
      search_months:e.target.value
    };
    debouncedSearch(config);
  }
  useEffect(() => {
    return () => {
      debouncedSearch.cancel();
    };
  }, [debouncedSearch]);
  return (
    <div className="inpurSearchBoxMonth">
      <img className="findIcon" src={find} alt="Find" />
      <input
        className="findInput"
        type='number'
        min={0}
        max={12}
        value={monthInputSearch}
        name="searchBox"
        placeholder="Buscar por meses de nacido"
        onChange={handleChange}
      />
    </div>
  );
});
export default SearchByMonths;
