import React, { useContext } from "react";
// import reactDom from 'react-dom';
import { Switch, Route, useHistory, Redirect } from "react-router-dom";
import { MainContext } from "./MainContext";
import Auth from "./Pages/Auth";
import Ranchos from "./Pages/Ranchos";
import Ganaderos from "./Pages/Ganaderos";
import Proveedores from "./Pages/Proveedores";

import Gestacion from "./Pages/Gestacion";
import ListadoDeCrias from "./Pages/ListadoDeCrias";
import ListadoDePartos from "./Pages/ListadoDePartos";

import Palpacion from "./Pages/Palpacion";
import PalpacionesHistorial from "./Pages/PalpacionesHistorial";

import Vacunas from "./Pages/Vacunas";
import VaccineList from "./Pages/VaccineList";

import Configuracion from "./Pages/Configuracion";
import Details from "./Pages/Details";
import Mails from "./Pages/Mails";
import PieCria from "./Pages/PieCria";
import { PublicRoute } from "./utils/PublicRoute";

const Routes = () => {
  const history = useHistory();
  const { user, cleanUser } = useContext(MainContext);
  return (
    <>
      <Switch>
        <PublicRoute exact path="/" component={Auth} />
        <Route exact path="/Rancho" component={Ranchos} />
        <Route exact path="/Rancho/:id" component={Details} />

        <Route exact path="/Ganaderos" component={Ganaderos} />
        <Route exact path="/Proveedores" component={Proveedores} />
        <Route exact path="/Palpacion" component={Palpacion} />
        <Route
          exact
          path="/Palpacion/:id/historial"
          component={PalpacionesHistorial}
        />
        <Route exact path="/Gestacion" component={Gestacion} />
        <Route exact path="/Gestacion/:id/listado-de-crias" component={ListadoDeCrias} />
        <Route exact path="/Gestacion/:id/listado-de-partos" component={ListadoDePartos} />


        <Route exact path="/Vacunas" component={Vacunas} />
        <Route exact path="/Vacunas/listado" component={VaccineList} />
        <Route exact path="/Configuracion" component={Configuracion} />
        <Route
          exact
          path="/CerrarSession"
          component={() => {
            sessionStorage.removeItem("hatoToken");
            cleanUser();
            history.push("/");
            return <>/</>;
          }}
        />
        <Route exact path="/mail/confirm/:id" component={Mails} />
        <Route exact path="/PieCria" component={PieCria} />
      </Switch>
    </>
  );
};
export default Routes;
