import React from 'react';
import { Link } from 'react-router-dom';
import { rutas } from '../constants';
import './styles.css';

const Navbar = ({currentUrl}) => {
  return (
    <>
      <div className="ContanierNav">
        {
          rutas && rutas.map( (el, i) => (
            <Link className={ currentUrl.includes(el.ruta)   ?  "currentURL" : "itemMenu"} to={el.ruta} key={el.ruta + i}>
              <img src={el.icon} alt={el.ruta} />
              <p>{el.nombre}</p>
            </Link>
          ))
        }
      </div>
    </>
  )
}

export default Navbar
