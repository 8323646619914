import React, { createContext, useState, useEffect } from "react";
import { shape, string, node, func, bool } from "prop-types";
import { withRouter } from "react-router";
import GanaderosStore from "./store";
import {
  newGanaderosPetition,
  InitialDataPetitionGanaderos,
  updateGanaderosService,
  InitialDataPetition,
  getAllGanaderos,
  getRanchs,
  disableGanaderoService,
} from "../../servicces";
import { toast } from "react-toastify";

export const GanaderosContext = createContext(GanaderosStore);

const initialQuerys = {
  page: 1,
  limit: 10,
  search_text: "",
  active: "todos",
  ranch: "todos",
};

const GanaderosProvider = ({ children }) => {
  const success = (text) => toast.success(text, { autoClose: 3000 });
  const ERROR = (text) => toast.error(text, { autoClose: 3000 });

  const [searchBarInput, setSearchBarInput] = useState(
    GanaderosStore.searchBarInput
  );
  const [rows, setRows] = useState(GanaderosStore.rosw);
  const [newGanadero, setnewGanaderos] = useState(GanaderosStore.newGanadero);
  const [GanaderosID, setGanaderosID] = useState(GanaderosStore.GanaderosID);
  const [isOpenModalGanaderos, setIsOpenModalGanaderos] = useState(false);
  const [getInitialDataBool, setGetInitialDataBool] = useState(true);
  const [isEditable, setIsEditable] = useState(false);
  const [Ranchos, setRanchos] = useState([]);
  const [infoGanaderos, setInfoGanaderos] = useState(null);
  const [loading, setLoading] = useState(false);
  const [arrivalClass, setarrivalClass] = useState("");
  const [msgResponse, setmsgResponse] = useState("");
  const [IsToastUse, setIsToastUse] = useState(false);
  const [page, setPage] = useState(1);
  const [inputSearch, setinputSearch] = useState(initialQuerys.search_text);
  const [activeSelector, setactiveSelector] = useState(initialQuerys.active);
  const [ranchsSelector, setRanchsSelector] = useState(initialQuerys.ranch);
  const getDataNewGanaderos = ({ name, value }) => {
    if (IsToastUse) setIsToastUse(false);
    setnewGanaderos((prevState) => {
      return { ...prevState, [name]: value };
    });
  };

  const cleanGanadero = () => {
    setnewGanaderos(GanaderosStore.newGanadero);
  };
  useEffect(async () => {
    const ranchersResponse = await getRanchs();
    setRanchos(ranchersResponse.data.data.ranchs);
  }, []);

  const getInitialData = async ({
    page = 1,
    limit = 10,
    search_text = "",
    active = "todos",
    ranch = "todos",
  }) => {
    setLoading(true);
    try {
      const response = await InitialDataPetitionGanaderos({
        page,
        limit,
        search_text,
        active,
        ranch,
      });
      setInfoGanaderos(await response.data.data);
      setRows(await response.data.data.ranchers);
      setLoading(false);
    } catch (error) {
      setInfoGanaderos(null);
      setRows([]);
      setRanchos([]);
    }
  };

  const sendNewGanaderos = async () => {
    setarrivalClass("");
    setmsgResponse("");
    if (
      newGanadero.name === "" ||
      newGanadero.upp === "" ||
      newGanadero.nickname === "" ||
      newGanadero.ranch === ""
    ) {
      setarrivalClass("fail");
      setmsgResponse("Todos los campos son requeridos");
      setIsToastUse(true);
      return;
    }
    try {
      setIsToastUse(false);
      await newGanaderosPetition(newGanadero);
      setIsOpenModalGanaderos(false);
      getInitialData(initialQuerys);
      cleanGanadero();
      success("Un ganadero a sido agregado satisfactoriamente");
    } catch (error) {
      setmsgResponse(error.response.data.msg);
      console.log(error.response.data.msg);
    }
  };

  const setFormWithRancherData = (rancher) => {
    setnewGanaderos({
      name: rancher.name,
      upp: rancher.upp,
      nickname: rancher.nickname,
      ranch: rancher.ranch,
      _id: rancher._id,
    });
    setIsEditable(true);
  };
  const updateGanaderoCtx = async (id) => {
    setarrivalClass("");
    setmsgResponse("");
    if (
      newGanadero.name === "" ||
      newGanadero.upp === "" ||
      newGanadero.nickname === "" ||
      newGanadero.ranch === ""
    ) {
      setarrivalClass("fail");
      setmsgResponse("Todos los campos son requeridos");
      setIsToastUse(true);
      return;
    }
    try {
      await updateGanaderosService(newGanadero, id);
      const config = {
        page,
        limit: 10,
        search_text: inputSearch,
        active: activeSelector,
        ranch: ranchsSelector,
      };
      getInitialData(config);
      setIsEditable(false);
      setIsOpenModalGanaderos(false);
      cleanGanadero();
      success("El ganadero a sido actualizado satisfactoriamente");
    } catch (error) {
      setmsgResponse(error.response.data.msg);
      console.log(error.response.data.msg);
    }
  };

  const disableFunction = async (id) => {
    try {
      await disableGanaderoService(id);
      success("Usuario actualizado");
      const config = {
        page,
        limit: 10,
        search_text: inputSearch,
        active: activeSelector,
        ranch: ranchsSelector,
      };
      getInitialData(config);
    } catch (error) {
      ERROR(error.response.data.msg);
    }
  };

  const state = {
    searchBarInput,
    setSearchBarInput,
    rows,
    setRows,
    newGanadero,
    setnewGanaderos,
    GanaderosID,
    setGanaderosID,
    isOpenModalGanaderos,
    setIsOpenModalGanaderos,
    sendNewGanaderos,
    getDataNewGanaderos,
    isEditable,
    setIsEditable,
    setFormWithRancherData,
    updateGanaderoCtx,
    Ranchos,
    infoGanaderos,
    getInitialData,
    loading,
    setLoading,
    arrivalClass,
    IsToastUse,
    msgResponse,
    disableFunction,
    page,
    setPage,
    inputSearch,
    setinputSearch,
    activeSelector,
    setactiveSelector,
    ranchsSelector,
    setRanchsSelector,
  };

  useEffect(() => {
    if (getInitialDataBool) {
      getInitialData(initialQuerys);
      setGetInitialDataBool(false);
    }
  }, [searchBarInput, rows, newGanadero, GanaderosID, isOpenModalGanaderos]);

  return (
    <GanaderosContext.Provider value={state}>
      {children}
    </GanaderosContext.Provider>
  );
};

GanaderosProvider.propTypes = {
  children: node,
  searchBarInput: string,
  rows: shape(),
  newGanaderos: shape(),
  GanaderosID: string,
  paises: shape(),
  estados: shape(),
  ciudades: shape(),
  isOpenModal: bool,
  setSearchBarInput: func,
  setRows: func,
  setnewGanaderos: func,
  setGanaderosID: func,
  setIsOpenModalGanaderos: func,
  sendNewGanaderos: func,
  getDataNewGanaderos: func,
};

GanaderosProvider.defaultProps = {
  children: null,
  searchBarInput: GanaderosStore.searchBarInput,
  rows: {},
  newGanaderos: GanaderosStore.newGanadero,
  GanaderosID: GanaderosStore.GanaderosID,
};

export default withRouter(GanaderosProvider);
