import React, { createContext, useState, useEffect } from 'react';
import { shape, string, node, func, bool } from "prop-types";
import { withRouter } from 'react-router';
import ProveedoresStore from './store';
import { newProveedoresPetition, InitialDataPetitionProveedores, updateProveedoresService } from '../../servicces';

export const ProveedoresContext = createContext(ProveedoresStore);

  const ProveedoresProvider = ({children}) => {
  
  const [searchBarInput, setSearchBarInput] = useState(ProveedoresStore.searchBarInput);
  const [rows, setRows] = useState(ProveedoresStore.rosw);
  const [newProveedor, setNewProveedor] = useState(ProveedoresStore.newProveedor);
  const [ProveedoresID, setProveedoresID] = useState(ProveedoresStore.ProveedoresID)
  const [isOpenModalProveedores, setIsOpenModalProveedores] = useState(false);
  const [getInitialDataBool, setGetInitialDataBool] = useState(true);
  const [isEditable, setIsEditable] = useState(false);

const getDataNewProveedores = (name, value) => {

    if(name ==="nombre") setNewProveedor({
      ...newProveedor,
      ['Nombre']:value
    })
    if(name ==="upp") setNewProveedor({
      ...newProveedor,
      ['UPP']:value
    })
  }

  const cleanProveedor = () => {
    setNewProveedor({
      Nombre:'',
      UPP:'',
    })
  }

  const getInitialData = async ()=> {

  const response = await InitialDataPetitionProveedores();
  

  setRows( await response?.data)
  }
  const sendNewProveedores = async () => {

  const response = await newProveedoresPetition(newProveedor);
    if( response?.data?.status === true ){
        setIsOpenModalProveedores(!response.data?.status);
        getInitialData();
        setIsEditable(false)
    }

    cleanProveedor();
  }
  const getProveedorById = id => {
    setNewProveedor(rows.find(el => el._id === id ))
    setIsEditable(true);
  }
  const updateProveedorCtx = async (id) => {
    const response =  await updateProveedoresService(newProveedor, id);


    if(response?.data?.status){
      getInitialData();
    }

    setIsEditable(false);
    setIsOpenModalProveedores(false);
    cleanProveedor();
    setIsEditable(false)

  }

  const state = {
    searchBarInput,
    setSearchBarInput,
    rows,
    setRows,
    newProveedor,
    setNewProveedor,
    ProveedoresID,
    setProveedoresID,
    isOpenModalProveedores, 
    setIsOpenModalProveedores,
    sendNewProveedores,
    getDataNewProveedores,
    isEditable,
    setIsEditable,
    getProveedorById,
    updateProveedorCtx,
  };

  useEffect(() => {
    if(getInitialDataBool){
      getInitialData();
      setGetInitialDataBool(false)
    }

    if(!isOpenModalProveedores){
      setIsEditable( false )
    }
   },
    [
      searchBarInput,
      rows,
      newProveedor,
      ProveedoresID,
      isOpenModalProveedores,
      isEditable
    ])

  return (
      <ProveedoresContext.Provider value={state}>
      {children}
    </ProveedoresContext.Provider>
  )
}

ProveedoresProvider.propTypes = {
  children:node,
  searchBarInput:string,
  rows:    shape(),
  newProveedores: shape(),
  ProveedoresID:  string,
  paises:   shape(),
  estados:  shape(),
  ciudades: shape(),
  isOpenModal:bool,
  setSearchBarInput:func,
  setRows:func,
  setnewProveedor:func,
  setProveedoresID:func,
  setIsOpenModalProveedores:func,
  sendNewProveedores:func,
  getDataNewProveedores:func,
}

ProveedoresProvider.defaultProps = {
  children: null,
  searchBarInput:ProveedoresStore.searchBarInput,
  rows:{},
  newProveedores:ProveedoresStore.newProveedor,
  ProveedoresID:ProveedoresStore.ProveedoresID,
}

export default  withRouter(ProveedoresProvider);