import React from "react";
import { PALPACION_HISTORIAL_TITLES, edit } from "../../../constants";
import translateDate from '../../../../utils/translateDate'

import "./styles.css";
const Tabla = ({ tablesInfo, getIdGanadero, pie }) => {
  if (tablesInfo.length === 0)
    return (
      <div className="MainTablasContainer">"No se encontraron resultados"</div>
    );
  const renderTitle = PALPACION_HISTORIAL_TITLES.map((el, i) => (
    <th key={`title-${i}`} className="eachCeld">
      {el}
    </th>
  ));
  const renderColumns = (item, rowKey) => {
    return Object.keys(item).map((res, key) => {
      console.log(res);
      let Style = {};
      if (res === "livestock" || res === "account" || res === "_id")
        return null;
      if (res === "status") Style = { textTransform: "capitalize" };
      /* if (res === "_id")
        return (
          <th
            style={Style}
            key={`${rowKey}-${res}-${key}`}
            className="thValues"
          >
            <img
              className="editable_Btn"
              src={edit}
              alt="edit"
              id={res._id}
              onClick={(_) => {
                getIdGanadero(res);
              }}
            />
          </th>
        ); */

      if (res === "date")
        return (
          <th
            style={Style}
            key={`${rowKey}-${res}-${key}`}
            className="thValues"
          >
            {item[res] ? translateDate(item[res]) : "-"}
          </th>
        );
      return (
        <th style={Style} key={`${rowKey}-${res}-${key}`} className="thValues">
          {item[res] ? item[res] : "-"}
        </th>
      );
    });
  };
  const renderRows = tablesInfo.map((res, key) => {
    return (
      <tr className="eachRow" key={`row_${key}`}>
        {renderColumns(res, key)}
      </tr>
    );
  });
  return (
    <div className="MainTablasContainer">
      <table className="tableElement">
        <tbody className="tableTbody">
          <tr className="tableTitles">
            {/* <th className="tableActions">ACCIONES</th> */}
            {renderTitle}
          </tr>
          {renderRows}
        </tbody>
      </table>
    </div>
  );
};

export default Tabla;
