import React, { useEffect, useContext } from "react";
import "./styles.css";
import { palpacionForm } from "../../constants";
import { statusOptionsReduced } from "../../constants";
import { useLocation } from "react-router-dom";
import Store from "../../../Context/PalpacionesStore/store";
import { PalpacionesContext } from "../../../Context/PalpacionesStore/context";
const PalpacionForm = ({ isEditableForm, values }) => {
  const PalpacionesCxt = useContext(PalpacionesContext);
  const { historialErros, setNewHistorialValues } = PalpacionesCxt;
  const { pathname } = useLocation();
  const splitPathname = pathname.split("/");
  const cowID = splitPathname[2];
  useEffect(() => {
    setNewHistorialValues((prev) => {
      return { ...prev, livestockId: cowID };
    });

    return () => {
      setNewHistorialValues(Store.Historial);
    };
  }, []);
  const returnValuesName = (name) => {
    if (name === "Fecha") {
      return new Date(values[name]).toISOString().split("T")[0];
    }
    return values[name];
  };

  const handleChange = (e) => {
    setNewHistorialValues((prev) => {
      return { ...prev, [e.target.name]: e.target.value };
    });
  };

  return (
    <div className="inputsPalpacionContainer">
      <div className="titleFormPalpation">
        <h2 className="title">{palpacionForm.title}</h2>
      </div>
      <div className="inputsPalpaptionContent">
        {palpacionForm.form.map((el, i) => (
          <div key={`key-${i}-${el.label}`} className="eachContainerInputs">
            <div className="labelsContainerPalpation">
              <img src={el.icon} alt={el.label} />
              <label className="labelInputPalpation">{el.label}</label>
            </div>
            <div>
              {el.type === "text" || el.type === "date" ? (
                <input
                  defaultValue={isEditableForm ? returnValuesName(el.name) : ""}
                  className="inputPalpation"
                  type={el.type}
                  name={el.name}
                  onChange={handleChange}
                  required={true}
                />
              ) : (
                <select
                  className="create-cow-selector"
                  style={{ width: "100%" }}
                  name={el.name}
                  onChange={handleChange}
                >
                  <option value="">Seleccion el status</option>
                  {statusOptionsReduced.map((item, k) => (
                    <option key={k} value={item.value}>
                      {item.label}
                    </option>
                  ))}
                </select>
              )}
            </div>
          </div>
        ))}
      </div>
      {historialErros !== "" && (
        <label style={{ color: "red" }}>{historialErros}</label>
      )}
    </div>
  );
};

export default PalpacionForm;
