import React, {
  useState,
  useCallback,
  useRef,
  useEffect,
  useContext,
} from "react";
import "mapbox-gl/dist/mapbox-gl.css";
import "react-map-gl-geocoder/dist/mapbox-gl-geocoder.css";
import Geocoder from "react-map-gl-geocoder";
import Map, { Marker } from "react-map-gl";
import "./styles.css";
import { newRanchForm } from "../../constants";
import { RanchContext } from "../../../Context/RanchosStore/context";
const NewRanch = ({ values }) => {
  const ranchContext = useContext(RanchContext);
  const {
    estados,
    ciudades,
    newRanch,
    setnewRanch,
/*     isEditableForm, */
    stateSelected,
    getDataNewRanch,
  } = ranchContext;

  const [y, setY] = useState("15rem");

/*   const [isMapInUse, setisMapInUse] = useState(false);

  const [viewport, setViewport] = useState({
    latitude: values?.coordinates?.y ? values.coordinates.y : 20.96,
    longitude: values?.coordinates?.x ? values.coordinates.x : -89.62,
    zoom: 10,
  }); */
/*   const mapRef = useRef(); */

  /* const handleViewportChange = useCallback((newViewport) => {
    const coordinates = {
      x: newViewport.longitude,
      y: newViewport.latitude,
    };
    setnewRanch((prev) => {
      return { ...prev, coordinates };
    });
    return setViewport(newViewport), [];
  }); */

  const handleChangeInput = (e) => {
    const { name, value } = e.target;
    getDataNewRanch(name, value);
  };

  /* const activeTab = "buttonsTabs isActiveTab";
  const deactiveTab = "buttonsTabs";

  const buttonsTabsSelection = (e) => {
    e.target.parentNode.parentNode.querySelectorAll(".Tab").forEach((el) => {
      if (el.title === e.target.title) {
        el.style.opacity = "1";
        el.style.display = "block";
      } else {
        el.style.opacity = "0";
        el.style.display = "none";
      }
    });
    e.target.parentNode
      .querySelectorAll(".buttonsTabs")
      .forEach((element, i) => {
        element.style.borderBottom = "none";
        element.classList.remove("isActiveTab");
      });
    e.target.classList.add("isActiveTab");
    e.target.style.borderBottom = "solid 2px rgba(215, 108, 28, 1)";

    if (e.target.innerHTML === "Ubicación") {
      setisMapInUse(true);
      setY("10rem");
    } else {
      setY("15rem");
      setisMapInUse(false);
    }
  }; */

 /*  const handleGeocoderViewportChange = useCallback((newViewport) => {
    const geocoderDefaultOverrides = { transitionDuration: 500 };
    return handleViewportChange({
      ...newViewport,
      ...geocoderDefaultOverrides,
    });
  }, []); */

  useEffect(() => {
    if (!values._id) return;
    setnewRanch(values);
    //eslint-disable-next-line
  }, [values._id]);

  const returnSelectorName = (name) => {
    if (name === "state") return "el estado";
    if (name === "city") return "la ciudad";

    if (name === "country") return "el pais";
  };

  return (
    <div className={newRanchForm.mainClass}>
      <div className="mainTitleContent">
        <h1 className="titleGrupo">
          {values._id ? newRanchForm.MaintitleEdit : newRanchForm.Maintitle}
        </h1>
      </div>

      {/* <div className="contentTabs">
        {Object.keys(newRanchForm?.tabs).map((key, i) => (
          <button
            key={`buttonsForm-${i}`}
            className={
              i === 0
                ? "baseTab_Btn " + activeTab
                : "baseTab_Btn " + deactiveTab
            }
            title={`tab${i}`}
            type="button"
            onClick={buttonsTabsSelection}
          >
            {newRanchForm?.tabs[key]}
          </button>
        ))}
      </div> */}

      <div className="Tabs" >
        {Array.from(Array(newRanchForm.parts)).map((_, i) => {
          return (
            <div
              key={`tab${i + 1}`}
              className={`Tab tab${i + 1}`}
              title={`tab${i}`}
              style={{ opacity: `${i > 0 ? "0" : "1"}` }}
            >
              {newRanchForm[`part${i + 1}`].map((el, i) => (
                <div className="eachInputForm" key={`eachInput-${i}`}>
                  <div className="contentLabel">
                    <img className="iconInput" src={el.icon} alt={el.name} />
                    <label className="labelInput">{el.label}</label>
                  </div>
                  {el.type === "text" ? (
                    <input
                      className="inputText inputNewRanch"
                      value={newRanch[el.name] ? newRanch[el.name] : ""}
                      type={el.type}
                      name={el.name}
                      onChange={handleChangeInput}
                    />
                  ) : (
                    <>
                      <select
                        className="selectRegions"
                        name={el.name}
                        onChange={handleChangeInput}
                        defaultValue={
                          el.name === "country"
                            ? "Mexico"
                            : el.name === "state" || el.name === "city"
                            ? newRanch[el.name]
                            : ""
                        }
                      >
                        <option value={""}>
                          Seleccione {returnSelectorName(el.name)}
                        </option>
                        {estados && el.name === "state"
                          ? estados.map((opt, i) => (
                              <option
                                key={`states-${i}`}
                                value={`${opt}`}
                                selected={opt === stateSelected}
                              >
                                {opt}
                              </option>
                            ))
                          : ciudades && el.name === "city"
                          ? ciudades.map((opt, i) => (
                              <option key={`city-${i}`} value={`${opt}`}>
                                {opt}
                              </option>
                            ))
                          : el.values.map((opt, i) => (
                              <option
                                key={`country-${i}`}
                                value={`${opt.value}`}
                              >
                                {opt.value}
                              </option>
                            ))}
                      </select>
                    </>
                  )}
                </div>
              ))}
            </div>
          );
        })}
      </div>
{/* 
      {isMapInUse && (
        <div
          style={{ width: "100%", height: "15rem", margin: "5rem auto 0 auto" }}
        >
          <Map
            ref={mapRef}
            {...viewport}
            width="100%"
            height="100%"
            mapStyle={"mapbox://styles/mapbox/streets-v11"}
            onViewportChange={handleViewportChange}
            mapboxApiAccessToken={process.env.REACT_APP_API_MAPBOX}
          >
            <Marker
              latitude={values?.coordinates?.y}
              longitude={values?.coordinates?.x}
              bgcolor="#D76C1B"
              anchor="bottom"
            ></Marker>

            <Geocoder
              mapRef={mapRef}
              onViewportChange={handleGeocoderViewportChange}
              mapboxApiAccessToken={process.env.REACT_APP_API_MAPBOX}
              position="top-left"
            ></Geocoder>
          </Map>
        </div>
      )} */}
    </div>
  );
};

export default NewRanch;
