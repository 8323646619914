import React, { useContext, useState, useEffect } from 'react';
import { LIVESCTOCK_VACCINE } from '../../../constants';
import { VacunasContext } from '../../../../Context/VacunasStore/context';
import './styles.css';
import Pagination from '@mui/material/Pagination';
import { Checkbox } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import translateDate from '../../../../utils/translateDate';

const CartillaDeVacunacion = () => {
  const [bigCheckbox, setbigCheckbox] = useState(false);
  const vacunasCtx = useContext(VacunasContext);
  const {
    livestockPageInfo,
    livestockPage,
    setlivestockPage,
    getLivestockTables,
    grupoSelector,
    sectionSelector,
    livestock,
    selectForVaccine,
    setSelectForVaccine,
    vaccinateAll,
    vaccinateLivestock,
    openDialog,
    setOpenDialog,
  } = vacunasCtx;

  function toggleAll(e) {
    setbigCheckbox(!bigCheckbox);
    let checkboxes = document.getElementsByName('custom-checkbox');
    let selectedIds = [];
    if (!bigCheckbox) {
      for (var i = 0, n = checkboxes.length; i < n; i++) {
        selectedIds.push(checkboxes[i].id);
        checkboxes[i].checked = true;
      }
    }
    setSelectForVaccine(selectedIds);
  }

  const checkIfBoxIsChecked = (id) => {
    const check = selectForVaccine.filter((selected_id) => selected_id === id);
    if (check.length > 0) return true;
    return false;
  };

  const clickOnCheckbox = (id) => {
    const position = selectForVaccine.findIndex((selected_id) => selected_id === id);
    let selectedIds = [...selectForVaccine];
    if (position === -1) {
      selectedIds.push(id);
    } else {
      selectedIds = selectForVaccine.filter((selected_id) => selected_id !== id);
    }
    setSelectForVaccine(selectedIds);
  };
  const renderTitle = LIVESCTOCK_VACCINE.map((title, key) => {
    if (title === 'checkbox' && sectionSelector === 'vaccinate') return null;
    if (title === 'checkbox')
      return (
        <th key={`${title}-${key}`} className="eachCeld">
          <Checkbox checked={bigCheckbox} onClick={toggleAll} id={'check-all'} />
        </th>
      );
    return (
      <th key={`${title}-${key}`} className="eachCeld">
        {title}
      </th>
    );
  });
  const handleChangePage = (_, newPage) => {
    console.log(newPage);
    //setLoading(true);
    const config = {
      page: newPage,
      limit: 10,
      group: grupoSelector,
      section: sectionSelector, //no - expired- vaccinate
    };
    setSelectForVaccine([]);
    setlivestockPage(newPage);
    getLivestockTables(config);
  };

  const vacunarTodoElGrupo = () => {
    vaccinateAll();
  };

  const vacunarVariasVacunas = () => {
    vaccinateLivestock();
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const renderRows = () => {
    return livestock.map((res, key) => {
      let Style = { minWidth: '5rem' };
      console.log(res);
      return (
        <tr className="eachRow" key={`row_${key}`}>
          {sectionSelector !== 'vaccinate' && (
            <th key={`${res._id}-${key}-${Math.random()}`} className="thValues">
              <Checkbox
                checked={checkIfBoxIsChecked(res._id)}
                onClick={(_) => clickOnCheckbox(res._id)}
                name="custom-checkbox"
                id={res._id}
              />
            </th>
          )}
          <th style={Style} key={`${res.siniiga}-${key}-${Math.random()}`} className="thValues">
            {res.siniiga ? res.siniiga : '-'}
          </th>
          <th style={Style} key={`${res.priv}-${key}-${Math.random()}`} className="thValues">
            {res.priv ? res.priv : '-'}
          </th>
          <th style={Style} key={`${res.date}-${key}-${Math.random()}`} className="thValues">
            {res.date ? translateDate(res.date) : '-'}
          </th>
          <th style={Style} key={`${res.breed.name}-${key}-${Math.random()}`} className="thValues">
            {res.breed.name ? res.breed.name : '-'}
          </th>
          <th style={Style} key={`${res.sex}-${key}-${Math.random()}`} className="thValues">
            {res.sex ? res.sex : '-'}
          </th>
          <th style={Style} key={`${res.status}-${key}-${Math.random()}`} className="thValues">
            {res.status ? res.status : '-'}
          </th>
          <th style={Style} key={`${res.ranch.name}-${key}-${Math.random()}`} className="thValues">
            {res.ranch.name ? res.ranch.name : '-'}
          </th>
          <th
            style={Style}
            key={`${res.rancher.name}-${key}-${Math.random()}`}
            className="thValues"
          >
            {res.rancher.name ? res.rancher.name : '-'}
          </th>
        </tr>
      );
    });
  };

  return (
    <div className="MainTablasContainer">
      {sectionSelector !== 'vaccinate' && (
        <div
          style={{
            marginBottom: '20px',
            display: 'flex',
            justifyContent: 'flex-end',
          }}
          className="filterVaccines"
        >
          <button className="vaccineButton" onClick={vacunarVariasVacunas}>
            Vacunar marcadas ({selectForVaccine.length})
          </button>
          <button className="vaccineButton" onClick={(_) => setOpenDialog(true)}>
            Vacunar todo el grupo
          </button>
        </div>
      )}

      <table className="tableElement">
        <tbody className="tableTbody">
          <tr className="tableTitles">{renderTitle}</tr>
          {renderRows()}
        </tbody>
      </table>
      <div style={{ marginTop: '20px' }} className="ganaderos-pagination">
        <Pagination
          count={livestockPageInfo?.totalPages}
          page={livestockPage}
          onChange={handleChangePage}
          shape="rounded"
        />
        <label style={{ marginTop: '7px' }}>
          Ganado en total : {livestockPageInfo?.totalGanado}
        </label>
      </div>

      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {'¿Esta seguro que desea vacunar a todo el grupo?'}
        </DialogTitle>
        <DialogActions>
          <Button style={{ color: '#d86c1c' }} onClick={handleCloseDialog}>
            cancelar
          </Button>
          <Button style={{ color: '#d86c1c' }} onClick={vacunarTodoElGrupo} autoFocus>
            continuar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default CartillaDeVacunacion;
