import React, { useContext, useEffect, useState } from "react";
import { grupo } from "../../constants";
import "./styles.css";
import { VacunasListContext } from "../../../Context/VaccineListStore/context";
import { getPeriods } from "../../../servicces";
const Grupo = () => {
  const vacunasCtx = useContext(VacunasListContext);
  const { setGrupo, groupError } = vacunasCtx;
  const [periods, setPeriods] = useState([]);
  useEffect(() => {
    const getSelectData = async () => {
      try {
        const result = await getPeriods();
        setPeriods(
          result.data.periods.filter((item) => {
            if (item.value !== "all" && item.value !== null) {
              return item;
            }
          })
        );
      } catch (error) {
        console.log(error);
      }
    };
    getSelectData();
  }, []);
  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setGrupo((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };
  return (
    <div className="addGroup">
      <h1 className="titleGrupo">Agregar Grupo</h1>

      <div className="inputGroup">
        <div className="labelGrupo">
          <label> Nombre del grupo </label>
        </div>

        <input type="text" name="name" onChange={handleChange} />
      </div>

      <div className="inputGroup">
        <div className="labelGrupo">
          {/*  // <img src={grupo} alt="nuevo grupo" /> */}
          <label> Periodo de la vacuna</label>
        </div>

        {/* <input type="number" min={0} name="recurrent" onChange={handleChange} /> */}
        <select className="inputGroup" name="period" onChange={handleChange}>
          <option value="">Selecciona el periodo de la vacuna</option>
          {periods.map((el) => (
            <option value={el.value} key={el._id}>
              {el.label}
            </option>
          ))}
        </select>
      </div>

      <div className="inputGroup">
        <div className="labelGrupo">
          {/*  // <img src={grupo} alt="nuevo grupo" /> */}
          <label> ¿Deseas que sea recurrente?</label>
        </div>

        {/* <input type="number" min={0} name="recurrent" onChange={handleChange} /> */}
        <select className="inputGroup" name="recurrence" onChange={handleChange}>
          <option value={false}>No</option>
          <option value={true}>Si</option>
        </select>
      </div>
      {groupError && <span style={{ color: "red", marginTop:'10px' }}>{groupError}</span>}
    </div>
  );
};

export default Grupo;
