import { AUTHSERVICE } from './config';

/*----------------------------Ranch-----------------------------------*/
export const newRanchPetition = async (data, id) => {
  return await AUTHSERVICE.post('/rancho/new', data);
};
export const InitialDataPetition = async ({ page, limit, search_text }) => {
  let url = '/rancho/all';
  if (page) url += `?page=${page}`;
  if (limit) url += `&limit=${limit}`;
  if (search_text !== '') url += `&search_text=${search_text}`;
  return await AUTHSERVICE.get(url);
};
export const getSelectActiveRanchs = async ({ page, limit, search_text }) => {
  let url = '/rancho/all?active=true';
  if (page) url += `&page=${page}`;
  if (limit) url += `&limit=${limit}`;
  if (search_text !== '') url += `&search_text=${search_text}`;
  return await AUTHSERVICE.get(url);
};
export const updateRanch = async (data, id) => {
  return await AUTHSERVICE.put(`/rancho/update?id=${id}`, { data });
};
export const disableRanchService = async (id) => {
  return await AUTHSERVICE.put(`/rancho/disable?id=${id}`);
};

export const getUrlFromRanch = async (id) => {
  return await AUTHSERVICE.get(`/excel/download/ganado?ranch=${id}`);
};
export const getUrlFromPalpations = async (id) => {
  return await AUTHSERVICE.get(`/excel/download/palpacion?livestock=${id}`);
};
/*----------------------------Ranch-----------------------------------*/
/*----------------------------Ganaderos-----------------------------------*/
export const newGanaderosPetition = async (data) => {
  return await AUTHSERVICE.post('/ganaderos/new', data);
};

export const InitialDataPetitionGanaderos = async ({ page, limit, search_text, active, ranch }) => {
  let url = '/ganaderos/all';
  if (page) url += `?page=${page}`;
  if (limit) url += `&limit=${limit}`;
  if (search_text !== '') url += `&search_text=${search_text}`;
  if (active === 'si') url += `&active=true`;
  if (active === 'no') url += `&active=false`;
  if (ranch !== 'todos') url += `&ranch=${ranch}`;
  return await AUTHSERVICE.get(url);
};
export const getAllGanaderos = async () => {
  return await AUTHSERVICE.get(`/ganaderos/all?page1&limit=100&active=true`);
};

export const updateGanaderosService = async (info, id) => {
  const data = {
    name: info.name,
    upp: info.upp,
    nickname: info.nickname,
    ranch: info.ranch,
  };

  return await AUTHSERVICE.put(`/ganaderos/update?id=${id}`, { data });
};
export const disableGanaderoService = async (id) => {
  return await AUTHSERVICE.put(`/ganaderos/disable?id=${id}`);
};
/*----------------------------Ganaderos-----------------------------------*/
/*----------------------------PROVEEDORES-----------------------------------*/
export const newProveedoresPetition = async (data) => {
  return await AUTHSERVICE.post('/proveedores/new', data);
};
export const InitialDataPetitionProveedores = async () => {
  return await AUTHSERVICE.get('/proveedores/all');
};
export const updateProveedoresService = async (data, id) => {
  return await AUTHSERVICE.post('/proveedores/update', { data, id });
};
/*----------------------------PROVEEDORES-----------------------------------*/
/*----------------------------palpacion-----------------------------------*/
export const newPalpacionPetition = async (data) => {
  return await AUTHSERVICE.post('/palpacion/new', data);
};

export const initalDataPalpacion = async () => {
  return await AUTHSERVICE.get('/palpacion/all');
};

export const getPalpacionesList = async ({
  page = 1,
  limit = 10,
  search_text,
  breed,
  ranch,
  status,
}) => {
  let url = `palpacion/all?sex=H`;
  if (page) url += `&page=${page}`;
  if (limit) url += `&limit=${limit}`;
  if (breed !== '') url += `&breed=${breed}`;
  if (ranch !== '') url += `&ranch=${ranch}`;
  if (status) url += `&status=${status}`;
  if (search_text !== '') url += `&search_text=${search_text}`;
  return await AUTHSERVICE.get(url);
};

export const getPalpacionHistorial = async ({ page = 1, limit = 10, livestock }) => {
  let url = `palpacion/history?livestock=${livestock}`;
  if (page) url += `&page=${page}`;
  if (limit) url += `&limit=${limit}`;
  return await AUTHSERVICE.get(url);
};

export const submitPalpationHistorical = async (data) => {
  return await AUTHSERVICE.post('palpacion/new', data);
};

export const updatePalpacionService = async (data, id) => {
  return await AUTHSERVICE.post('/palpacion/update', { data, id });
};
/*----------------------------palpacion-----------------------------------*/

/* ----------------------------gestacion----------------------------------- */

export const getGestacionesList = async ({
  page = 1,
  limit = 10,
  search_text,
  breed,
  ranch,
  status,
}) => {
  let url = `/parto/all?sex=H`;
  if (page) url += `&page=${page}`;
  if (limit) url += `&limit=${limit}`;
  if (breed !== '') url += `&breed=${breed}`;
  if (ranch !== undefined) url += `&ranch=${ranch}`;
  if (status) url += `&status=${status}`;
  if (search_text !== '') url += `&search_text=${search_text}`;
  return await AUTHSERVICE.get(url);
};
export const gestationInfoByID = async (id) => {
  let url = `parto/show?livestock=${id}`;
  return await AUTHSERVICE.get(url);
};

export const getBirthListService = async ({ page = 1, limit = 10, livestock }) => {
  let url = `parto/history?livestock=${livestock}`;
  if (page) url += `&page=${page}`;
  if (limit) url += `&limit=${limit}`;
  return await AUTHSERVICE.get(url);
};
export const submitNewBirthService = async (data, id) => {
  return await AUTHSERVICE.post(`parto/new?livestock=${id}`, data);
};

export const createGestationService = async (data, id) => {
  return await AUTHSERVICE.post(`parto/newGestation?livestock=${id}`, data);
};

export const getPupsListService = async ({
  page = 1,
  limit = 5,
  id,
  search_text,
  sex,
  breed,
  status,
  period,
}) => {
  let url = `parto/showCalves?livestock=${id}`;
  if (page) url += `&page=${page}`;
  if (limit) url += `&limit=${limit}`;
  if (sex !== '') url += `&sex=${sex}`;
  if (breed !== '') url += `&breed=${breed}`;
  if (status) url += `&status=${status}`;
  if (period) url += `&date=${period}`;
  if (search_text !== '') url += `&search_text=${search_text}`;
  return await AUTHSERVICE.get(url);
};

export const createCalves = async (data, id) => {
  return AUTHSERVICE.post(`parto/createCalves?livestock=${id}`, data);
};

export const getCalves = async ({ page = 1, limit = 10, livestock, period }) => {
  let url = `parto/showCalves?livestock=${livestock}`;
  if (page) url += `&page=${page}`;
  if (limit) url += `&limit=${limit}`;
  if (period) url += `&date=${period}`;
  return await AUTHSERVICE.get(url);
};

/* ----------------------------gestacion-----------------------------------  */

/*----------------------------VACUNAS --------------------------------------*/

export const sendNewGroupService = async (data) => {
  return await AUTHSERVICE.post('/vacunas/newgroup', data);
};

export const toggleVaccineGroup = async (id) => {
  return await AUTHSERVICE.put(`/vacunas/disableGroup?id=${id}`);
};

export const sendNewVacunaService = async (data) => {
  return await AUTHSERVICE.post('/vacunas/newVaccine', data);
};

export const getPeriods = async () => {
  return await AUTHSERVICE.get('/vacunas/getPeriods');
};

export const getInitialDataVaccines = async ({ page = 1, limit = 10 }) => {
  let url = `/vacunas/getGroups`;
  if (page) url += `?page=${page}`;
  if (limit) url += `&limit=${limit}`;
  return await AUTHSERVICE.get(url);
};

export const getGroupsByPeriod = async ({ page = 1, limit = 10, period }) => {
  let url = `/vacunas/getGroups?active=true`;
  if (page) url += `&page=${page}`;
  if (limit) url += `&limit=${limit}`;
  if (period === 'Permanente') {
    url += `&period=null`;
  } else {
    url += `&period=${period}`;
  }

  return await AUTHSERVICE.get(url);
};

export const sendToVaccine = async (data) => {
  return await AUTHSERVICE.post('vacunas/createVaccine', { data });
};
export const getVaccinesBygroupId = async (id) => {
  return await AUTHSERVICE.get(`/vacunas/getVaccines?group=${id}`);
};

export const deleteVaccineService = async (id) => {
  return await AUTHSERVICE.delete(`/vacunas/deleteVaccine?vaccineId=${id}`);
};

export const getBreeds = async () => {
  return await AUTHSERVICE.get('razas/all?page=1&limit=100&active=true');
};

export const getRanchs = async () => {
  return await AUTHSERVICE.get('rancho/all?page=1&limit=100&active=true');
};
export const paginatedBreeds = async ({ page, limit }) => {
  let url = `razas/all`;
  if (page) url += `?page=${page}`;
  if (limit) url += `&limit=${limit}`;
  return await AUTHSERVICE.get(url);
};

/*----------------------------VACUNAS --------------------------------------*/

/*----------------------------Configuracion --------------------------------------*/

export const sendNewRolService = async (data) => {
  return await AUTHSERVICE.post('/roles/new', { ...data });
};
export const getInitialDataConfig = async () => {
  return await AUTHSERVICE.get('/config/alldata');
};
export const getUsers = async (page) => {
  return await AUTHSERVICE.get(`/users/all?page=${page}&limit=5`);
};
export const getAdmins = async (page) => {
  return await AUTHSERVICE.get(`/admins/all?page=${page}&limit=5`);
};
export const getRoles = async () => {
  return await AUTHSERVICE.get('/permisos/all?page=1&limit=100');
};
export const getCiclesService = async () => {
  return await AUTHSERVICE.get('ciclos');
};
export const saveCicles = async (data) => {
  return await AUTHSERVICE.post('ciclos/change', data);
};
export const sendNewUsusarioGanaderoService = async (data) => {
  return await AUTHSERVICE.post('/users/new', data);
};
export const sendNewAdminGanaderoService = async (data) => {
  return await AUTHSERVICE.post('/admins/new', data);
};

//NewGestation
export const sendNewGestationWithoutPalpation = async (data, livestock) => {
  return await AUTHSERVICE.post(`/parto/newWithoutPalpation?livestock=${livestock}`, data);
};

export const sendNewPermissionService = async (data) => {
  return await AUTHSERVICE.post('/permisos/new', data);
};
export const sendEditPermissionService = async (data, id) => {
  return await AUTHSERVICE.put(`/permisos/update?id=${id}`, data);
};
export const enableUserService = async (id) => {
  return await AUTHSERVICE.put(`/users/disable?id=${id}`);
};
export const enableBreedService = async (id) => {
  return await AUTHSERVICE.put(`/razas/disable?id=${id}`);
};

export const enableRolService = async (id) => {
  return await AUTHSERVICE.put(`/permisos/disable?id=${id}`);
};
export const sendNewRazaService = async (data) => {
  return await AUTHSERVICE.post('razas/new', data);
};

export const editRazaService = async (data, id) => {
  delete data.id;
  data.name = data.name.trim();
  return await AUTHSERVICE.put(`razas/update?id=${id}`, data);
};

export const sendNewTipoService = async (data) => {
  return await AUTHSERVICE.post('/config/newTipo', { ...data });
};
export const getLivestockByGroupAndSection = async ({ page = 1, limit = 5, group, section }) => {
  let url = '/vacunas/allData';
  if (page) url += `?page=${page}`;
  if (limit) url += `&limit=${limit}`;
  if (group) url += `&group=${group}`;
  if (section) url += `&section=${section}`;
  return await AUTHSERVICE.get(url);
};

export const postVacinateAll = async ({ group, section }) => {
  let url = '/vacunas/createVaccinateAll';
  if (group) url += `?group=${group}`;
  if (section) url += `&section=${section}`;
  return await AUTHSERVICE.post(url);
};

export const postVacinateLivestock = async (data) => {
  let url = '/vacunas/createVaccinate';
  return await AUTHSERVICE.post(url, data);
};

// siniiga
export const createSiniiga = async (data) => {
  delete data.id;
  let url = '/vacunas/createVaccinate';
  return await AUTHSERVICE.post('/siniiga/new', data);
};

export const deleteSiniiga = async (siniiga, livestock) => {
  let url = '/vacunas/createVaccinate';
  return await AUTHSERVICE.delete(`/siniiga/delete?siniiga=${siniiga}&livestock=${livestock}`);
};

export const updateSiniiga = async (data) => {
  return await AUTHSERVICE.put('/siniiga/selectMain', data);
};

//GetSiniiga
export const getAllSiniigasCow = async (livestock) => {
  return await AUTHSERVICE.get(`/siniiga/show?livestock=${livestock}`);
};
// siniiga

// #private
export const createPrivate = async (data) => {
  delete data.id;
  return await AUTHSERVICE.post('/priv/new', data);
};

export const deletePrivate = async (siniiga, livestock) => {
  return await AUTHSERVICE.delete(`/priv/delete?priv=${siniiga}&livestock=${livestock}`);
};

export const updatePrivate = async (data) => {
  return await AUTHSERVICE.put('/priv/selectMain', data);
};

export const getAllPrivate = async (livestock) => {
  return await AUTHSERVICE.get(`/priv/show?livestock=${livestock}`);
};

// #private

/*----------------------------Configuracion --------------------------------------*/
/*---------------------------- Details -------------------------------------------*/
export const getCrias = async ({ page = 1, limit = 5, search_text, search_months }) => {
  let url = 'details/all?cycle=ternero';
  if (page) url += `&page=${page}`;
  if (limit) url += `&limit=${limit}`;
  if (search_text && search_text !== '') url += `&search_text=${search_text}`;
  if (search_months && search_months !== '') url += `&search_month=${search_months}`;
  return await AUTHSERVICE.get(url);
};

export const getRanchoDetails = async ({
  page = 1,
  limit = 5,
  id,
  search_text,
  sex,
  breed,
  status,
  cycle,
}) => {
  let url = `details/all?id=${id}`;
  if (page) url += `&page=${page}`;
  if (limit) url += `&limit=${limit}`;
  if (sex !== '') url += `&sex=${sex}`;
  if (breed !== '') url += `&breed=${breed}`;
  if (status) url += `&status=${status}`;
  if (cycle) url += `&cycle=${cycle}`;
  if (search_text !== '') url += `&search_text=${search_text}`;
  return await AUTHSERVICE.get(url);
};
export const sendNewCabezaService = async (data) => {
  return await AUTHSERVICE.post('details/new', data);
};
export const getDataIDCabezaService = async (id) => {
  return AUTHSERVICE.get(`details/show?id=${id}`);
};
export const updateFieldCabezaService = async (data, id) => {
  return AUTHSERVICE.put(`details/update?id=${id}`, { data });
};
/*---------------------------- Details -------------------------------------------*/

export const getGanadoExcel = async ({ id, search_text, sex, breed, status, cycle }) => {
  let url = `excel/download/ganado?ranch=${id}`;
  if (sex !== '' && sex) url += `&sex=${sex}`;
  if (breed !== '' && breed) url += `&breed=${breed}`;
  if (status !== '' && status) url += `&status=${status}`;
  if (cycle !== '' && cycle) url += `&cycle=${cycle}`;
  if (search_text !== '' && search_text) url += `&search_text=${search_text}`;
  return await AUTHSERVICE.get(url);
};

export const getPalpacionesExcel = async ({ id, search_text, sex, breed, status, cycle }) => {
  let url = `excel/download/palpacion?livestock=${id}`;
  if (sex !== '' && sex) url += `&sex=${sex}`;
  if (breed !== '' && breed) url += `&breed=${breed}`;
  if (status !== '' && status) url += `&status=${status}`;
  if (cycle !== '' && cycle) url += `&cycle=${cycle}`;
  if (search_text !== '' && search_text) url += `&search_text=${search_text}`;
  return await AUTHSERVICE.get(url);
};

export const getPartosExcel = async ({ id, search_text, sex, breed, status, cycle }) => {
  let url = `excel/download/gestacion?livestock=${id}`;
  if (sex !== '' && sex) url += `&sex=${sex}`;
  if (breed !== '' && breed) url += `&breed=${breed}`;
  if (status !== '' && status) url += `&status=${status}`;
  if (cycle !== '' && cycle) url += `&cycle=${cycle}`;
  if (search_text !== '' && search_text) url += `&search_text=${search_text}`;
  return await AUTHSERVICE.get(url);
};
