import React, { useContext } from "react";
import { DetailsContext } from "../../../Context/Details/context";
import { PieContext } from "../../../Context/PieCria/Context";
import { GanaderosContext } from "../../../Context/GanaderosStore/context";
import Switch from "../../Auxiliars/switch";
import { edit } from "../../constants";

import "./styles.css";

const Tabla = ({
  tablesInfo,
  getIdGanadero,
  match,
  pie,
  ranchos,
}) => {
  const { inputFilterRaza } = useContext(DetailsContext);
  const { inputFilterSiniiga } = useContext(PieContext);
  const { disableFunction } = useContext(GanaderosContext);

  let originTable = tablesInfo;

  if (match?.url?.includes("Rancho")) {
    originTable = tablesInfo.filter((item) =>
      item.Raza.includes(inputFilterRaza)
    );
  }

  if (pie) {
    originTable = tablesInfo.filter((item) =>
      item.Siniiga.includes(inputFilterSiniiga)
    );
  }

  const getRanchData = (ranchId) => {
    const ranch = ranchos.find((r) => r._id === ranchId);
    return ranch;
  };

  return (
    <div className="MainTablasContainer">
      {originTable.length > 0 ? (
        <table className="tableElement">
          <tbody className="tableTbody">
            <tr className="tableTitles">
              <th className="eachCeld">NOMBRE</th>
              <th className="eachCeld">SOBRENOMBRE</th>
              <th className="eachCeld">UPP</th>
              <th className="eachCeld">RANCHO</th>
              <th className="eachCeld">ACTIVO</th>
              <th className="tableActions">ACCIONES</th>
            </tr>
            {originTable?.map(
              (res, i) => (
                <tr className="eachRow" key={`row_${i}`}>
                  <th className="thValues">{res.name}</th>
                  <th className="thValues">{res.nickname}</th>
                  <th className="thValues">{res.upp}</th>
                  <th className="thValues">{getRanchData(res.ranch)?.name}</th>
                  <th className="thValues">{res.active ? "Si" : "No"}</th>
                  <th className="thValues">
                      <img
                        className="editable_Btn"
                        src={edit}
                        alt="edit"
                        id={res._id}
                        onClick={_ => {
                          getIdGanadero(res);
                        }}
                      />
                    <Switch
                      id={res._id}
                      identificador={`${res.name}-${res._id}`}
                      isActive={res.active}
                      setItmeToDelete={() => disableFunction(res._id)}
                    />
                  </th>
                </tr>
              )
            )}
          </tbody>
        </table>
      ) : (
        "No se encontraron resultados"
      )}
    </div>
  );
};

export default Tabla;
