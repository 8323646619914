import React, { useContext, useState, useEffect } from "react";
import { ConfiguracionContext } from "../../Context/ConfiguracionStore/context";
import { buttonsContainer, razas, rutas, ciclos } from "../constants";
import Tabla from "../Tables";
import Raza from "./Raza/Raza";
import "./styles.css";
import Usuarios from "./Usuarios/Usuarios";
import Roles from "./Roles/Roles";
import Ciclos from "./Ciclos/Ciclos";
import Admins from "./Admins/Admins";
import { MainContext } from "../../MainContext";

const Configuracion = ({ mainId, match }) => {
  const mainCtx = useContext(MainContext);
  const { user } = mainCtx;
  const configCtx = useContext(ConfiguracionContext);
  const {
    getRolFunction,
    getUsuarioFunction,
    getRazaFunction,
    /* getTipoFunction, */
    cleanInputs,
    sendNewRol,
    RolesArr,
    sendNewUsuarioGanadero,
    UsusariosArr,
    sendNewRaza,
    RazasArr,
    /*  sendNewTipo,
    TiposArr, */
  } = configCtx;

  const [rolesBoolean, setRolesBoolean] = useState(true);
  const [usuariosBoolean, setUsuariosBoolean] = useState(false);
  const [RazaBoolean, setRazaBoolean] = useState(false);
  const [TipoBoolean, setTipoBoolean] = useState(false);
  const [ciclosBoolean, setCiclosBoolean] = useState(false);
  const [adminBoolean, setadminBoolean] = useState(false);

  const [weaning, setWeaning] = useState(null);
  const [growth, setgrowth] = useState(null);
  const [permisos, setPermisos] = useState([
    "leer-Ranchos",
    "leer-Ganaderos",
    "leer-Proveedores",
    "leer-Vacunas",
    "leer-Palpación",
  ]);

  const arrFunctions = [
    setRolesBoolean,
    setUsuariosBoolean,
    setadminBoolean,
    setRazaBoolean,
    setCiclosBoolean,
    setTipoBoolean,
  ];
  useEffect(() => {}, [
    rolesBoolean,
    usuariosBoolean,
    RazaBoolean,
    TipoBoolean,
    rolesBoolean,
    ciclosBoolean,
    adminBoolean,
    permisos,
  ]);
  const PermisosFunction = (e) => {
    if (e.target.name.includes("Destete")) {
      setWeaning(e.target.name);
    } else {
      setgrowth(e.target.name);
    }
    if (e.target.checked) {
      setPermisos([...permisos, e.target.name]);
      return;
    } else {
      if (permisos.includes(e.target.name)) {
        setPermisos(permisos.filter((el) => e.target.name !== el));
      }
    }
  };
  const changeConfigView = (e, index) => {
    e.target.parentElement
      .querySelectorAll(".eachTabConfig")
      .forEach((element, k) => {
        element.style.borderBottom = `none`;
        if (k === index) {
          arrFunctions[k](true);
        } else {
          arrFunctions[k](false);
        }
      });
    e.target.style.borderBottom = `solid 2px rgba(215, 108, 28, 1)`;
    cleanInputs();
  };

  return (
    <div className="MainConfigComponent">
      <div className="tabs-Container-config">
        <div className="tabsConfigContainer">
          {buttonsContainer.map((el, i) => (
            <button
              key={`key-${i}-${el.name}`}
              className="eachTabConfig"
              name={el.name}
              type={el.type}
              onClick={(e) => {
                changeConfigView(e, i);
              }}
            >
              {el.label}
            </button>
          ))}
        </div>
        {rolesBoolean && RolesArr && (
          <Roles
            PermisosFunction={PermisosFunction}
            setWeaning={setWeaning}
            setgrowth={setgrowth}
            setPermisos={setPermisos}
            mainId={mainId}
            match={match}
            permisos={permisos}
          />
        )}

        {usuariosBoolean && UsusariosArr && <Usuarios permisos={permisos} />}

        {RazaBoolean && <Raza />}

        {ciclosBoolean && <Ciclos />}

        {adminBoolean && user.Rol === "superadmin" && <Admins />}
      </div>
    </div>
  );
};

export default Configuracion;
