import React, { useContext } from 'react'
import DetailsComponent from '../Components/Main/Details';
import Hato from '../layout/Hato_Theme'
import DetailsProvider from './../Context/Details/context';
import PieProvider from '../Context/PieCria/Context'

const Details = ({ match }) => {
    return (
        <Hato>
            <DetailsProvider>
                <PieProvider>
                    <DetailsComponent item={match.params} match={match} />
                </PieProvider>
            </DetailsProvider>
        </Hato>
    )
}

export default Details
