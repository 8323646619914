import React, { useContext, useState } from 'react';
import { GESTACION_TITLES, cattleColor, find, addColor, NewBreedGestation } from '../../../constants';
import GestationModal from './GestationInfoModal';
import CreateGestationModal from './CreateGestationModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserNurse } from '@fortawesome/free-solid-svg-icons';
import './styles.css';
import { GestacionContext } from '../../../../Context/GestacionStore/context';
import translateDate from '../../../../utils/translateDate';
import NewGanado from '../../../Forms/NewGanadoGestation';
import Modal from '../../../ModalContainer';
import { map } from 'lodash';

const Tabla = ({ tablesInfo, getIdGanadero }) => {
  const [gestationId, setGestationId] = useState('');
  const [isRes, setisRes]= useState()
  //setisRes(tablesInfo.map());

  const GestacionCtx = useContext(GestacionContext);
  const {
    GestationInfoModal,
    setGestationInfoModal,
    openModalCreateGestation,
    setOpenModalCreateGestation,
    historialErros, 
    newHistorialValues, 
    setNewHistorialValues,
    isOpenModalGanado,
    setisOpenModalGanado,
    submitNewCowGestation,
    submitErrors,
    handleCowFormInputs
  } = GestacionCtx;

  if (tablesInfo.length === 0)
    return <div className="MainTablasContainer">"No se encontraron resultados"</div>;

  const renderTitle = GESTACION_TITLES.map((el, i) => (
    <th key={`title-${i}`} className="eachCeld">
      {el}
    </th>
  ));
  
  const toggleModalRegistrarCria = (ModalInfo)=>{
    if(!ModalInfo){
      setisOpenModalGanado(!isOpenModalGanado);
    }
  }

  const toggleModalVisibitily = (id) => {
    let gestId = id;
    if (GestationInfoModal) id = '';
    setGestationInfoModal(!GestationInfoModal);
    setGestationId(gestId);
    let vaca = tablesInfo.map(function(res){
      if(res._id==gestId)
      {
        setisRes(res);  
        console.log(res)   
      }
    })

  };

  const toggleModalCreateGestation = (id) => {
    let gestId = id;
    if (openModalCreateGestation) id = '';

    setOpenModalCreateGestation(!openModalCreateGestation);
    setGestationId(gestId);
  };

  const renderRows = tablesInfo.map((res, key) => {
    let Style = { minWidth: '5rem' };
    return (
      <tr className="eachRow" key={`row_${key}`}>
        <th style={Style} key={`${res.siniiga}-${key}`} className="thValues">
          {res.siniiga ? res.siniiga : '-'}
        </th>
        <th style={Style} key={`${res.priv}-${key}`} className="thValues">
          {res.priv ? res.priv : '-'}
        </th>
        <th style={Style} key={`${res.status}-${key}`} className="thValues">
          {res.status ? res.status : '-'}
        </th>
        <th style={Style} key={`${res.labors}-${key}`} className="thValues">
          {res.labors ? res.labors : '-'}
        </th>
        <th style={Style} key={`${res.days}-${key}`} className="thValues">
          {res.days ? res.days : '-'}
        </th>
        <th style={Style} key={`${res.breed.name}-${key}`} className="thValues">
          {res.breed.name ? ` ${res.breed.name} - ${res.breed.description}` : '-'}
        </th>
        <th style={Style} key={`${res.type}-${key}`} className="thValues">
          {res.type ? res.type : '-'}
        </th>
        <th style={Style} key={`${res.date}-${key}`} className="thValues">
          {res.date ? translateDate(res.date) : '-'}
        </th>
        <th style={Style} key={`${res.ranch.name}-${key}`} className="thValues">
          {res.ranch.name ? res.ranch.name : '-'}
        </th>
        <th
          style={{
            ...Style,
            ...{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            },
          }}
          key={`${res._id}-${key}`}
          className="thValues"
        >
          <div style={{ width: '33.33%' }}>
            {res.status === 'cargada' ? (
              <img
                className="editable_Btn"
                style={{ margin: '0' }}
                src={find}
                alt="list of offspring"
                id={res._id}
                onClick={(_) => toggleModalVisibitily(res._id)}
              />
            ) : (
              <img
                className="editable_Btn"
                style={{ margin: '0' }}
                src={addColor}
                alt="list of offspring"
                id={res._id}
                onClick={(_) => toggleModalCreateGestation(res._id)}
              />
            )}
          </div>
          <div style={{ width: '33.33%' }}>
            <img
              style={{ margin: '0' }}
              className="editable_Btn"
              src={cattleColor}
              alt="list of offspring"
              id={res._id}
              onClick={(_) => {
                getIdGanadero(`${res._id}/listado-de-crias`);
              }}
            />
          </div>
          <div style={{ width: '33.33%' }}>
            <FontAwesomeIcon
              style={{ color: 'rgba(215, 108, 28, 1)', fontSize: '18px' }}
              icon={faUserNurse}
              onClick={(_) => {
                getIdGanadero(`${res._id}/listado-de-partos`);
              }}
            />
          </div>
        </th>
      </tr>
    );
  });
  return (
    <div className="MainTablasContainer">
      <table className="tableElement">
        <tbody className="tableTbody">
          <tr className="tableTitles">{renderTitle}</tr>
          {renderRows}
        </tbody>
      </table>

      {GestationInfoModal && (
        <GestationModal openCloseModal={toggleModalVisibitily} gestationId={gestationId} />
      )}
      {isOpenModalGanado && (
        <Modal
          ActiveForm={isOpenModalGanado}
          openCloseModal={() => {
            setisOpenModalGanado(false);
          }}
          submitNewItem={submitNewCowGestation}
        >
          <NewGanado
            form={NewBreedGestation}
            handleCowFormInputs={handleCowFormInputs}
            heigth={'650px'}
            submitErrors={submitErrors}
            res={isRes}
          />
        </Modal>
      )}
      {openModalCreateGestation && (
        <CreateGestationModal
          openCloseModal={toggleModalCreateGestation}
          gestationId={gestationId}
        />
      )}
    </div>
  );
};

export default Tabla;
