import React from "react";
import Vacunasprovider from "./../Context/VacunasStore/context";
import VacunassComponent from "./../Components/Main/Vacunas";
import Hato from "../layout/Hato_Theme";
const Vacunas = ({ children, match }) => {
  return (
    <>
      <Hato>
        <Vacunasprovider children={children}>
          <VacunassComponent match={match} />
        </Vacunasprovider>
      </Hato>
    </>
  );
};

export default Vacunas;
