import React, {  useContext } from 'react';
import './ProveedoresComponent.css';
import SearchBox from './SearchBar';
import { add } from './../constants'
import Tables from './../Tables';
import Modal from '../ModalContainer';
import NewGanaderos from './../Forms/NewGanaderos'
import { newProveedoresForm, tables } from '../constants';

import { ProveedoresContext } from '../../Context/ProveedoresStore/context';

const ProveedoresComponent = ( ) => {
  const proveedorContext = useContext(ProveedoresContext);
  const { 
    searchBarInput,
    setSearchBarInput,
    rows,
    setRows,
    newProveedor,
    setNewProveedor,
    ProveedoresID,
    setProveedoresID,
    isOpenModalProveedores, 
    setIsOpenModalProveedores,
    sendNewProveedores,
    getDataNewProveedores,
    isEditable,
    setIsEditable,
    getProveedorById,
    updateProveedorCtx,
  } = proveedorContext;

  const getShowDataGanaderos = (id) => console.log(id)
  const activeDeactiveGanaderosFunction = ( e ) =>console.log(e)
  
  const getNewProveedor = ( { name , value } ) => getDataNewProveedores( name, value );
  const submitNewProveedor = () => sendNewProveedores(newProveedor);
  const openCloseModalFunctionProveedores = () => setIsOpenModalProveedores(!isOpenModalProveedores);
  const getIdProveedor = ( id ) => {
    getProveedorById( id );
    openCloseModalFunctionProveedores();
  }

  const updateGanadero = (id) => updateProveedorCtx(id)
  


  return (
    <>
      <div className="GanaderosComponentContainer">
        <h1 className="Ganaderos">Proveedores</h1>
          <SearchBox/>
          <div className="cardContainerGanaderos">
          {
            <Tables 
            getIdGanadero={(id)=> {getIdProveedor(id)}}
            tablesInfo={rows} 
            setActiveRanch={activeDeactiveGanaderosFunction}/>
          }
          </div>
    </div>
 
  <div className="addGanaderosContainer" >
   <button 
     type="button" 
     className="ActiveForm"
     onClick={()=> openCloseModalFunctionProveedores()}>
      <img src={ add } alt="add"/>
    </button>
  </div>

       {
       isOpenModalProveedores && 
       <Modal
       ActiveForm={isOpenModalProveedores}
       openCloseModal={
         ()=> openCloseModalFunctionProveedores()
       }

        submitNewItem={ (id) =>  isEditable ?  updateGanadero(id) :submitNewProveedor() }
        ID={isEditable ? newProveedor?._id : ''}
        >
          <NewGanaderos 
          form={newProveedoresForm} 
          getNewGanaderos={(target)=> {getNewProveedor(target)}}
          values={isEditable? newProveedor : {}}
          isEditableForm={isEditable}
          makeEditable={() =>{}}
          />
        </Modal>
        }

    </>

  )
}

export default ProveedoresComponent;
