import React, { memo, useContext } from "react";
import { ConfiguracionContext } from "../../../Context/ConfiguracionStore/context";
import { rutas } from "../../constants";
//import Tabla from "../../Tables";
import Tabla from "./RolesTable/RolesTable";
import Modal from "../../../Components/ModalContainer";
import "../../../Components/ModalContainer/styles.css";


const Roles = memo(({ permisos, mainId }) => {
  const configCtx = useContext(ConfiguracionContext);
  const { sendNewPermision, Rol, setRol, isEditRoles,rolEditting,
    setisEditRoles,setrolEditting,sendEditPermision } = configCtx;

  const setPermisions = (e) => {
    const type = e.target.id;
    const action = e.target.name; //read, add, modified, delete
    let permissions = { ...Rol.permissions };
    permissions[type][action] = !permissions[type][action];
    setRol({
      ...Rol,
      permissions,
    });
  };

  const validateForm = () => {
    let valid = false;
    if (Rol.name === "") valid = true;
    if (Rol.email === "") valid = true;
    return valid;
  };

  const handleChange = (e) => {
    setRol((prev) => {
      return {
        ...prev,
        [e.target.name]: e.target.value,
      };
    });
  };

  const closeModal = () => {
    setisEditRoles(false);
  }

  const updateRoles = () => {
    sendEditPermision();
  }

  const handleChangeEdit = (e) => {
    setrolEditting((prev) => {
      return {
        ...prev,
        [e.target.name]: e.target.value,
      };
    });
  };




  return (
    <>

      {isEditRoles ?
        <Modal
          ActiveForm={isEditRoles}
          openCloseModal={closeModal}
        >
          <h1>Editar Rol</h1>
          <div style={{textAlign:'center'}}>
          <div className='label-gestation'>
              <label>
                Nombre
              </label>
            </div>
            <input
              type="text"
              className="inputRoles InputsConfig"
              name="name"
              placeholder="Nombre"
              value={rolEditting.name}
              onChange={handleChangeEdit}
            />


            <div>
            <div className='label-gestation'>
              <label>
                Descripción
              </label>
            </div>
            <input
              type="text"
              className="inputRoles InputsConfig"
              name="description"
              placeholder="Descripción"
              value={rolEditting.description}
              onChange={handleChangeEdit}
            />
            </div>
          </div>

          <button onClick={updateRoles} className="newRanchButtonAccept" type="button" >Actualizar</button>
          <button onClick={closeModal} className="newRanchButtonCancel" type="button">Cancelar</button>
        </Modal>
        : null}

      <div className="tabsConfigZone">
        <div className="inputsRoles">
          <div className="inputs">
            <input
              type="text"
              className="inputRoles InputsConfig"
              name="name"
              placeholder="Nombre"
              onChange={handleChange}
            />

            <input
              type="text"
              className="inputRoles InputsConfig"
              name="description"
              placeholder="Descripción"
              onChange={handleChange}
            />

            <button
              disabled={validateForm()}
              type="button"
              className="buttonsSend"
              name="newRol"
              onClick={sendNewPermision}
            >
              +
            </button>
          </div>
          <div className="controlSections">
            {rutas.map((el, i) => {
              if (el.permision === "breeding") return null;
              return (
                <div className="contentAttributes" key={`${el.permision}-${i}`}>
                  <h5>{el.nombre}</h5>
                  <label>
                    Leer
                    <input
                      type="checkbox"
                      disabled={true}
                      checked={true}
                      id={el.permision}
                      name="read"
                      onClick={setPermisions}
                    />
                  </label>
                  <label>
                    Agregar
                    <input
                      type="checkbox"
                      id={el.permision}
                      name="add"
                      onClick={setPermisions}
                    />
                  </label>
                  {el.permision !== "births" && (
                    <label>
                      Modificar
                      <input
                        id={el.permision}
                        type="checkbox"
                        name="modified"
                        onClick={setPermisions}
                      />
                    </label>
                  )}

                  {el.permision !== "births" && (
                    <label>
                      {el.permision === "ranchs" ? "Deshabilitar" : "Eliminar"}
                      <input
                        id={el.permision}
                        type="checkbox"
                        name="delete"
                        onClick={setPermisions}
                      />
                    </label>
                  )}
                </div>
              );
            })}
          </div>
        </div>

        <Tabla />
      </div>
    </>
  );
});

export default Roles;
