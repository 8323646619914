import './RazaTable/styles.css'
import React, { memo, useContext, useState } from "react";
import { ConfiguracionContext } from "../../../Context/ConfiguracionStore/context";
import { MainContext } from "../../../MainContext";
import { razas } from "../../constants";
import { getInitialDataConfig } from "./../../../servicces";
import Modal from "../../../Components/ModalContainer";
import "../../../Components/ModalContainer/styles.css";

import RazaTable from "./RazaTable/RazaTable";
const Raza = memo(() => {
  const mainCtx = useContext(MainContext);
  const configCtx = useContext(ConfiguracionContext);
  const { handleRazaValues, sendNewRaza, Raza, isEditing, setIsEditing,itemEditing,handleRazaEditValues,sendEditRaza } = configCtx;

  const valid = () => {
    if (Raza.name !== "" && Raza.description !== "") return false;
    return true;
  };

  const validEdit = () => {
    if (itemEditing.name !== "" && itemEditing.description !== "") return false;
    return true;
  };

  const closeModal = () => {
    setIsEditing(false);
  }



  const style = {
    width: "100%",
    height: "100%",
    position: "fixed",
    left: 0,
    top: 0,
    zIndex: 99,
    backgroundColor: "rgba(0,0,0,0.3)",
  }

  const modalBody = {
    position: "absolute",
    width: 500,
    top: "50%",
    left: "50%",
    transform: "translate(-50%,-50%)",
    backgroundColor: "white",
    paddingTop: "20px",
    paddingBottom: "20px"
  }

  return (
    <>
      {isEditing ?
        <Modal
          ActiveForm={isEditing}
          openCloseModal={closeModal}
        >
          <h1>Editar Raza</h1>
          <div style={{textAlign:'center'}}>
          <div className='label-gestation'>
              <label>
                Nombre
              </label>
            </div>
            <select
                value={itemEditing.name}
                type="text"
                className="inputRoles InputsConfig"
                name="name"
                onChange={handleRazaEditValues}
              >
                <option value="">Seleccione una raza</option>
                {razas.map((el, i) => (
                  <option key={`key-${i}-${el}`} value={el.trim()}>
                    {el}
                  </option>
                ))}
              </select>


            <div>
            <div className='label-gestation'>
              <label>
                Descripción
              </label>
            </div>
            <input
                value={itemEditing.description}
                type="text"
                className="inputRoles InputsConfig"
                name="description"
                placeholder="Descripción"
                onChange={handleRazaEditValues}
              />
            </div>
          </div>

          <button onClick={sendEditRaza} className="newRanchButtonAccept" type="button" >Actualizar</button>
          <button onClick={closeModal} className="newRanchButtonCancel" type="button">Cancelar</button>
        </Modal>
        : null}


      {/* {isEditing === true ?
        <div style={style}>
          <div style={modalBody}>
            <div style={{ display: "flex" }}>
              <div style={{ flex: 1 }}></div>
              <div style={{ paddingRight: "30px" }}>
                <button onClick={closeModal}>
                  X
                </button>
              </div>
            </div>

            <div style={{  display: "flex", paddingRight:"50px" }}>
              <h1>Editar raza</h1>
            </div>

            <div className="inputs">
              <select
                value={itemEditing.name}
                type="text"
                className="inputUsuarios selectRazas InputsConfig"
                name="name"
                onChange={handleRazaEditValues}
              >
                <option value="">Seleccione una raza</option>
                {razas.map((el, i) => (
                  <option key={`key-${i}-${el}`} value={el}>
                    {el}
                  </option>
                ))}
              </select>
              <input
                value={itemEditing.description}
                type="text"
                className="inputUsuarios InputsConfig"
                name="description"
                placeholder="Descripción"
                onChange={handleRazaEditValues}
              />
            </div>
            <div style={{ display: "flex", paddingRight: "30px" }}>
              <div style={{ flex: 1 }}></div>
              <button
                disabled={validEdit()}
                type="button"
                name="newRaza"
                onClick={sendEditRaza}
              >Actualizar
              </button>
            </div>
          </div>
        </div> : null
      } */}



      <div className="tabsConfigZone">
        <div className="inputsRoles">
          <div className="inputs">
            <select
              value={Raza.name}
              type="text"
              className="inputUsuarios selectRazas InputsConfig"
              name="name"
              onChange={handleRazaValues}
            >
              <option value="">Seleccione una raza</option>
              {razas.map((el, i) => (
                <option key={`key-${i}-${el}`} value={el}>
                  {el}
                </option>
              ))}
            </select>
            <input
              value={Raza.description}
              type="text"
              className="inputUsuarios InputsConfig"
              name="description"
              placeholder="Descripción"
              onChange={handleRazaValues}
            />
            <button
              disabled={valid()}
              type="button"
              className="buttonsSend"
              name="newRaza"
              onClick={sendNewRaza}
            >
              {" "}
              +{" "}
            </button>
          </div>
        </div>
        <RazaTable />
      </div>
    </>

  );
});

export default Raza;
