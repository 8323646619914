import React, { createContext, useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import {
  getDataIDCabezaService,
  getCrias,
  sendNewCabezaService,
  getBreeds,
  InitialDataPetition,
  InitialDataPetitionGanaderos,
  createSiniiga,
  deleteSiniiga,
  updateSiniiga,
  getAllSiniigasCow,
  deletePrivate,
  createPrivate,
  updatePrivate
} from "../../servicces";
import Store from "./store";
const initialQuerys = {
  page: 1,
  limit: 10,
  search_text: "",
};


export const PieContext = createContext(Store);

const PieProvider = ({ children }) => {
  const [isOpenModalGanado, setisOpenModalGanado] = useState(false);
  const [RanchoID, setRanchoID] = useState("all");
  const [Cabeza, setCabeza] = useState(Store.Cabeza);
  const [Cabezas, setCabezas] = useState([]);
  const [CabezasRow, setCabezasRow] = useState([]);
  const [isOpenModalDetails, setisOpenModalDetails] = useState(false);
  const [dataFromID, setdataFromID] = useState(null);
  const [inputFilterSiniiga, inputSetFilterSiniiga] = useState("");
  const [loading, setLoading] = useState(false);
  const [ranchPaginationInfo, setRanchPaginationInfo] = useState(null);
  const [page, setPage] = useState(1);
  const [inputSearch, setinputSearch] = useState(initialQuerys.search_text);
  const [monthInputSearch, setmonthInputSearch] = useState('');
  const [updateData, setupdateData] = useState(0);

  //siniiga
  const initSiniiga = {
    id: '',
    livestockId: '',
    siniiga: ''
  }

  const [modalnewSiniiga, setmodalnewSiniiga] = useState(false)
  const [siniigaModel, setsiniigaModel] = useState(initSiniiga)
  //endsiniiga

  // private

  //privateinit
  const privateInit = {
    id: '',
    priv: '',
    livestockId: ''
  }
  const [modalPrivate, setmodalPrivate] = useState(false)
  const [privateModel, setPrivateModel] = useState(privateInit)
  // endprivate


  const restructureData = async (data) => {
    const formatedData = data.data.livestocks.map((i) => ({
      Rancho: i.ranch.name,
      Raza: `${i.breed.name} - ${i.breed.description}`,
      Ganadero: i.rancher.name,
      Siniiga: i.siniiga,
      Fecha: i.date,
      Status: i.status,
      cycle: i.cycle,
      priv: i.priv,
      type: i.type,
      _id: i._id
    }));
    setCabezas(formatedData);
    setCabezasRow(formatedData);
    setLoading(false)
  };

  const getInitialData = async (config) => {
    const response = await getCrias(config);
    setRanchPaginationInfo(response.data.data)
    restructureData(response.data);
  };

  /* ---- --- verificar luego si se quita ---- --- */
  const getRanchoData = async (RanchoId) => {
    if (!RanchoID) {
      setRanchoID(RanchoId);
    }
    const config = {
      page,
      limit: 10,
      search_text: inputSearch,
      search_months: ''
    };
    const response = await getCrias(config);
    restructureData(response.data);
  };
  const getRanchoIdParams = async (ranchoID) => await getRanchoData(ranchoID);
  /* ---- ---  verificar luego si se quita  ---- --- */

  const getDataCabezas = ({ name, value }) => {
    setCabeza({ ...Cabeza, [name]: value });
  };

  const sendNewCabeza = async () => {
    const response = await sendNewCabezaService(Cabeza);
    if (response.data.status) {
      getInitialData(RanchoID);
      setisOpenModalGanado(false);
    }
  };

  const detailsCabeza = async (id) => {
    const response = await getDataIDCabezaService(id);

    if (response.data.status === true) {
      setdataFromID(response.data);
    }
  };

  const openModalDetails = (booleano, id) => {
    setisOpenModalDetails(booleano);
    if (id) {
      detailsCabeza(id);
    }
  };


  const handleSiniigaValue = ({ target: { name, value } }) => {
    setsiniigaModel({
      ...siniigaModel,
      [name]: value,
    });
  };

  const handleSubmitSiniigaForm = async () => {
    //create
    if (siniigaModel.id == '') {
      try {
        const response = await createSiniiga(siniigaModel);
        if (response.status == 200) {
          setmodalnewSiniiga(false);
          setupdateData(updateData + 1);
        }
      } catch (ex) {

      }
    } else {
      try {
        const response = await updateSiniiga(siniigaModel);
        setmodalnewSiniiga(false);
        setupdateData(updateData + 1);
      } catch (ex) {
      }
    }
  }

  const handleDeleteSiniiga = async (siiniga, livestock) => {
    const response = await deleteSiniiga(siiniga, livestock);
    setmodalnewSiniiga(false);
    setupdateData(updateData + 1);
  }

  //private
  const handlePrivateValueMethod = ({ target: { name, value } }) => {
    setPrivateModel({
      ...privateModel,
      [name]: value,
    });
  };

  const handleSubmitPrivateFormMethod = async () => {
    if (privateModel.id == '') {
      const response = await createPrivate(privateModel);
      if (response.status == 200) {
        setmodalPrivate(false);
        setupdateData(updateData + 1);
      }
    } else {
      const response = await updatePrivate(privateModel);
      setmodalPrivate(false);
      setupdateData(updateData + 1);
    }
  }

  const deletePrivateMethod = async (privateWord,livestock) => {
    const response = await deletePrivate(privateWord, livestock);
    setmodalPrivate(false);
    setupdateData(updateData + 1);
  }
  // endprivate


  const filterRaza = (raza) => {
    return setCabeza(CabezasRow.filter((el) => el.Raza === raza.target.value));
  };
  const filterSexo = (sexo) =>
    setCabeza(CabezasRow.filter((el) => el.Sexo === sexo));
  const filterSttatus = (status) =>
    setCabeza(CabezasRow.filter((el) => el.Statuds === status));
  const filterRangoDeNacimiento = (fecha) =>
    setCabeza((el) => new Date(el.Fecha).getTime() > new Date(fecha));

  useEffect(() => {
    getInitialData(initialQuerys);
  }, [updateData]);

  const state = {
    getRanchoIdParams,
    getDataCabezas,
    sendNewCabeza,
    openModalDetails,
    setisOpenModalGanado,
    filterRaza,
    filterSexo,
    filterSttatus,
    filterRangoDeNacimiento,
    inputFilterSiniiga,
    inputSetFilterSiniiga,
    isOpenModalGanado,
    Cabezas,
    isOpenModalDetails,
    dataFromID,
    getInitialData,
    ranchPaginationInfo,
    page,
    setPage,
    inputSearch,
    setinputSearch,
    loading,
    setLoading,
    monthInputSearch,
    setmonthInputSearch,
    modalnewSiniiga,
    setmodalnewSiniiga,
    siniigaModel,
    setsiniigaModel,
    initSiniiga,
    handleSiniigaValue,
    handleSubmitSiniigaForm,
    handleDeleteSiniiga,
    updateData, 
    setupdateData,
    //private
    handlePrivateValueMethod,
    handleSubmitPrivateFormMethod,
    deletePrivateMethod,
    privateModel, 
    setPrivateModel,
    modalPrivate,
    setmodalPrivate,
    privateInit

  };

  useEffect(() => { }, [isOpenModalDetails, dataFromID]);

  return <PieContext.Provider value={state}>{children}</PieContext.Provider>;
};

export default withRouter(PieProvider);
