import React, { memo, useContext, useEffect } from "react";
import { VacunasContext } from "../../../../Context/VacunasStore/context";
const SectionSelector = memo(() => {
  const vacunasCtx = useContext(VacunasContext);
  const { sectionSelector, setsectionSelector, grupoSelector,setlivestockPageInfo, setLivestock } = vacunasCtx;
  useEffect(() => {
    return () => {
      setsectionSelector("no");
    };
  }, []);

  const onClickSearch = (value) => {
    setlivestockPageInfo(null);
    setLivestock(null);
    setsectionSelector(value);
  };

  if (grupoSelector === "") return null;

  return (
    <div className="filterVaccines">
      <button
        className={sectionSelector === "vaccinate" ? "active" : ""}
        onClick={(_) => onClickSearch("vaccinate")}
      >
        Vacunadas
      </button>
      <button
        className={sectionSelector === "no" ? "active" : ""}
        onClick={(_) => onClickSearch("no")}
      >
        No Vacunadas
      </button>
      <button
        className={sectionSelector === "expired" ? "active" : ""}
        onClick={(_) => onClickSearch("expired")}
      >
        Vacuna Expirada
      </button>
    </div>
  );
});

export default SectionSelector;
