import React from "react";
import { Link } from "react-router-dom";
import Switch from "../../Auxiliars/switch";
import { cattle } from "../../constants";
import styles from "./styles.css";
const Card = ({
  ranch,
  title,
  prefijo,
  identificador,
  _id,
  getIdentificador,
  setActiveRanch,
  isActive,
  user,
  setItmeToDelete,
  setPage,
}) => {
  return (
    <div className={isActive ? "card" : "cardInactive"}>
      <div
        className="textContentCard"
        id={_id + "_" + identificador}
        onClick={(e) =>
          isActive ? getIdentificador(e.currentTarget.id) : null
        }
      >
        <h5
          className={isActive ? "titleCard" : "titleCardInactive"}
        >{`${title}`}</h5>
        <span >Ganado disponible:</span>
        <br></br>
        <span >{ranch.livestocks}</span>

        {/* <p className={isActive ? "identificadorCard" : "identificadorCardInactive"}>{`${prefijo}-${identificador}`}</p> */}
      </div>
      <div className="footCard">
        <div className="switchbuttonContainer">
          <div className="switchButtonContent">
            {isActive && (
              <Link className="cattleId" to={`/Rancho/${_id}`}>
                <img
                  className="imgCattleCard"
                  src={cattle}
                  alt={`${prefijo}-${identificador}`}
                />
              </Link>
            )}
            <Switch
              id={_id}
              title={title}
              identificador={identificador}
              setActiveIDSwitch={setActiveRanch}
              isActive={isActive}
              user={user}
              setItmeToDelete={setItmeToDelete}
              setPage={setPage}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Card;
