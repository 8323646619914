import React, { useContext } from "react";
import "./styles.css";
import { VacunasListContext } from "../../../Context/VaccineListStore/context";
import { add } from "../../constants";
import { ToastContainer } from "react-toastify";

import VaccineTable from "./VaccineTable/VaccineTable";
import GroupTable from "./GroupTable/GroupTable";

import Modal from "../../ModalContainer";
import { Grupo, Vacuna } from "../../Forms/VacunasForm";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const VacunasComponent = ({ match }) => {
  const vacunasCtx = useContext(VacunasListContext);
  const history = useHistory();
  const {
    isEditable,
    vacunaID,
    setGrupoForm,
    sendNewGroup,
    sendNewVacuna,
    setGroupId,
    isModalGrupoActive,
    closeActiveModalGroup,
    groupModal,
    setgroupModal,
    vaccineModal,
    setvaccineModal,
  } = vacunasCtx;

  const closeGroupModal = () => {
    setgroupModal(false);
  };
  
  return (
    <div className="mainVacunasContainer">
      <div className="TabsVacunasContainer">
        <div className="TabsVacunas_btns">
          <button
            style={{ borderBottom: "none" }}
            className="btn_CartillaTab"
            name="cartilla"
            type="button"
            onClick={(e) => history.push("/Vacunas")}
          >
            Cartilla de vacunación
          </button>
          <button
            style={{ borderBottom: "solid 2px rgba(215, 108, 28, 1)" }}
            className="btn_CartillaTab"
            name="listadoDeVacunas"
            type="button"
          >
            Grupo de vacunas
          </button>
        </div>
      </div>
      
      <GroupTable />

      <div className="addVacunasContainer">
        <button
          type="button"
          name="Grupo"
          className="ActiveForm"
          onClick={() => {
            setgroupModal(true);
          }}
        >
          <img src={add} alt="add" />
        </button>
      </div>
      {groupModal && (
        <Modal
          ActiveForm={groupModal}
          openCloseModal={closeGroupModal}
          submitNewItem={sendNewGroup}
          ID={isEditable ? vacunaID._id : ""}
        >
          <Grupo
            isEditable={false}
            setGrupoForm={(e) => {
              setGrupoForm(e);
            }}
          />
        </Modal>
      )}

      {vaccineModal && (
        <Modal
          ActiveForm={vaccineModal}
          openCloseModal={() => setvaccineModal(false)}
          submitNewItem={sendNewVacuna}
          ID={isEditable ? vacunaID._id : ""}
        >
          <Vacuna />
        </Modal>
      )}

      {isModalGrupoActive && (
        <div className="listadoDevacunasPorGrupo">
          <div className="contetnVacunas">
            <div className="closeSection">
              <button
                type="button"
                name="Close"
                onClick={() => {
                  setGroupId("");
                  closeActiveModalGroup();
                }}
              >
                X
              </button>
            </div>
            <VaccineTable />
          </div>
        </div>
      )}
      <ToastContainer />
    </div>
  );
};

export default VacunasComponent;
