import moment from "moment";
const renderDate = (date) => {
  var localLocale = moment(date);
  moment.lang("es", {
    months:
      "Enero_Febrero_Marzo_Abril_Mayo_Junio_Julio_Agosto_Septiembre_Octubre_Noviembre_Diciembre".split(
        "_"
      ),
    monthsShort:
      "Ene_Feb_Mar_Abr_May_Jun_Jul_Ago_Sept_Oct_Nov_Dic".split("_"),
  });
  localLocale.locale(false);
  return localLocale.format("D-MMM-YYYY");
};
export default renderDate;
