export default{
filters:{

},
  Grupo:'',
  Vacuna:{
    Nombre:'',
    Presentacion:'',
    Via_de_Administracion:'',
    Dosis:'',
    x_kg_Peso:''
  },
  Aplican:{
    Raza:'',
    Sexo:'',
    Ciclo:'',
    Periodo:''
  },
  Rango_de_Nacimoento:'',
  Raza:'',
  Periodo:'',
}




