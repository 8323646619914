export default {
  Cabeza: {
    sex: "",
    status: "",
    type: "",
    priv: "",
    date: "",
    siniiga: "",
    siniigaM: "",
    rancherM: "",
    pedigreeM: "",
    ranchM: "",
    siniigaH: "",
    rancherH: "",
    pedigreeH: "",
    ranchH: "",
    breed: "",
    ranch: "",
    rancher: "",
  },
};

/* Siniiga:'',
        Rancho:'',
        Raza:'',
        Ganadero:'',
        Sexo:'',
        Status:'',
        Fecha:'',
        Ciclo:'',
        Observaciones:'',
        SiniigaM:'',
        RanchoM:'',
        GanaderoM:'',
        PedigreeM:'',
        SiniigaH:'',
        RanchoH:'',
        GanaderoH:'',
        PedigreeH:'', */
