import ranch from './../images/ranch.svg';
import rancher from './../images/rancher.svg';
import supplier from './../images/supplier.svg';
import vaccine from './../images/vaccine.svg';
import palpation from './../images/palpation.svg';
import palpation_c from './../images/palpation_color.svg';
import cattle from './../images/cattle.svg';
import cattleColor from './../images/cattle_color.svg';
import responsable from './../images/responsable_color.svg';
import ranchColor from './../images/ranch_color.svg';
import intention from './../images/intention_color.svg';
import dimension from './../images/dimension_color.svg';
import logo from './../images/logo.svg';
import favicon from './../images/favicon.png';
import faviconColor from './../images/favicon.png';
import city from './../images/city_color.svg';
import country from './../images/country_color.svg';
import pedigrie from './../images/pedigree_color.svg';
import siniga from './../images/siniiga_color.svg';
import vaccineColor from './../images/vaccine_color.svg';
import genderColor from './../images/gender_color.svg';
import stateColor from './../images/state_color.svg';
import presentation from './../images/presentation_color.svg';
import dose from './../images/dose_color.svg';
import admin_route from './../images/administration_route_color.svg';
import background from './../images/cattle_background.png';
import arrowdown from './../images/expand_more_black_24dp.svg';
import find from './../images/search_black_24dp.svg';
import add from './../images/add_black_24dp.svg';
import edit from './../images/edit_black_24dp.svg';
import done from './../images/done.svg';
import notes from './../images/notes.svg';
import arrowR from './../images/chevron_right_black_24dp.svg';
import grupo from './../images/group_black_24dp.svg';
import logout from './../images/logout_black_24dp.svg';
import settings from './../images/settings_black_24dp.svg';
import trash from './../images/trash.svg';
import addColor from '../images/add_color_24dp.svg';

export const rutas = [
  {
    ruta: '/Rancho',
    icon: ranch,
    nombre: 'Ranchos',
    permision: 'ranchs',
  },
  {
    ruta: '/Ganaderos',
    icon: rancher,
    nombre: 'Ganaderos',
    permision: 'ranchers',
  },
  {
    ruta: '/Vacunas',
    icon: vaccine,
    nombre: 'Vacunas',
    permision: 'vaccines',
  },
  {
    ruta: '/Palpacion',
    icon: palpation,
    nombre: 'Palpación',
    permision: 'palpations',
  },
  {
    ruta: '/PieCria',
    icon: cattle,
    nombre: 'Cría',
    permision: 'breeding',
  },
  {
    ruta: '/Gestacion',
    icon: cattle,
    nombre: 'Gestación',
    permision: 'births',
  },
];

export const ciclos = [
  {
    nombre: 'Destete',
  },
  {
    nombre: 'Desarrollo',
  },
];

export const floatMenu = [
  {
    ruta: '/Configuracion',
    icon: vaccine,
    nombre: 'Configuración',
  },
  {
    ruta: '/Palpacion',
    icon: palpation,
    nombre: 'Cerrar sesión',
  },
];

export { default as cattle } from './../images/cattle.svg';
export { default as cattleColor } from './../images/cattle_color.svg';
export { default as responsable } from './../images/responsable_color.svg';
export { default as ranchColor } from './../images/ranch_color.svg';
export { default as intention } from './../images/intention_color.svg';
export { default as dimension } from './../images/dimension_color.svg';
export { default as logo } from './../images/logo.svg';
export { default as favicon } from './../images/favicon.png';
export { default as faviconColor } from './../images/favicon.png';
export { default as city } from './../images/city_color.svg';
export { default as country } from './../images/country_color.svg';
export { default as pedigrie } from './../images/pedigree_color.svg';
export { default as siniga } from './../images/siniiga_color.svg';
export { default as vaccineColor } from './../images/vaccine_color.svg';
export { default as genderColor } from './../images/gender_color.svg';
export { default as stateColor } from './../images/state_color.svg';
export { default as presentation } from './../images/presentation_color.svg';
export { default as dose } from './../images/dose_color.svg';
export { default as admin_route } from './../images/administration_route_color.svg';
export { default as background } from './../images/cattle_background.png';
export { default as arrowdown } from './../images/expand_more_black_24dp.svg';
export { default as find } from './../images/search_black_24dp.svg';
export { default as add } from './../images/add_black_24dp.svg';
export { default as addColor } from './../images/add_color_24dp.svg';
export { default as trash } from './../images/trash.svg';

export { default as edit } from './../images/edit_black_24dp.svg';
export { default as done } from './../images/done.svg';
export { default as notes } from './../images/notes.svg';
export { default as palpation_c } from './../images/palpation_color.svg';
export { default as arrowR } from './../images/chevron_right_black_24dp.svg';
export { default as grupo } from './../images/group_black_24dp.svg';
export { default as logout } from './../images/logout_black_24dp.svg';
export { default as settings } from './../images/settings_black_24dp.svg';

export const cards = [
  {
    title: 'Rancho ',
    prefijo: 'Hato-',
    identificador: 345,
    _id: 'idUnique',
  },
  {
    title: 'Rancho ',
    prefijo: 'Hato-',
    identificador: 678,
    _id: 'idUnique',
  },
  {
    title: 'Rancho ',
    prefijo: 'Hato-',
    identificador: 912,
    _id: 'idUnique',
  },
];

export const tables = [
  {
    _id: 'oinajkivnu2099023m239inp928fhun',
    Nombre: 'Nombre 1',
    UPP: Math.round(Math.random() * 1000),
    SobreNombre: 'Sobrenombre',
    Rancho: 'rancho $',
  },
  {
    _id: '610d60e1fc13ae781f000000',
    Nombre: 'Nombre 1',
    UPP: Math.round(Math.random() * 1000),
    SobreNombre: 'Sobrenombre',
    Rancho: 'rancho $',
  },
  {
    _id: '610d60e1fc13ae781f000001',
    Nombre: 'Nombre 1',
    UPP: Math.round(Math.random() * 1000),
    SobreNombre: 'Sobrenombre',
    Rancho: 'rancho $',
  },
  {
    _id: '610d60e1fc13ae781f000002',
    Nombre: 'Nombre 1',
    UPP: Math.round(Math.random() * 1000),
    SobreNombre: 'Sobrenombre',
    Rancho: 'rancho $',
  },
  {
    _id: '610d60e1fc13ae781f000003',
    Nombre: 'Nombre 1',
    UPP: Math.round(Math.random() * 1000),
    SobreNombre: 'Sobrenombre',
    Rancho: 'rancho $',
  },
  {
    _id: '610d60e1fc13ae781f000004',
    Nombre: 'Nombre 1',
    UPP: Math.round(Math.random() * 1000),
    SobreNombre: 'Sobrenombre',
    Rancho: 'rancho $',
  },
  {
    _id: '610d60e1fc13ae781f000005',
    Nombre: 'Nombre 1',
    UPP: Math.round(Math.random() * 1000),
    SobreNombre: 'Sobrenombre',
    Rancho: 'rancho $',
  },
  {
    _id: '610d60e1fc13ae781f000006',
    Nombre: 'Nombre 1',
    UPP: Math.round(Math.random() * 1000),
    SobreNombre: 'Sobrenombre',
    Rancho: 'rancho $',
  },
];

export const Grupos = [
  {
    _id: 'oinajkivnu2099023m239inp928fhun',
    Nombre: 'Nombre 1',
    Grupo: Math.round(Math.random() * 1000),
  },
  {
    _id: '610d60e1fc13ae781f000000',
    Nombre: 'Nombre 1',
    Grupo: Math.round(Math.random() * 1000),
  },
];

export const VacinnesArr = [
  {
    _id: '610d60e1fc13ae781f000001',
    Vacuna: 'Sample',
    Presentación: 'Sample',
    Via: 'Sample',
    Dosis: 'Sample',
    x_Kg_Peso: 'Sample',
  },

  {
    _id: '610d60e1fc13ae781f000002',
    Vacuna: 'Sample',
    Presentación: 'Sample',
    Via: 'Sample',
    Dosis: 'Sample',
    x_Kg_Peso: 'Sample',
  },

  {
    _id: '610d60e1fc13ae781f000004',
    Vacuna: 'Sample',
    Presentación: 'Sample',
    Via: 'Sample',
    Dosis: 'Sample',
    x_Kg_Peso: 'Sample',
  },
];

export let newRanchForm = {
  Maintitle: 'Agregar Rancho',
  MaintitleEdit: 'Editar Rancho',
  cancelar: 'Cancelar',
  accept: 'Guardar',
  mainClass: 'newRanchForm',
  typeClassAccept: 'newRanchButtonAccept',
  typeClassCancel: 'newRanchButtonCancel',
  parts: 2,
  tabs: {
    1: 'Información',
    2: 'Ubicación',
  },
  part1: [
    {
      label: 'Responsable',
      type: 'text',
      icon: responsable,
      name: 'inCharge',
    },
    {
      label: 'Nombre del Rancho',
      type: 'text',
      icon: ranchColor,
      name: 'name',
    },
    {
      label: 'Proposito',
      type: 'text',
      icon: intention,
      name: 'purpose',
    },
    {
      label: 'Dimensión (ha)',
      type: 'text',
      icon: dimension,
      name: 'dimension',
    },
    {
      label: 'País',
      type: 'select',
      values: [
        {
          value: 'Mexico',
        },
        /* {
          value: "EU",
        }, */
      ],
      icon: country,
      name: 'country',
    },
    {
      label: 'Estado',
      type: 'select',
      values: [],
      icon: stateColor,
      name: 'state',
    },
    {
      label: 'Ciudad',
      type: 'select',
      values: [],
      icon: city,
      name: 'city',
    },
  ],

  part2: [
    {
      label: 'País',
      type: 'select',
      values: [
        {
          value: 'Mexico',
        },
        /* {
          value: "EU",
        }, */
      ],
      icon: country,
      name: 'country',
    },
    {
      label: 'Estado',
      type: 'select',
      values: [],
      icon: stateColor,
      name: 'state',
    },
    {
      label: 'Ciudad',
      type: 'select',
      values: [],
      icon: city,
      name: 'city',
    },
  ],
};

export let newGanaderosForm = {
  Maintitle: 'Agregar Ganadero',
  MaintitleEdit: 'Editar Ganadero',
  cancelar: 'Cancelar',
  accept: 'Guardar',
  mainClass: 'newRanchForm',
  typeClassAccept: 'newRanchButtonAccept',
  typeClassCancel: 'newRanchButtonCancel',
  parts: 1,
  tabs: {
    // 1:'Información',
    // 2:'Ubicación'
  },

  part1: [
    {
      title: 'Nombre',
      label: 'Nombre',
      type: 'text',
      icon: responsable,
      name: 'name',
    },
    {
      title: 'UPP',
      label: 'UPP',
      type: 'text',
      icon: ranchColor,
      name: 'upp',
    },
    {
      title: 'Sobrenombre',
      label: 'Sobrenombre',
      type: 'text',
      icon: intention,
      name: 'nickname',
    },
    {
      title: 'Rancho',
      label: 'Rancho',
      type: 'select',
      icon: dimension,
      name: 'ranch',
    },
  ],
};

export let newProveedoresForm = {
  Maintitle: 'Agregar Proveedor',
  cancelar: 'Cancelar',
  accept: 'Guardar',
  mainClass: 'newRanchForm',
  typeClassAccept: 'newRanchButtonAccept',
  typeClassCancel: 'newRanchButtonCancel',
  parts: 1,
  tabs: {
    // 1:'Información',
    // 2:'Ubicación'
  },

  part1: [
    {
      label: 'Nombre',
      type: 'text',
      icon: responsable,
      name: 'nombre',
    },
    {
      label: 'UPP',
      type: 'text',
      icon: ranchColor,
      name: 'upp',
    },
  ],
};

export let palpacionForm = {
  title: 'Palpación',
  form: [
    {
      label: '1° Palpada',
      type: 'text',
      icon: palpation_c,
      name: 'palpated',
    },
    {
      label: 'Método de reproducción',
      type: 'text',
      icon: genderColor,
      name: 'reproduction',
    },
    {
      label: '2° Palpada',
      type: 'text',
      icon: palpation_c,
      name: 'confirmation',
    },
    {
      label: 'Observaciones',
      type: 'text',
      icon: palpation_c,
      name: 'observations',
    },
    {
      label: 'Fecha de gestación',
      type: 'date',
      icon: siniga,
      name: 'date',
    },
    {
      label: 'Semen',
      type: 'text',
      icon: genderColor,
      name: 'semen',
    },
    {
      label: 'SINIIGA M',
      type: 'text',
      icon: siniga,
      name: 'siniigaM',
    },
    {
      label: 'Status',
      type: 'select',
      icon: cattleColor,
      name: 'status',
    },
  ],
};

export let birthsForm = {
  title: 'Generar parto',
  form: [
    {
      label: 'Fecha de parto',
      type: 'date',
      icon: siniga,
      name: 'dueDate',
    },
    {
      label: 'Status',
      type: 'select',
      icon: cattleColor,
      name: 'status',
    },
  ],
};

export let cartilla = {
  selectors: [
    {
      name: 'Rango_de_Nacimoento',
      type: 'select',
    },
    {
      name: 'Raza',
      type: 'select',
    },
    {
      name: 'Periodo',
      type: 'select',
    },
  ],

  rows: [
    {
      ID: 1,
      Siniiga: 'Siniga',
      Raza: 'raza',
      Sexo: 'Sexo',
      Ciclo: 'ciclo',
    },
  ],
};

const daySeconds = 1000 * 3600 * 24;

export const PeriodoIntervalos = {};

export const Razas = [
  {
    Raza: 'Raza 0',
    Clave: 'R0',
  },
  {
    Raza: 'Raza 1',
    Clave: 'R1',
  },
  {
    Raza: 'Raza 2',
    Clave: 'R2',
  },
  {
    Raza: 'Raza 3',
    Clave: 'R3',
  },
  {
    Raza: 'Raza 4',
    Clave: 'R4',
  },
  {
    Raza: 'Raza 5',
    Clave: 'R5',
  },
  {
    Raza: 'Raza 6',
    Clave: 'R6',
  },
];

export const buttonsContainer = [
  {
    name: 'Permisos',
    label: 'Permisos',
    type: 'button',
  },
  {
    name: 'Usuarios',
    label: 'Usuarios',
    type: 'button',
  },
  {
    name: 'Admins',
    label: 'Admins',
    type: 'button',
  },
  {
    name: 'Raza',
    label: 'Raza',
    type: 'button',
  },
  {
    name: 'Ciclos',
    label: 'Ciclos de vida',
    type: 'button',
  },
];

export const roles = [
  {
    Rol: 'Rol -',
    Descripcion: ' Descripción',
  },
];

export const Usuarios = [
  {
    Correo: 'correo',
    Nombre: ' Nombre ',
    Rol: 'Rol-',
  },
];

export const RazasArr = [
  {
    Raza: 'Raza 1',
    Descripcion: 'Descripción',
  },
];

export const tipodeMuerte = [
  {
    Tipo: 'Tipo',
    Descripcion: 'Descripción',
  },
];

export const cabezas = [
  {
    _id: '611cd36afc13ae43430001b5',
    Rancho: 'Rancho',
    Raza: 'Raza',
    Ganadero: 'ganadero',
    Procedencia: 'Procedencia',
    Sexo: ' sexo',
    Ciclo: 'ciclo',
    Status: 'status',
  },
  {
    _id: '611cd36afc13ae43430001c0',
    Rancho: 'Rancho',
    Raza: 'Raza',
    Ganadero: 'ganadero',
    Procedencia: 'Procedencia',
    Sexo: ' sexo',
    Ciclo: 'ciclo',
    Status: 'status',
  },
  {
    _id: '611cd36afc13ae43430001c2',
    Rancho: 'Rancho',
    Raza: 'Raza',
    Ganadero: 'ganadero',
    Procedencia: 'Procedencia',
    Sexo: ' sexo',
    Ciclo: 'ciclo',
    Status: 'status',
  },
  {
    _id: '611cd36afc13ae43430001c4',
    Rancho: 'Rancho',
    Raza: 'Raza',
    Ganadero: 'ganadero',
    Procedencia: 'Procedencia',
    Sexo: ' sexo',
    Ciclo: 'ciclo',
    Status: 'status',
  },
  {
    _id: '611cd36afc13ae43430001c1',
    Rancho: 'Rancho',
    Raza: 'Raza',
    Ganadero: 'ganadero',
    Procedencia: 'Procedencia',
    Sexo: ' sexo',
    Ciclo: 'ciclo',
    Status: 'status',
  },
  {
    _id: '611cd36afc13ae43430001c4',
    Rancho: 'Rancho',
    Raza: 'Raza',
    Ganadero: 'ganadero',
    Procedencia: 'Procedencia',
    Sexo: ' sexo',
    Ciclo: 'ciclo',
    Status: 'status',
  },
  {
    _id: '611cd36afc13ae43430001bb',
    Rancho: 'Rancho',
    Raza: 'Raza',
    Ganadero: 'ganadero',
    Procedencia: 'Procedencia',
    Sexo: ' sexo',
    Ciclo: 'ciclo',
    Status: 'status',
  },
];

export let Ganado = {
  Maintitle: 'Agregar Ganado',
  cancelar: 'Cancelar',
  accept: 'Guardar',
  mainClass: 'newGanado',
  typeClassAccept: 'newGanadoButtonAccept',
  typeClassCancel: 'newGanadoButtonCancel',
  parts: 3,
  tabs: {
    1: 'Ganado',
    2: 'Progenitor H',
    3: 'Progenitor M',
  },

  part1: [
    {
      title: 'Ranchos',
      label: 'Seleccione un rancho',
      type: 'select',
      icon: ranchColor,
      name: 'ranch',
    },
    {
      title: 'Raza',
      label: 'Seleccione la raza',
      type: 'select',
      icon: siniga,
      name: 'breed',
    },
    {
      title: 'Ganadero',
      label: 'Seleccione al ganadero',
      type: 'select',
      icon: responsable,
      name: 'rancher',
    },
    {
      title: 'Sexo',

      label: 'Seleccione el sexo',
      type: 'select',
      icon: genderColor,
      name: 'sex',
    },
    {
      title: 'Estatus',
      label: 'Seleccione un estatus',
      type: 'select',
      icon: done,
      name: 'status',
    },
    {
      title: 'Fecha de monta',
      label: 'Fecha de monta',
      type: 'date',
      icon: notes,
      name: 'reproductionDate',
    },
    {
      title: 'SINIIGA',
      label: 'SINIIGA',
      type: 'text',
      icon: siniga,
      name: 'siniiga',
    },
    {
      title: 'Numero privado',
      label: 'Numero privado',
      type: 'text',
      icon: siniga,
      name: 'priv',
    },
    {
      title: 'Tipo de ganado',
      label: 'Seleccione un tipo',
      type: 'select',
      icon: done,
      name: 'type',
    },
    {
      title: 'Fecha de nacimiento',
      label: 'Fecha de nacimiento',
      type: 'date',
      icon: dimension,
      name: 'date',
    },

    // {
    //   label: "Ciclo",
    //   type:"text",
    //   icon:genderColor,
    //   name:"Ciclo"
    // },
  ],

  part2: [
    {
      title: 'Siniiga de la madre',
      label: 'Siniiga de la madre',
      type: 'text',
      icon: siniga,
      name: 'siniigaH',
    },
    {
      title: 'Rancho',
      label: 'Rancho',
      type: 'text',
      icon: ranchColor,
      name: 'ranchH',
    },
    {
      title: 'Ganadero',
      label: 'Ganadero',
      type: 'text',
      icon: responsable,
      name: 'rancherH',
    },
    {
      title: 'Privado/Pedigree',
      label: 'Privado/Pedigree',
      type: 'text',
      icon: genderColor,
      name: 'pedigreeH',
    },
  ],

  part3: [
    {
      title: 'Siniiga del padre',
      label: 'Siniiga del padre',
      type: 'text',
      icon: siniga,
      name: 'siniigaM',
    },
    {
      title: 'Rancho',
      label: 'Rancho',
      type: 'text',
      icon: ranchColor,
      name: 'ranchM',
    },
    {
      title: 'Ganadero',
      label: 'Ganadero',
      type: 'text',
      icon: responsable,
      name: 'rancherM',
    },
    {
      title: 'Privado/Pedigree',
      label: 'Privado/Pedigree',
      type: 'text',
      icon: genderColor,
      name: 'pedigreeM',
    },
  ],
};

export let EditarProgenitorH = {
  Maintitle: 'Editar Progenitor Hembra',
  cancelar: 'Cancelar',
  accept: 'Guardar',
  mainClass: 'newGanado',
  typeClassAccept: 'newGanadoButtonAccept',
  typeClassCancel: 'newGanadoButtonCancel',
  parts: 1,
  part1: [
    {
      title: 'Siniiga de la madre',
      label: 'Siniiga de la madre',
      type: 'text',
      icon: siniga,
      name: 'siniigaH',
    },
    {
      title: 'Rancho',
      label: 'Rancho',
      type: 'text',
      icon: ranchColor,
      name: 'ranchH',
    },
    {
      title: 'Ganadero',
      label: 'Ganadero',
      type: 'text',
      icon: responsable,
      name: 'rancherH',
    },
    {
      title: 'Privado/Pedigree',
      label: 'Privado/Pedigree',
      type: 'text',
      icon: genderColor,
      name: 'pedigreeH',
    },
  ],
};

export let EditarProgenitorM = {
  Maintitle: 'Editar Progenitor Macho',
  cancelar: 'Cancelar',
  accept: 'Guardar',
  mainClass: 'newGanado',
  typeClassAccept: 'newGanadoButtonAccept',
  typeClassCancel: 'newGanadoButtonCancel',
  parts: 1,
  part1: [
    {
      title: 'Siniiga del padre',
      label: 'Siniiga del padre',
      type: 'text',
      icon: siniga,
      name: 'siniigaM',
    },
    {
      title: 'Rancho',
      label: 'Rancho',
      type: 'text',
      icon: ranchColor,
      name: 'ranchM',
    },
    {
      title: 'Ganadero',
      label: 'Ganadero',
      type: 'text',
      icon: responsable,
      name: 'rancherM',
    },
    {
      title: 'Privado/Pedigree',
      label: 'Privado/Pedigree',
      type: 'text',
      icon: genderColor,
      name: 'pedigreeM',
    },
  ],
};

export let NewBreed = {
  Maintitle: 'Agregar Ganado',
  cancelar: 'Cancelar',
  accept: 'Guardar',
  mainClass: 'newGanado',
  typeClassAccept: 'newGanadoButtonAccept',
  typeClassCancel: 'newGanadoButtonCancel',
  parts: 3,
  tabs: {
    1: 'Ganado',
    2: 'Progenitor M',
    3: 'Progenitor H',
  },

  part1: [
    {
      title: 'Ranchos',
      label: 'Seleccione un rancho',
      type: 'select',
      icon: ranchColor,
      name: 'ranch',
    },
    {
      title: 'Raza',
      label: 'Seleccione la raza',
      type: 'select',
      icon: siniga,
      name: 'breed',
    },
    {
      title: 'Ganadero',
      label: 'Seleccione al ganadero',
      type: 'select',
      icon: responsable,
      name: 'rancher',
    },
    {
      title: 'Sexo',

      label: 'Seleccione el sexo',
      type: 'select',
      icon: genderColor,
      name: 'sex',
    },
    {
      title: 'Estatus',
      label: 'Seleccione un estatus',
      type: 'select',
      icon: done,
      name: 'status',
    },
    {
      title: 'SINIIGA',
      label: 'SINIIGA',
      type: 'text',
      icon: siniga,
      name: 'siniiga',
    },
    {
      title: 'Numero privado',
      label: 'Numero privado',
      type: 'text',
      icon: siniga,
      name: 'priv',
    },
    {
      title: 'Tipo de ganado',
      label: 'Seleccione un tipo',
      type: 'select',
      icon: done,
      name: 'type',
    },
    {
      title: 'Fecha de nacimiento',
      label: 'Fecha de nacimiento',
      type: 'date',
      icon: dimension,
      name: 'date',
    },
  ],

  part2: [
    {
      title: 'Siniiga de la madre',
      label: 'Siniiga de la madre',
      type: 'text',
      icon: siniga,
      name: 'siniigaH',
    },
    {
      title: 'Rancho',
      label: 'Rancho',
      type: 'text',
      icon: ranchColor,
      name: 'ranchH',
    },
    {
      title: 'Ganadero',
      label: 'Ganadero',
      type: 'text',
      icon: responsable,
      name: 'rancherH',
    },
    {
      title: 'Privado/Pedigree',
      label: 'Privado/Pedigree',
      type: 'text',
      icon: genderColor,
      name: 'pedigreeH',
    },
  ],

  part3: [
    {
      title: 'Siniiga del padre',
      label: 'Siniiga del padre',
      type: 'text',
      icon: siniga,
      name: 'siniigaM',
    },
    {
      title: 'Rancho',
      label: 'Rancho',
      type: 'text',
      icon: ranchColor,
      name: 'ranchM',
    },
    {
      title: 'Ganadero',
      label: 'Ganadero',
      type: 'text',
      icon: responsable,
      name: 'rancherM',
    },
    {
      title: 'Privado/Pedigree',
      label: 'Privado/Pedigree',
      type: 'text',
      icon: genderColor,
      name: 'pedigreeM',
    },
  ],
};

export let NewBreedGestation = {
  Maintitle: 'Agregar Ganado',
  cancelar: 'Cancelar',
  accept: 'Guardar',
  mainClass: 'newGanado',
  typeClassAccept: 'newGanadoButtonAccept',
  typeClassCancel: 'newGanadoButtonCancel',
  parts: 2,
  tabs: {
    1: 'Ganado',
    2: 'Progenitor H',
  },

  part1: [
    {
      title: 'Ranchos',
      label: 'Seleccione un rancho',
      type: 'select',
      icon: ranchColor,
      name: 'ranch',
    },
    {
      title: 'Raza',
      label: 'Seleccione la raza',
      type: 'select',
      icon: siniga,
      name: 'breed',
    },
    {
      title: 'Ganadero',
      label: 'Seleccione al ganadero',
      type: 'select',
      icon: responsable,
      name: 'rancher',
    },
    {
      title: 'Sexo',

      label: 'Seleccione el sexo',
      type: 'select',
      icon: genderColor,
      name: 'sex',
    },
    {
      title: 'Estatus',
      label: 'Seleccione un estatus',
      type: 'select',
      icon: done,
      name: 'status',
    },
    {
      title: 'SINIIGA',
      label: 'SINIIGA',
      type: 'text',
      icon: siniga,
      name: 'siniiga',
    },
    {
      title: 'Numero privado',
      label: 'Numero privado',
      type: 'text',
      icon: siniga,
      name: 'priv',
    },
    {
      title: 'Tipo de ganado',
      label: 'Seleccione un tipo',
      type: 'select',
      icon: done,
      name: 'type',
    },
    {
      title: 'Fecha de nacimiento',
      label: 'Fecha de nacimiento',
      type: 'date',
      icon: dimension,
      name: 'date',
    },
  ],

  part2: [
    {
      title: 'Siniiga del padre',
      label: 'Siniiga del padre',
      type: 'text',
      icon: siniga,
      name: 'siniigaM',
    },
    {
      title: 'Rancho',
      label: 'Rancho',
      type: 'text',
      icon: ranchColor,
      name: 'ranchM',
    },
    {
      title: 'Ganadero',
      label: 'Ganadero',
      type: 'text',
      icon: responsable,
      name: 'rancherM',
    },
    {
      title: 'Privado/Pedigree',
      label: 'Privado/Pedigree',
      type: 'text',
      icon: genderColor,
      name: 'pedigreeM',
    },
  ],
};
export const NewBreed2 = {
  Maintitle: 'Agregar Ganado',
  cancelar: 'Cancelar',
  accept: 'Guardar',
  mainClass: 'newGanado',
  typeClassAccept: 'newGanadoButtonAccept',
  typeClassCancel: 'newGanadoButtonCancel',
  parts: 1,
  part1: [
    {
      title: 'Cantidad de Hembras',
      label: 'Cantidad de Hembras',
      type: 'number',
      icon: siniga,
      name: 'calvesH',
    },
    {
      title: 'Cantidad de Machos',
      label: 'Cantidad de Machos',
      type: 'number',
      icon: siniga,
      name: 'calvesM',
    },
    {
      title: 'Fecha de nacimiento',
      label: 'Fecha de nacimiento',
      type: 'date',
      icon: dimension,
      name: 'date',
    },
  ],
};

export const razas = [
  'ABONDANCE',
  'AMERICAN BREED',
  'ANGUS NEGRO ',
  'ANGUS ROJO ',
  'AUBRAC',
  'BALANCER',
  'BARZONA',
  'BEEFMASTER ',
  'BELGIAN BLUE',
  'BELTED GALLOWAY',
  'BLANCA BRITANICA',
  'BORAN ',
  'BRAFORD',
  'BRAHMAN GRIS',
  'BRAHMAN ROJO ',
  'BRANGUS NEGRO ',
  'BRANGUS ROJO ',
  'BRAUNVIEH ',
  'CHARBRAY ',
  'CHAROLAIS',
  'CHIANINA',
  'CRIOLLO ',
  'CRUZA ',
  'DEVON DEL NORTE',
  'ENGLISH LONGHORN ',
  'F1',
  'FLORIDA CRACKER',
  'GALLOWAY CATTLE',
  'GELBVIEH',
  'GUZERAT',
  'GYRBELIARDE ',
  'GYROLANDO (GYR)',
  'HEREFORD',
  'HOLANDO CEBU',
  'HOLSTEIN ',
  'INDUBRASIL ',
  'JAPONESA NEGRA ',
  'JERSEY ',
  'LIMOUSIN ',
  'MAINE-ANJOU',
  'MARCHIGIANA',
  'MONTBÉLIARDE',
  'NELORE',
  'NELORE MOCHO ',
  'NORMANDO ',
  'PARDO SUIZO',
  'PARTHENAISE',
  'PODÓLICA ',
  'RATH ',
  'RED POLL',
  'ROMAGNOLA ',
  'SANTA GERTRUDIS ',
  'SARDO NEGRO ',
  'SENEPOL',
  'SHORTHORN ',
];

// 611cd36afc13ae43430001b4
// 611cd36afc13ae43430001b5
// 611cd36afc13ae43430001b6
// 611cd36afc13ae43430001b7
// 611cd36afc13ae43430001b8
// 611cd36afc13ae43430001b9
// 611cd36afc13ae43430001ba
// 611cd36afc13ae43430001bb
// 611cd36afc13ae43430001bc
// 611cd36afc13ae43430001bd
// 611cd36afc13ae43430001be
// 611cd36afc13ae43430001bf
// 611cd36afc13ae43430001c0
// 611cd36afc13ae43430001c1
// 611cd36afc13ae43430001c2
// 611cd36afc13ae43430001c3
// 611cd36afc13ae43430001c4
// 611cd36afc13ae43430001c5
// 611cd36afc13ae43430001c6
// 611cd36afc13ae43430001c7
// 611cd36afc13ae43430001c8
// 611cd36afc13ae43430001c9
// 611cd36afc13ae43430001ca
// 611cd36afc13ae43430001cb
// 611cd36afc13ae43430001cc
// 611cd36afc13ae43430001cd
// 611cd36afc13ae43430001ce

export const sexoOptions = [
  { label: 'Hembra', value: 'H' },
  { label: 'Macho', value: 'M' },
];
export const breedTypeOptions = [
  { label: 'Comercial', value: 'comercial' },
  { label: 'Registro', value: 'registro' },
];
export const statusOptions = [
  {
    value: 'cargada',
    label: 'Cargada',
  },
  {
    value: 'cria',
    label: 'Cría',
  },
  {
    value: 'vacia',
    label: 'Vacía',
  },
  {
    value: 'desecho',
    label: 'Desecho',
  },
  {
    value: 'semental',
    label: 'Semental',
  },
  {
    value: 'vendido',
    label: 'Vendido',
  },
  {
    value: 'muerto',
    label: 'Muerto',
  },
];

export const statusOptionsReduced = [
  {
    value: 'cargada',
    label: 'Cargada',
  },
  {
    value: 'vacia',
    label: 'Vacía',
  },
];

export const birhtStatusOptions = [
  {
    value: 'exitoso',
    label: 'Exitoso',
  },
  {
    value: 'aborto',
    label: 'Aborto',
  },
];

export const ROLES_TITLES = ['PERMISOS', 'NOMBRE', 'DESCRIPCIÓN', 'ACTIVAR', 'EDITAR'];

export const USER_TITLES = [
  'NOMBRE',
  'EMAIL',
  'ACTIVAR',
  /*  "ROL", */
  'TIPO DE PERMISO',
];
export const ADMIN_TITLE = ['NOMBRE', 'USERNAME', '# DE RANCHOS', 'ACTIVAR'];
export const BREED_TITTLES = ['RAZA', 'DESCRIPCIÓN', 'ACTIVAR', 'EDITAR'];

export const VACCINE_TITLES = ['ACTIONS', 'NOMBRE', 'VIA', 'DOSIS', 'Kg x peso'];
export const GROUP_TITLE = ['ACCIONES', 'GRUPO', 'PERIODO'];
export const PALPACION_HISTORIAL_TITLES = [
  //"ACTIONS",
  'CONFIRMACION',
  'ESTATUS',
  'FECHA DE GESTACION',
  'REPRODUCCION',
  'PALPADA',
  'OBSERVACIONES',
  'SEMEN',
  'SINIIGAM',
];
export const LISTA_PARTOS_TITLES = [
  'ACTIONS',
  'FECHA DE PARTO',
  'FECHA DE GESTACIÓN',
  'ESTATUS DE PARTO',
  'MÉTODO DE REPRODUCCIÓN',
  'CONFIRMACIÓN',
  'PALPADA',
  'SEMEN DE TORO',
  'SINIIGAM',
  'OBSERVACIÓN',
  'DURACIÓN DE LA GESTACIÓN',
];
export const GESTACION_TITLES = [
  'SINIIGA',
  'PRIV',
  'ESTATUS',
  'PARTOS',
  'DIAS SIN PARIR',
  'RAZA',
  'TIPO',
  'FECHA DE NACIMIENTO',
  'RANCHO',
  'ACTIONS',
];
export const titleTableRanchos = [
  'RANCHO',
  'GANADERO',
  'ESTATUS',
  'FECHA DE NACIMIENTO',
  'CICLO',
  'SINIIGA',
  '#PRIV',
  'RAZA',
];
export const TITULO_RANCHO_DETAILS = [
  'SINIIGA',
  '#PRIV',
  'FECHA DE NACIMIENTO',
  'CICLO',
  'RAZA',
  'ESTATUS',
  'GANADERO',
  'RANCHO',
  'TIPO DE GANADO',
  'ACCIONES',
];
export const TITULO_GANADO_PALPACIONES_DETAILS = [
  'SINIIGA',
  '#PRIV',
  'FECHA DE NACIMIENTO',
  'CICLO',
  'RAZA',
  'ESTATUS',
  'DÍAS SIN PARIR',
  'GANADERO',
  'RANCHO',
  'TIPO DE GANADO',
  'ACCIONES',
];

export const TITULO_LISTADO_DE_CRIAS = [
  'FECHA DE NACIMIENTO',
  'CANTIDAD DE HEMBRAS',
  'CANTIDAD DE MACHOS',
];

export const LIVESCTOCK_VACCINE = [
  'checkbox',
  'SINIIGA',
  '#PRIV',
  'FECHA DE NACIMIENTO',
  'RAZA',
  'SEXO',
  'ESTATUS',
  'RANCHO',
  'GANADERO',
];

export const deleteRanchMsg = 'Seguro que quieres deshabilitar a:';
export const EnableRanchMsg = 'Seguro que quieres habilitar a:';
export const falseButton = 'Cancelar';
export const disableButton = 'Sí, quiero deshabilitar';
export const enableButton = 'Sí, quiero habilitar';
export const notEnable = 'No, quiero habilitar';
export const notDisable = 'No, quiero deshabilitar';
