import React from "react";
import { Link } from "react-router-dom";
import { logout, settings } from "../../constants";
import "./styles.css";
const FloatMenu = ({ openCloseFunction, isOwner }) => {
  return (
    <div className="floatMenu" onMouseLeave={() => openCloseFunction()}>
      {isOwner && (
        <Link to="/Configuracion">
          <img src={settings} alt="Configuración" />
          Configuración
        </Link>
      )}

      <Link to="/">
        <img src={logout} alt="Cerrar sessión" />

        <span onClick={() => sessionStorage.clear()}>Cerrar sesión</span>
      </Link>
    </div>
  );
};

export default FloatMenu;
