import React, { useContext } from 'react';
import Ranchprovider from './../Context/RanchosStore/context';
import RanchosComponent from './../Components/Ranchos/index'
import Hato from '../layout/Hato_Theme';
import { MainContext } from '../MainContext';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import PieProvider, { PieContext } from '../Context/PieCria/Context'


const Ranchos = ({ children, match }) => {
  const mainCtx = useContext(MainContext);
  return (
    <Hato>
      <Ranchprovider children={children}>
          <RanchosComponent user={mainCtx.user} match={match} />
      </Ranchprovider>
    </Hato>
  )
}

export default Ranchos
