import React from 'react';
import Ganaderosprovider from './../Context/ProveedoresStore/context';
import ProveedoresComponent from './../Components/Proveedores/'
import Hato from '../layout/Hato_Theme';


const Ranchos = ({children}) => {
  return (
    <>
    <Hato>
      <Ganaderosprovider children={children}>
        <ProveedoresComponent/>
      </Ganaderosprovider>
    </Hato>

    </>
  )
}

export default Ranchos
