import React from 'react';
import PalpacionProvider from '../Context/PalpacionesStore/context'
import PalpacionComponent from './../Components/Main/Palpaciones'
/* import PalpacionProvider from '../Context/PalpacionStore/context'
import PalpacionComponent from './../Components/Main/Palpacion/' */
import Hato from '../layout/Hato_Theme';


const Palpacion = ({children,match}) => {
  return (
    <>
    <Hato>
      <PalpacionProvider children={children}>
        <PalpacionComponent match={match}/>
      </PalpacionProvider>
    </Hato>
    </>
  )
}

export default Palpacion
