import React, { useContext } from "react";
import Switch from "../../Switch/Switch";
import { USER_TITLES } from "../../../constants";
import { ConfiguracionContext } from "../../../../Context/ConfiguracionStore/context";

import "./styles.css";
import Pagination from "@mui/material/Pagination";

const Tabla = () => {
  const configCtx = useContext(ConfiguracionContext);
  const {
    enableUser,
    UsusariosArr,
    setUserPage,
    userPage,
    usersInfo,
    getMoreUsers,
  } = configCtx;
  const renderTitle = USER_TITLES.map((el, i) => (
    <th key={`title-${i}`} className="eachCeld">
      {el}
    </th>
  ));
  const handleChangePage = (_, newPage) => {
    //setLoading(true);
    setUserPage(newPage);
    getMoreUsers(newPage);
  };

  const renderColumns = (item, rowKey) => {
    return Object.keys(item).map((res, key) => {
      if (res === "_id" || res === "account" || res==='role' || res==='owner') return null;
      if (res === "active")
        return (
          <th className="thValues">
            <div className="center-switch">
              <Switch
                isActive={item[res]}
                id={item._id}
                onChange={enableUser}
              />
            </div>
          </th>
        );
      if (res === "permission")
        return (
          <th key={`${rowKey}-${res}-${key}`} className="thValues">
            {item[res].name}
          </th>
        );
      return (
        <th key={`${rowKey}-${res}-${key}`} className="thValues">
          {item[res] ? item[res] : "-"}
        </th>
      );
    });
  };
  console.log(UsusariosArr);
  const renderRows = UsusariosArr.map((res, key) => {
    return (
      <tr className="eachRow" key={`row_${key}`}>
        {renderColumns(res, key)}
      </tr>
    );
  });

  return (
    <div className="MainTablasContainer">
      <table className="tableElement">
        <tbody className="tableTbody">
          <tr className="tableTitles">{renderTitle}</tr>
          {renderRows}
        </tbody>
      </table>
      <div style={{marginTop:'20px'}} className="ganaderos-pagination">
        <Pagination
          count={usersInfo?.totalPages}
          page={userPage}
          onChange={handleChangePage}
          shape="rounded"
        />
        <label style={{ marginTop: "7px" }}>
          Usuarios en total : {usersInfo?.totalUsers}
        </label>
      </div>
    </div>
  );
};

export default Tabla;
