export default{

  Grupo:{
    name:'',
    period:'',
    recurrence:false
  },
  Vacuna:{
    name:'',
    via:'',
    dose:'',
    kg:'',
    group:''
  },
  Aplican:{
    Raza:'',
    Sexo:'',
    Ciclo:'',
    Periodo:''
  },
  Rango_de_Nacimoento:'',
  Raza:'',
  Periodo:'',
}




