import React, { useContext, useEffect, useState } from "react";
import { VACCINE_TITLES } from "../../../constants";
import { VacunasListContext } from "../../../../Context/VaccineListStore/context";
import "./styles.css";
import Pagination from "@mui/material/Pagination";
import {
  getVaccinesBygroupId,
  deleteVaccineService,
} from "../../../../servicces";
import {  toast } from "react-toastify";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
const Tabla = () => {
  const success = (text) => toast.success(text, { autoClose: 3000 });
  const Error = (text) => toast.error(text, { autoClose: 3000 });
  const [vaccines, setvaccines] = useState([]);
  const vacunasCtx = useContext(VacunasListContext);
  const { groupId, closeActiveModalGroup } = vacunasCtx;
  const renderTitle = VACCINE_TITLES.map((el, i) => (
    <th key={`title-${i}`} className="eachCeld">
      {el}
    </th>
  ));
  useEffect(() => {
    const getVaccines = async (id) => {
      try {
        const result = await getVaccinesBygroupId(id);
        setvaccines(result.data.vaccines);
      } catch (error) {
        console.log("error get vaccines by id", error);
      }
    };
    if (groupId !== "") {
      getVaccines(groupId);
    }
  }, [groupId]);

  const deleteVaccine = async (id, name) => {
    try {
      await deleteVaccineService(id);
      success(`La vacuna "${name}", ha sido borrada correctamente`);
      closeActiveModalGroup()
    } catch (error) {
      console.log(error.response);
      Error(error.response.data.msg);
    }
  };

  const renderColumns = (item, rowKey) => {
    return Object.keys(item).map((res, key) => {
      if (res === "account" || res === "group") return null;
      if (res === "_id")
        return (
          <th
            key={`${rowKey}-${res}-${key}`}
            onClick={(_) => deleteVaccine(item[res], item.name)}
            className="thValues"
          >
            <FontAwesomeIcon icon={faTrash} />
          </th>
        );

      return (
        <th key={`${rowKey}-${res}-${key}`} className="thValues">
          {item[res] ? item[res] : "-"}
        </th>
      );
    });
  };
  const renderRows = vaccines.map((res, key) => {
    return (
      <tr className="eachRow" key={`row_${key}`}>
        {renderColumns(res, key)}
      </tr>
    );
  });

  return (
    <div className="vacunas-container">
      <table className="vacunas-element">
        <tbody className="tableTbody">
          <tr className="vacunas-tableTitles">{renderTitle}</tr>
          {renderRows}
        </tbody>
      </table>
      {/* <div style={{marginTop:'20px'}} className="ganaderos-pagination">
        <Pagination
          count={usersInfo?.totalPages}
          page={userPage}
          onChange={handleChangePage}
          shape="rounded"
        />
      </div> */}
      
    </div>
  );
};

export default Tabla;
