import React, { useEffect, useState, useContext } from 'react';
import './styles.css';
import { getBreeds, getAllGanaderos, getSelectActiveRanchs } from '../../../servicces';
import translateDate from '../../../utils/translateDate';
import { GestacionContext } from '../../../Context/GestacionStore/context';
import { useLocation } from 'react-router-dom';
import { sexoOptions, statusOptions, breedTypeOptions } from '../../constants';

const NewGanado = ({ form, handleCowFormInputs, heigth, submitErrors, livestockMotherInfo }) => {
  const gestacionCtx = useContext(GestacionContext);
  const { Cabeza, setCabeza, birthInfoModal, submitNewCalves } = gestacionCtx;
  // const { pathname } = useLocation();
  // const splitPathname = pathname.split('/');
  // const ranchID = splitPathname[2];
  // const [selectors, setselectors] = useState(null);
  // const [loading, setloading] = useState(true);

  useEffect(() => {
    if (livestockMotherInfo)
      setCabeza((prev) => {
        return {
          ...prev,
          siniigaH: livestockMotherInfo.siniiga,
          rancherH: livestockMotherInfo.rancher,
          ranchH: livestockMotherInfo.ranch,
          pedigreeH: livestockMotherInfo.type,
          siniigaM: birthInfoModal.siniigaM || '',
          date: birthInfoModal.dueDate,
        };
      });
  }, [livestockMotherInfo]);

  // const getSelectors = async () => {
  //   try {
  //     const ranchsResult = await getSelectActiveRanchs({
  //       page: 1,
  //       limit: 100,
  //       search_text: '',
  //     });
  //     const ranchersResult = await getAllGanaderos();
  //     const breedResult = await getBreeds();
  //     const configSelectors = {
  //       ranch: ranchsResult.data.data.ranchs,
  //       breed: breedResult.data.data.breeds,
  //       rancher: ranchersResult.data.data.ranchers,
  //       sex: sexoOptions.map((res) => {
  //         return { name: res.label, _id: res.value };
  //       }),
  //       status: statusOptions.map((res) => {
  //         return { name: res.label, _id: res.value };
  //       }),
  //       type: breedTypeOptions.map((res) => {
  //         return { name: res.label, _id: res.value };
  //       }),
  //     };
  //     setselectors(configSelectors);
  //     setloading(false);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  // useEffect(() => {
  //   getSelectors();
  // }, []);

  // if (loading && !selectors) return <div className={form.mainClass}>...loading</div>;
  return (
    <div className={form.mainClass}>
      <h1 className="titleGrupo">Agregar nueva cría</h1>

      <div className="TabsGanaderos" style={{ height: heigth }}>
        {/* {Array.from(Array(form.parts)).map((num, i) => {
          return (
            <div
              key={`tab${i + 1}`}
              className={`Tab tab${i + 1}`}
              title={`tab${i}`}
              style={{ opacity: `${i > 0 ? '0' : '1'}` }}
            >
              {form[`part${i + 1}`].map((el, i) => ( */}
        {form.part1.map((el, i) => (
          <div className="eachInputForm" key={`eachInput-${i}`}>
            <div className="contentLabel">
              <img className="iconInput" src={el.icon} alt={el.name} />
              <label className="labelInput">{el.title}</label>
            </div>
            {el.type === 'number' ? (
              <input
                className="inputText ganado"
                type={el.type}
                name={el.name}
                onChange={(e) => handleCowFormInputs(e.target)}
              />
            ) : (
              // el.type === 'date' ?
              translateDate(Cabeza.date)
              // : (
              //   <select
              //     className="create-cow-selector ganado"
              //     name={el.name}
              //     onChange={(e) => handleCowFormInputs(e.target)}
              //     defaultValue={el.title === 'Ranchos' ? ranchID : ''}
              //   >
              //     <option value="">{el.label}</option>
              //     {selectors[el.name]?.map((opt, k) => (
              //       <option key={`${el.label}-${k}`} value={`${opt._id}`}>
              //         {opt.description != null ? `${opt.name} -  ${opt.description}` : `${opt.name}`}
              //       </option>
              //     ))}
              //   </select>
            )}
          </div>
        ))}
      </div>
      {/*);
        })}
      </div> */}
      {submitErrors !== '' && <label style={{ color: 'red' }}>{submitErrors}</label>}
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          marginTop: '10px',
        }}
      >
        <button
          style={{ margin: '0' }}
          type="button"
          onClick={submitNewCalves}
          className={`newRanchButtonAccept`}
        >
          Generar nueva cría
        </button>
      </div>
    </div>
  );
};

export default NewGanado;
