import React, { useContext } from "react";
import Switch from "../../Switch/Switch";
import { BREED_TITTLES } from "../../../constants";
import { ConfiguracionContext } from "../../../../Context/ConfiguracionStore/context";
import { edit } from "../../../constants";

import "./styles.css";
import Pagination from "@mui/material/Pagination";

const Tabla = () => {
  const configCtx = useContext(ConfiguracionContext);
  const {
    enableBreed,
    RazasArr,
    setbreedsPage,
    getMoreBreeds,
    breedsInfo,
    breedsPage,
    setIsEditing,
    setitemEditing,
    itemEditing
  } = configCtx;
  const renderTitle = BREED_TITTLES.map((el, i) => (
    <th key={`title-${i}`} className="eachCeld">
      {el}
    </th>
  ));
  const handleChangePage = (_, newPage) => {
    //setLoading(true);
    setbreedsPage(newPage);
    getMoreBreeds({ page: newPage, limit: 10 });
  };

  const editingRaza = (name, description, id) => {
    setIsEditing(true);
    console.log(name)
    setitemEditing({
      id,
      name,
      description
    })
  }

  const renderColumns = (item, rowKey) => {
    item.edit = true;
    return Object.keys(item).map((res, key) => {

      if (res === "account") return null;
      if (res === "_id" || res === "account") return null;

      if (res === "edit") {
        return (
          <th className="thValues-rol">
            <img
              className="editable_Btn"
              src={edit}
              alt="edit"
              id={res._id}
              onClick={_ => 
                {
                editingRaza(item.name, item.description,item._id);
              }}
            />
          </th>
        );
      }

      if (res === "active") {
        return (
          <th className="thValues">
            <div className="center-switch">
              <Switch
                isActive={item[res]}
                id={item._id}
                onChange={enableBreed}
              />
            </div>
          </th>
        );
      }

      if (res === "permission") {
        return (
          <th key={`${rowKey}-${res}-${key}`} className="thValues">
            {item[res].name}
          </th>
        );
      }

      return (
        <th key={`${rowKey}-${res}-${key}`} className="thValues">
          {item[res] ? item[res] : "-"}
        </th>
      );
    });
  };
  const renderRows = RazasArr.map((res, key) => {
    return (
      <tr className="eachRow" key={`row_${key}`}>
        {renderColumns(res, key)}
      </tr>
    );
  });

  return (
    <div className="MainTablasContainer">
      <table className="tableElement">
        <tbody className="tableTbody">
          <tr className="tableTitles">{renderTitle}</tr>
          {renderRows}
        </tbody>
      </table>
      <div style={{ marginTop: "20px" }} className="ganaderos-pagination">
        <Pagination
          count={breedsInfo?.totalPages}
          page={breedsPage}
          onChange={handleChangePage}
          shape="rounded"
        />
        {/* {console.log(breedsInfo)} */}
        <label style={{ marginTop: "7px" }}>
          Razas en total : {breedsInfo?.totalBreeds}
        </label>
      </div>
    </div>
  );
};

export default Tabla;
