import React, { useContext, useEffect, useState } from "react";
import { PalpacionesContext } from "../../../Context/PalpacionesStore/context";
import { add } from "../../constants";
import Modal from "../../ModalContainer";
import Tabla from "./HistoricalTable";
import CircularProgress from "@mui/material/CircularProgress";
import PalpacionForm from "../../Forms/PalpacionForm";
import "./styles.css";
import Pagination from "@mui/material/Pagination";
import { useLocation } from "react-router-dom";
import DownloadFileButton from "../../../Components/DownloadFileButton/DownloadFileButton";
const PalpacionesHistorial = ({ item, match }) => {
  const { pathname } = useLocation();
  const splitPathname = pathname.split("/");
  const cowID = splitPathname[2];
  const PalpacionesCxt = useContext(PalpacionesContext);
  const {
    newPalpationModal,
    setNewPalpationModal,
    historialInfo,
    listGanado,
    loading,
    setLoading,
    paginationInfo,
    page,
    setPage,
    historial,
    getHistorial,
    submitNewHistorical,
    urlExcel,
    getDownloadUrl,
  } = PalpacionesCxt;


  useEffect(() => {
    getDownloadUrl();
  }, []);

  const getId = (e) => {};
  const toggleModalVisibitily = () => {
    setNewPalpationModal((prev) => !prev);
  };

  const handleChangePage = (_, newPage) => {
    setLoading(true);
    setPage(newPage);
    const config = {
      page: newPage,
      limit: 10,
      livestock: cowID,
    };
    getHistorial(config);
  };

  return (
    <div className="MainDetailsComponentContainer">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: "0 30px",
        }}
      >
        <h1 className="misRanchos">Historial de palpaciones</h1>
        <DownloadFileButton id={cowID} excelType="palpaciones">
          Descargar historico
        </DownloadFileButton>
      </div>
      {loading ? (
        <div className="loading">
          <CircularProgress color="warning" size={100} />
        </div>
      ) : (
        <>
          {listGanado.length > 0 && (
            <>
              <Tabla
                getIdGanadero={getId}
                tablesInfo={historial}
                setActiveRanch={() => {}}
                redirect={true}
                match={match}
              />
            </>
          )}
          {/*  <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={listGanado?.totalLivestocks}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          /> */}
        </>
      )}
      <div className="ganaderos-pagination">
        <Pagination
          count={historialInfo?.totalPages}
          page={page}
          onChange={handleChangePage}
          shape="rounded"
        />
        <label style={{ marginTop: "7px" }}>
          Palpaciones en total : {historialInfo?.totalPalpations}
        </label>
      </div>
      <div className="addGanadoContainerDetails">
        <button
          type="button"
          className="ActiveFormDetails"
          onClick={toggleModalVisibitily}
        >
          <img src={add} alt="add" />
        </button>
      </div>

      {newPalpationModal && (
        <Modal
          ActiveForm={newPalpationModal}
          openCloseModal={toggleModalVisibitily}
          submitNewItem={submitNewHistorical}
        >
          <PalpacionForm />
        </Modal>
      )}

      {/*      {isOpenModalDetails && dataFromID && (
        <ModalDetails
          closeFunction={() => {
            openModalDetails(false);
          }}
          data={dataFromID}
          item={item}
          getInitialData={getInitialData}
          getId={(id) => {
            getId(id);
          }}
          info={info}
          configGetPages={configGetPages}
        />
      )} */}
    </div>
  );
};

export default PalpacionesHistorial;
