import { Redirect, Route } from 'react-router-dom'

export const PublicRoute = ({ component: Component, ...rest }) => (
    <Route
        {...rest}
        render={props =>
            !sessionStorage.getItem('hatoToken') ? (
                <Component {...props} />
            ) : (
                <Redirect
                    to={"/Rancho"} />
            )
        }

    />
)
