import React, { useContext } from "react";
import { GROUP_TITLE, notes } from "../../../constants";
import { VacunasListContext } from "../../../../Context/VaccineListStore/context";
import "./styles.css";
import Pagination from "@mui/material/Pagination";
import Switch from "./Switch/Switch";
const Tabla = () => {
  const vacunasCtx = useContext(VacunasListContext);
  const {
    gruposArr,
    setvaccineModal,
    setModalGrupoActive,
    setNewVacunas,
    setGroupId,
    gruposPage,
    setLoading,
    setgruposPage,
    gruposInfoPage,
    getInitialGroupData,
    enableVaccineGroup
  } = vacunasCtx;
  const renderTitle = GROUP_TITLE.map((el, i) => (
    <th key={`title-${i}`} className="eachCeld">
      {el}
    </th>
  ));

  const openCreateVaccineModal = (id) => {
    setvaccineModal(true);
    setNewVacunas((prev) => {
      return { ...prev, group: id };
    });
  };

  const handleChangePage = (_, newPage) => {
    setLoading(true);
    setgruposPage(newPage);
    const config = {
      page: newPage,
      limit: 10,
    };
    getInitialGroupData(config);
  };

  const renderColumns = (item, rowKey) => {
    return Object.keys(item).map((res, key) => {
      if (res === "account" || res === "active" || res === "recurrence" ) return null;
      if (res === "_id")
        return (
          <th className="thValuesgroupd buttons">
            <div className="buttonsModal">
              <button
                type="button"
                id={item[res]}
                onClick={(_) => openCreateVaccineModal(item[res])}
              >
                +
              </button>
            </div>
            <div className="buttonsModal">
              <button
                type="button"
                id={item[res]}
                onClick={(e) => {
                  setGroupId(item[res]);
                  setModalGrupoActive(true);
                }}
              >
                <img src={notes} alt="vaccines"></img>
              </button>
            </div>
            <div className="buttonsModal">
              <Switch
                isActive={item.active}
                id={item[res]}
                onChange={enableVaccineGroup}
              />
            </div>
          </th>
        );

      return (
        <th key={`${rowKey}-${res}-${key}`} className="thValuesgroupd ">
          {item[res] ? item[res] : "-"}
        </th>
      );
    });
  };
  const renderRows = gruposArr.map((res, key) => {
    return (
      <tr className="eachRow" key={`row_${key}`}>
        {renderColumns(res, key)}
      </tr>
    );
  });

  return (
    <div className="MainTablasContainer">
      <table className="tableElement">
        <tbody className="tableTbody">
          <tr className="tableTitles">{renderTitle}</tr>
          {renderRows}
        </tbody>
      </table>
      <div style={{ marginTop: "20px" }} className="ganaderos-pagination">
        <Pagination
          count={gruposInfoPage?.totalPages}
          page={gruposPage}
          onChange={handleChangePage}
          shape="rounded"
        />
        <label style={{ marginTop: "7px" }}>
          Grupos en total : {gruposInfoPage?.totalGroups}
        </label>
      </div>
    </div>
  );
};

export default Tabla;
