import React, { memo, useState } from "react";
import "./DownloadFileButton.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { getGanadoExcel, getPalpacionesExcel, getPartosExcel } from "../../servicces";
import { toast } from "react-toastify";
import CircularProgress from "@mui/material/CircularProgress";

const DownloadFileButton = memo(
  ({ children, excelType, id, search_text, sex, breed, status, cycle }) => {
    const Error = (text) => toast.error(text, { autoClose: 3000 });

    const [loading, setLoading] = useState(false);
    const getExcel = async () => {
      setLoading(true);
      let apiCall = getPartosExcel;
      if (excelType === "ganado") apiCall = getGanadoExcel;
      if (excelType === "palpaciones") apiCall = getPalpacionesExcel;
      try {
        let result = await apiCall({
          id,
          search_text,
          sex,
          breed,
          status,
          cycle,
        });
        const url = result.data.filename;
        window.open(url, "_blank").focus();
        setLoading(false);
      } catch (error) {
        Error(`No se pudo descargar el archivo de excel`);
        console.log("excel error", error);
        setLoading(false);
      }
    };

    return (
      <button disabled={loading} className="download-button" onClick={getExcel}>
        {loading ? (
          <CircularProgress color="inherit" size={18} />
        ) : (
          <FontAwesomeIcon icon={faDownload} />
        )}
        <span style={{ marginLeft: "10px" }}>{children}</span>
      </button>
    );
  }
);

export default DownloadFileButton;
