import React, { useContext } from 'react';
import { PieContext } from '../../../../Context/PieCria/Context';
import Switch from '../../../Auxiliars/switch';
import { edit, TITULO_GANADO_PALPACIONES_DETAILS } from '../../../constants';
import './styles.css';
import translateDate from '../../../../utils/translateDate';

const Tabla = ({ tablesInfo, setActiveRanch, getIdGanadero, redirect, match, pie }) => {
  const { inputFilterSiniiga } = useContext(PieContext);
  let originTable = [...tablesInfo];

  if (pie) {
    originTable = tablesInfo.filter((item) => item.Siniiga.includes(inputFilterSiniiga));
  }

  if (originTable.length === 0)
    return <div className="MainTablasContainer">"No se encontraron resultados"</div>;

  const renderPieRanchTitles = TITULO_GANADO_PALPACIONES_DETAILS.map((el, i) => (
    <th key={`titlesTableKey${i}`} className="eachCeld">
      {el}
    </th>
  ));

  return (
    <div className="MainTablasContainer">
      <table className="tableElement">
        <tbody className="tableTbody">
          <tr className="tableTitles">{renderPieRanchTitles}</tr>
          {originTable.map((el, i) => (
            <tr className="eachRow" key={`row_${i}`}>
              {el.siniiga || el.Siniiga ? (
                <th className="thValues">{el.siniiga ?? el.Siniiga} </th>
              ) : (
                <th className="thValues">-</th>
              )}
              {el.priv && <th className="thValues">{el.priv} </th>}
              {(el.date || el.Fecha) && (
                <th className="thValues">{translateDate(el.date ?? el.Fecha)}</th>
              )}
              {el.cycle && (
                <th className="thValues" style={{ textTransform: 'capitalize' }}>
                  {el.cycle}
                </th>
              )}
              {(el.Raza || el.breed) && (
                <th className="thValues">
                  {el.Raza ?? el.breed?.name} - {el.breed?.description}
                </th>
              )}
              {el.status || el.Status ? (
                <th style={{ textTransform: 'capitalize' }} className="thValues">
                  {el.status ?? el.Status}{' '}
                </th>
              ) : (
                <th className="thValues">-</th>
              )}
              {el.days_without_labor ? (
                <th className="thValues">{el.days_without_labor}</th>
              ) : (
                <th className="thValues">-</th>
              )}
              {(el.rancher || el.Ganadero) && (
                <th className="thValues">{el.rancher?.name ?? el.Ganadero}</th>
              )}
              {(el.ranch || el.Rancho) && (
                <th className="thValues">{el.ranch?.name ?? el.Rancho}</th>
              )}
              {(el.type || el.type) && <th className="thValues">{el.type}</th>}
              {redirect ? (
                <th className="thValues">
                  <div className="linkRedirect">
                    <button
                      type="button"
                      id={el._id}
                      onClick={(e) => {
                        getIdGanadero(e.target.id);
                      }}
                    >
                      {'>'}
                    </button>
                  </div>
                </th>
              ) : (
                <>
                  <th className="thValues">
                    {match.url.includes('Configuracion') ? null : (
                      <img
                        className="editable_Btn"
                        src={edit}
                        alt="edit"
                        id={el?._id}
                        onClick={(e) => {
                          getIdGanadero(e.target.id);
                        }}
                      />
                    )}
                    <Switch
                      id={el.id}
                      identificador={`${el?.SobreNombre}-${el?._id}`}
                      setActiveIDSwitch={setActiveRanch}
                    />
                  </th>
                </>
              )}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Tabla;
