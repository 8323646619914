import React from 'react';
import GestacionProvider from '../Context/GestacionStore/context'
import ListadoDePartos from '../Components/Main/ListadoDePartos'
import Hato from '../layout/Hato_Theme';

const Partos = ({children,match}) => {
  return (
    <Hato>
      <GestacionProvider children={children}>
        <ListadoDePartos match={match}/>
      </GestacionProvider>
    </Hato>
  )
}

export default Partos
