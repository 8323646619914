import React, { useContext, useState } from "react";
import SearchBox from "./SearchBar/SearchBar";
import {
  add,
  deleteRanchMsg,
  EnableRanchMsg,
  disableButton,
  enableButton,
  notEnable,
  notDisable,
} from "./../constants";
import Card from "./Card";
import Modal from "../ModalContainer";
import NewRanch from "./../Forms/NewRanch";
import { RanchContext } from "../../Context/RanchosStore/context";
import "./RanchosComponent.css";
import MainModal from "../MainModal";
import ConfirmDelete from "./Warnings/ConfirmDelete";
import CircularProgress from "@mui/material/CircularProgress";
import Pagination from "@mui/material/Pagination";

const RanchosComponent = ({ user }) => {
  const ranchContext = useContext(RanchContext);
  const {
    sendNewRanch,
    newRanch,
    isOpenModal,
    setIsOpenModal,
    cards,
    getInfoRanch,
    isEditableForm,
    setIsEditableForm,
    updateRanchCtx,
    setMapNull,
    dissableFunction,
    isActiveWarning,
    setisActiveWarning,
    findCard,
    infoRanchs,
    getInitialData,
    loading,
    setLoading,
    page,
    setPage,
    searchBarInput,
    msgError,
    setnewRanch,
    
  } = ranchContext;

  const [itmeToDelete, setItmeToDelete] = useState({});

  const getShowDataRanch = (id) => {
    //set data into the inputs
    getInfoRanch(id.split("_")[0]);
    openCloseModalFunction();
    setIsEditableForm(!isEditableForm);
  };

  const activeDeactiveRanchFunction = (e) => {
    if (!isActiveWarning) {
      setisActiveWarning(true);
    } else {
      setisActiveWarning(false);
    }

    setItmeToDelete(cards.find((el) => el._id === e));
  };

  const submitNewRanch = async () => {
    sendNewRanch(user.id);
  };

  const openCloseModalFunction = () => {
    if (isOpenModal) {
      setMapNull();
      setnewRanch({
        inCharge: '',
        name: '',
        purpose: '',
        dimension: '',
        country: 'mexico',
        city: '',
        coordinates: {
          x: -89.62,
          y: 20.96
        },
        state: ''
      })
    }
    setIsOpenModal(!isOpenModal);
  };

  const updateRanchComponent = (id) => {
    updateRanchCtx(newRanch, id);
    openCloseModalFunction();
    setIsEditableForm(false);
  };

  const handleChangePage = (event, newPage) => {
    setLoading(true);
    const config = {
      page: newPage,
      limit: 12,
      search_text: searchBarInput,
    };
    setPage(newPage);
    getInitialData(config);
  };

  return (
    <>
      <div className="ranchComponentContainer">
        <div
          style={{
            padding: "0 30px",
          }}
        >
          <h1 style={{ paddingLeft: "0" }} className="misRanchos">
            Mis Ranchos
          </h1>
          <SearchBox
            findRanch={(data) => {
              findCard(data.target.value);
            }}
          />
        </div>

        <div className="cardContainerRanches">
          {loading ? (
            <div className="loading">
              <CircularProgress color="warning" size={100} />
            </div>
          ) : (
            cards.map((el, i) => (
              <Card
                key={`${el._id}-${i}`}
                ranch={el}
                title={el.name}
                isActive={el.active}
                user={user}
                prefijo={"Hato"}
                identificador={""}
                _id={el._id}
                getIdentificador={getShowDataRanch}
                setActiveRanch={activeDeactiveRanchFunction}
                setItmeToDelete={setItmeToDelete}
                setPage={setPage}
              />
            ))
          )}
        </div>
        <div className="ganaderos-pagination">
          <Pagination
            count={infoRanchs?.totalPages}
            page={page}
            onChange={handleChangePage}
            shape="rounded"
          />
          <label style={{ marginTop: "7px" }}>
            Ranchos en total : {infoRanchs?.totalRanchs}
          </label>
        </div>
      </div>

      <div className="addRanchContainer">
        <button
          type="button"
          className="ActiveFormRanchosSection"
          onClick={() => openCloseModalFunction()}
        >
          <img src={add} alt="add" />
        </button>
      </div>

      {isOpenModal && (
        <Modal
          ActiveForm={isOpenModal}
          openCloseModal={() => {
            setIsEditableForm(false);
            openCloseModalFunction();
          }}
          submitNewItem={(id) =>
            isEditableForm ? updateRanchComponent(id) : submitNewRanch()
          }
          ID={isEditableForm ? newRanch._id : ""}
        >
          <NewRanch values={newRanch} />
          {msgError && (
            <label style={{ margin: "10px", color: "red" }}>{msgError}</label>
          )}
        </Modal>
      )}
      {isActiveWarning && (
        <MainModal>
          <ConfirmDelete
            mainWarning={
              itmeToDelete.sActive ? deleteRanchMsg : EnableRanchMsg
            }
            buttonAccept={itmeToDelete.sActive ? disableButton : enableButton}
            buttonReject={itmeToDelete.sActive ? notDisable : notEnable}
            actionFunction={() => {
              setisActiveWarning(!isActiveWarning);
            }}
            itmeToDelete={itmeToDelete}
            user={user}
            deleteItem={() => {
              dissableFunction(itmeToDelete._id);
            }}
          ></ConfirmDelete>
        </MainModal>
      )}
    </>
  );
};
export default RanchosComponent;
