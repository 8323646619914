import React from 'react';
import PalpacionProvider from '../Context/PalpacionesStore/context'
import PalpacionesH from '../Components/Main/PalpacionesHistorial'
/* import PalpacionProvider from '../Context/PalpacionStore/context'
import PalpacionComponent from './../Components/Main/Palpacion/' */
import Hato from '../layout/Hato_Theme';

const PalpacionesHistorial = ({children,match}) => {
  return (
    <Hato>
      <PalpacionProvider children={children}>
        <PalpacionesH match={match}/>
      </PalpacionProvider>
    </Hato>
  )
}

export default PalpacionesHistorial
