import React, { memo, useContext } from "react";
import { ConfiguracionContext } from "../../../Context/ConfiguracionStore/context";
import AdminTables from "./AdminTables/AdminTables";
import { ToastContainer } from "react-toastify";

const Admins = memo(() => {
  const configCtx = useContext(ConfiguracionContext);
  const { Admin, setAdminValues, sendNewAdminGanadero } =
    configCtx;

  const validateForm = () => {
    let valid = false;
    if (Admin.name === "") valid = true;
    if (Admin.email === "") valid = true;
    return valid;
  };

  return (
    <div className="tabsConfigZone">
      <div className="inputsRoles">
        <div className="inputs">
          <input
            type="text"
            className="inputUsuarios InputsConfig"
            name="username"
            placeholder="Username"
            value={Admin.username}
            onChange={setAdminValues}
          />
          <input
            type="text"
            className="inputUsuarios InputsConfig"
            name="name"
            placeholder="Nombre"
            value={Admin.name}
            onChange={setAdminValues}
          />
          <button
            disabled={validateForm()}
            type="button"
            className="buttonsSend"
            name="newUsuario"
            onClick={sendNewAdminGanadero}
          >
            {" "}
            +{" "}
          </button>
        </div>
      </div>
      <ToastContainer />
      <AdminTables />
    </div>
  );
});

export default Admins;
