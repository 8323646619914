import React from 'react';
import './styles.css';

const ConfirmDelete = ({
    mainWarning,
    buttonAccept,
    buttonReject,
    actionFunction,
    itmeToDelete,
    deleteItem
}) => {

    return (
        <>
            <div className="contentWarning">
                <div className="openCloseSectionConfirm" onClick={() => actionFunction()}> X </div>

                <div className="contentMsg">
                    <p className="msgWarning">{mainWarning}</p>
                </div>
                <p className="deleteTarget">{itmeToDelete.name}</p>

                <div className="buttonsControlsWarning">

                    <button className="trueDesition" type="button" onClick={deleteItem} >{buttonAccept}</button>
                    <button
                        className="falseDesition"
                        type="button"
                        onClick={() => actionFunction()}
                    >{buttonReject}</button>

                </div>
            </div>
        </>
    )
}

export default ConfirmDelete
