import React, { useContext, useEffect, memo } from "react";
import { find } from "../../constants";
import "./styles.css";
import debounce from "lodash.debounce";
import { RanchContext } from "../../../Context/RanchosStore/context";

const SearchBox = memo(() => {
  const ranchContext = useContext(RanchContext);
  const {
    getInitialData,
    setPage,
    setSearchBarInput,
    searchBarInput,
    setLoading,
  } = ranchContext;
  const searchFunction = async (config) => {
    setLoading(true);
    setPage(1);
    getInitialData(config);
  };
  const debouncedSearch = React.useRef(
    debounce(async (config) => {
      await searchFunction(config);
    }, 300)
  ).current;
  async function handleChange(e) {
    setSearchBarInput(e.target.value);
    const config = {
      page: 1,
      limit: 12,
      search_text: e.target.value,
    };
    debouncedSearch(config);
  }
  useEffect(() => {
    return () => {
      debouncedSearch.cancel();
    };
  }, [debouncedSearch]);
  return (
    <div className="inpurSearchBoxGanaderos">
      <img className="findIcon" src={find} alt="Find" />
      <input
        className="findInput"
        type='text'
        min={0}
        value={searchBarInput}
        name="searchBox"
        placeholder="Buscar rancho"
        onChange={handleChange}
      />
    </div>
  );
});
export default SearchBox;
