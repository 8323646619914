import React from 'react';
import styles from './styles.module.css';


const  Recover = ({ getDataInputs }) => {
  return (
    <div className={styles.containerRecoverAuth}>
        <h6 
        className={styles.mainTitleRecover}>
        Ingresa la dirección de correo donde deseas recuperar tu contraseña
        </h6>
        <input 
        className={styles.inputRecover} 
        type="email" 
        name="email"
        placeholder="Correo electrónico"
        onChange={(e)=> getDataInputs(e)}
        />
    </div>
  )
}

export default Recover
