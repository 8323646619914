
export const MesesGenerator = () => {
  return Array
  .from( new Array(12) )
  .map( (el, i) =>
  {
    const month = new Date(new Date()
    .setMonth(i))
    .toLocaleDateString('es-MX', {month:"long"});

    return { mes:month, value: i <= 9 ? `0${i}`: `${i}` }
  });
}

export const YearsFunction = (int) =>{

  const arrYears = [];

const yearInterval = int;
 
 Array
  .from(new Array(  yearInterval ))
  .forEach( (_, i )=>{

    const minorNumber = ((2 * yearInterval ) + i) +5 <= 9 ? `200${((2 * yearInterval ) + i) +5}`: `20${((2 * yearInterval ) + i) +5}`;
    const majorName = ((2 * yearInterval -1 ) - i)+5 <= 9 ? `200${((2 * yearInterval -1 ) - i)+5}`: `20${((2 * yearInterval -1 ) - i)+5}`;
    arrYears.push( minorNumber);
    arrYears.unshift(majorName);
    })
    return arrYears;
}

export const dayOperator = 1000 * 3600 * 24;


  export const periodos = days => dayOperator * days;

