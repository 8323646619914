import './styles.css';
import { dimension } from '../../../../constants';
import React, { Fragment, useContext } from 'react';
import { GestacionContext } from '../../../../../Context/GestacionStore/context';

const Modal = ({ openCloseModal, gestationId }) => {
  const GestacionCtx = useContext(GestacionContext);
  const { setDataGestation, historialErros, submitCreateGestation } = GestacionCtx;

  const handleClose = () => {
    openCloseModal();
  };

  const handleChange = (e) => {
    setDataGestation((prev) => {
      return { ...prev, [e.target.name]: e.target.value };
    });
  };

  return (
    <div className="ModalContainer">
      <div className="FormContainer">
        <div onClick={handleClose} className="openCloseModal">
          <p>X</p>
        </div>
        <div className="titleFormPalpation">
          <h1>Crear Gestación</h1>
        </div>

        <form name="currentForm" className="currentForm">
          <Fragment>
            <div className="inputsPalpacionContainer">
              <div key="1-fechadegestacion" className="eachContainerInputs">
                <div className="labelsContainerPalpation">
                  <img src={dimension} alt="Fecha de monta" />
                  <label className="labelInputPalpation">Fecha de monta</label>
                </div>

                <div>
                  <input
                    className="inputPalpation"
                    type="date"
                    name="reproductionDate"
                    onChange={handleChange}
                    required={true}
                  />
                </div>
              </div>

              {historialErros !== '' && <label style={{ color: 'red' }}>{historialErros}</label>}
            </div>

            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginTop: '10px',
              }}
            >
              <button
                style={{ margin: '0' }}
                type="button"
                onClick={() => submitCreateGestation(gestationId)}
                className={`newRanchButtonAccept`}
              >
                Confirmar gestación
              </button>
            </div>
          </Fragment>
        </form>
      </div>
    </div>
  );
};

export default Modal;
