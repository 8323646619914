export default {
    Cabeza:{
        
        Siniiga:'',
        Rancho:'',
        Raza:'',
        Ganadero:'',
        Sexo:'',
        Status:'',
        Fecha:'',
        Ciclo:'',
        Observaciones:'',

        SiniigaM:'',
        RanchoM:'',
        GanaderoM:'',
        PedigreeM:'',

        SiniigaH:'',
        RanchoH:'',
        GanaderoH:'',
        PedigreeH:'',
    }
}