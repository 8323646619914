import React, { useContext } from "react";
import Login from "../Components/Auth/Login";
import Recover from "../Components/Auth/Recover";
import SignUp from "../Components/Auth/SignUp";
import Toast from "./../Components/Auxiliars/Toast";
import { background, logo } from "../Components/constants";
import { MainContext } from "../MainContext";
import "./../Css-pages/Auth.scss";
import CircularProgress from "@mui/material/CircularProgress";
const Auth = () => {
  const {
    sendDataAuth,
    cleanSignIn,
    cleanSignUp,
    cleanRecover,
    isLoginForm,
    setIsLoginForm,
    isRecover,
    setIsRecover,
    getDataInputsAuth,
    msgResponse,
    arrivalClass,
    IsToastUse,
    setIsToastUse,
    loading,
  } = useContext(MainContext);

  const getDataInputs = ({ target: { name, value } }) => {
    if (IsToastUse) {
      setIsToastUse(false);
      setIsRecover(false);
    }
    getDataInputsAuth(name, value);
  };

  const handleSubmitLogin = (e) => {
    e.preventDefault();

    isLoginForm
      ? sendDataAuth("login")
      : isRecover
      ? sendDataAuth("recover")
      : sendDataAuth("signup");
  };

  const cleanAllInputs = () => {
    cleanSignIn();
    cleanSignUp();
    cleanRecover();
  };
  const fecha = new Date();
  return (
    <>
      <div
        className="MainContainer_Auth"
        style={{
          background: `url('${background}')`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="formsContainer">
          <form className="MainFormAuth">
            <div className="inner-logoContainer">
              <img src={logo} alt="logo" />
            </div>
            {isRecover ? (
              <Recover getDataInputs={(e) => getDataInputs(e)} />
            ) : isLoginForm ? (
              <Login getDataInputs={(e) => getDataInputs(e)} />
            ) : (
              <SignUp getDataInputs={(e) => getDataInputs(e)} />
            )}

            <button
              className={"MainButtonSendAuthData"}
              name={
                isRecover ? "Enviar" : isLoginForm ? "Entrar" : "Crear cuenta"
              }
              type="submit"
              onClick={(e) => handleSubmitLogin(e)}
            >
              {loading ? (
                <CircularProgress className={"loader"} size={20} />
              ) : (
                <>
                  {isRecover
                    ? "Enviar"
                    : isLoginForm
                    ? "Entrar"
                    : "Crear cuenta"}
                </>
              )}
            </button>

            <div className="buttonsAccountCoontainer">
              {!isLoginForm ? (
                <button
                  className={"buttonAuthSendCredentials"}
                  type="button"
                  name="login_Btn"
                  onClick={() => {
                    cleanAllInputs();
                    setIsLoginForm(true);
                    setIsRecover(false);
                  }}
                >
                  Iniciar Sesión
                </button>
              ) : (
                <>
                  {/* <button
                      className={buttonAuthSendCredentials}
                      type="button" name="newAccount_Btn"
                      onClick={() => {
                        cleanAllInputs();
                        setIsLoginForm(false);
                      }}>
                      Crear una cuenta
                    </button> */}
                  <button
                    className={"buttonAuthSendCredentials"}
                    type="button"
                    name="recover_Btn"
                    onClick={() => {
                      cleanAllInputs();
                      setIsRecover(true);
                      setIsLoginForm(false);
                    }}
                  >
                    {" "}
                    Olvidé mi contraseña
                  </button>
                </>
              )}
              <div className="ContainerCopyRigth">
                <p>Todos los derechos reservados / GANADERÍA {fecha.getFullYear()} </p>
                <p>Términos y condiciones</p>
              </div>
            </div>
            {/* {IsToastUse && (
              <Toast msg={msgResponse} arrivalClass={arrivalClass} />
            )} */}
          </form>
          <div className="logoContainer">
            <img src={logo} alt="logo" />
          </div>
        </div>
      </div>
    </>
  );
};

export default Auth;
