import React, {useContext} from 'react';
import Ganaderosprovider from './../Context/GanaderosStore/context';
import GanaderosComponent from './../Components/Ganaderos/index'
import Hato from '../layout/Hato_Theme';
import { MainContext } from '../MainContext';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';

const Ganaderos = ({children, match}) => {
  const mainCtx = useContext( MainContext )

  const { user } = mainCtx;
  return (
    <Hato>
      <Ganaderosprovider children={children}>
        <GanaderosComponent mainId={ user.id } match={match}/>
      </Ganaderosprovider>
    </Hato>
  )
}
export default Ganaderos
