import React, { useContext } from "react";
import { DetailsContext } from "../../../../Context/Details/context";
import "./styles.css";
import { useLocation } from "react-router-dom";
import { cycleOptions } from "./contants";
const SexSelector = () => {
  const { pathname } = useLocation();
  const splitPathname = pathname.split("/");
  const ranchID = splitPathname[2];
  const datailsCtx = useContext(DetailsContext);
  const {
    getInitialData,
    setPage,
    breedFilter,
    setLoading,
    statusFilter,
    inputSearch,
    sexFilter,
    cycleFilter,
    setCycleFilter,
  } = datailsCtx;

  const handleChange = (e) => {
    const config = {
      page: 1,
      limit: 5,
      id: ranchID,
      search_text: inputSearch,
      sex: sexFilter,
      breed: breedFilter,
      status: statusFilter,
      cycle: e.target.value,
    };
    setCycleFilter(e.target.value);
    setLoading(true);
    setPage(1);
    getInitialData(config);
  };

  return (
    <div className="mainContainerFiltersDetails">
      <div className="inpurSearchBox colorSelect">
        <select
          defaultValue={cycleFilter}
          name="SexoDetailsSelect"
          className="SelecteSexoDetails"
          onChange={handleChange}
        >
          <option value="">Ciclo</option>
          {cycleOptions.map((option) => {
            return (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            );
          })}
        </select>
      </div>
    </div>
  );
};
export default SexSelector;
