import React, { useContext } from "react";
import { PalpacionesContext } from "../../../Context/PalpacionesStore/context";
import Tabla from "./PalpationTable";
import CircularProgress from "@mui/material/CircularProgress";
import InputSearch from "./InputSearch/InputSearch";
import BreedSelector from "./BreedSelector/BreedSelector";
import StatusSelector from "./StatusSelector/StatusSelector";
import RanchSelector from "./RanchSelector/RanchSelector";

import "./styles.css";
import Pagination from "@mui/material/Pagination";
import { useLocation, useHistory } from "react-router-dom";

const Palpaciones = ({ item, match }) => {
  const { pathname } = useLocation();
  const history = useHistory();
  const splitPathname = pathname.split("/");
  const ranchID = splitPathname[2];
  const PalpacionesCxt = useContext(PalpacionesContext);
  const {
    getInitialData,
    listGanado,
    loading,
    setLoading,
    paginationInfo,
    inputSearch,
    page,
    setPage,
    breedFilter,
    sexFilter,
    statusFilter,
  } = PalpacionesCxt;


  const getId = (e) => {
    history.push(`Palpacion/${e}/historial`);
  };
  const handleChangePage = (_, newPage) => {
    setLoading(true);
    setPage(newPage);
    const config = {
      page: newPage,
      limit: 5,
      id: ranchID,
      search_text: inputSearch,
      sex: sexFilter,
      breed: breedFilter,
      status: statusFilter,
    };
    getInitialData(config);
  };

  return (
    <div className="MainDetailsComponentContainer">
      <h1 className="misRanchos">Palpación</h1>
      <div className="ranch-filters-row">
        <div className="mainContainerFiltersDetails">
          <InputSearch />
          <RanchSelector />
          <BreedSelector />
          {/* <StatusSelector /> */}
        </div>
      </div>

      {loading ? (
        <div className="loading">
          <CircularProgress color="warning" size={100} />
        </div>
      ) : (
        <>
          {listGanado.length > 0 && (
            <>
              <Tabla
                getIdGanadero={getId}
                tablesInfo={listGanado}
                setActiveRanch={() => {}}
                redirect={true}
                match={match}
              />
            </>
          )}
        </>
      )}
      <div className="ganaderos-pagination">
        <Pagination
          count={paginationInfo?.totalPages}
          page={page}
          onChange={handleChangePage}
          shape="rounded"
        />
        <label style={{ marginTop: "7px" }}>
          Palpaciones en total : {paginationInfo?.totalLivestocks}
        </label>
      </div>
    </div>
  );
};

export default Palpaciones;
