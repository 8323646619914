import React, { useContext, useState } from 'react';
import BirthsForm from '../../../../Forms/NewBreed';
import './styles.css';
import { GestacionContext } from '../../../../../Context/GestacionStore/context';
import { NewBreed2 } from '../../../../constants';

const Modal = ({ openCloseModal, gestationId }) => {
  const [loading, setLoading] = useState(false);
  const GestacionCtx = useContext(GestacionContext);
  const { handleCowFormInputs, submitErrors, livestockMotherInfo } = GestacionCtx;
  const handleClose = () => {
    openCloseModal();
  };
  return (
    <div className="ModalContainer">
      <div className="FormContainer">
        <div onClick={handleClose} className="openCloseModal">
          <p>X</p>
        </div>
        {loading ? (
          <div>CARGANDO</div>
        ) : (
          <form name="currentForm" className="currentForm">
            <div className="contentDinamicForm">
              <BirthsForm
                form={NewBreed2}
                heigth={'250px'}
                submitErrors={submitErrors}
                handleCowFormInputs={handleCowFormInputs}
                livestockMotherInfo={livestockMotherInfo}
              />
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export default Modal;
