import React, { useState, useEffect, useContext } from "react";
import "./styles.css";
import { VacunasListContext } from "../../../../../Context/VaccineListStore/context";
const Switch = ({ id, isActive, onChange }) => {
  const vacunasCtx = useContext(VacunasListContext);
  const {enableVaccineGroup} = vacunasCtx;

  const [active, setActive] = useState(isActive);
  useEffect(() => {
    setActive(isActive);
  }, [id]);

  const handleChange = async () => {
    //onChange(id);
    const result = await enableVaccineGroup(id)
    if(!result.error){
      setActive(!active);
    }
  };

  return (
    <div
      className={active ? "switchbutton" : "switchbuttonDeactive"}
      id={id}
      onClick={handleChange}
    >
      <span
        className={active ? "finalPosition disabledClass" : "initialPosiotion"}
      ></span>
    </div>
  );
};

export default Switch;
