import React, { createContext, useState, useEffect } from "react";
import { shape, string, node, func, bool } from "prop-types";
import { withRouter } from "react-router";
import VacunasStore from "./store";
import { MesesGenerator } from "./FechasLib";
import {
  getPeriods,
  getGroupsByPeriod,
  getLivestockByGroupAndSection,
  postVacinateLivestock,
  postVacinateAll,
} from "../../servicces";
import {  toast } from "react-toastify";

export const VacunasContext = createContext(VacunasStore);

const VacunasProvider = ({ children }) => {
  const [grupos, setGrupos] = useState([]);
  const [periodSelector, setPeriodSelector] = useState("");
  const [grupoSelector, setgrupoSelector] = useState("");
  const [sectionSelector, setsectionSelector] = useState("no");
  //no: for not vaccine -  vaccinate: for vaccineted - expired: for expired vaccined
  const success = (text) => toast.success(text, { autoClose: 3000 });
  const Error = (text) => toast.error(text, { autoClose: 3000 });
  const [periodos, setPeriodos] = useState([]);
  const [livestockPageInfo, setlivestockPageInfo] = useState(null);
  const [livestock, setLivestock] = useState(null);
  const [livestockPage, setlivestockPage] = useState(1);

  const [selectForVaccine, setSelectForVaccine] = useState([]);

  const [openDialog, setOpenDialog] = useState(false);

  const [row, setRow] = useState([]);
  const [rowRaw, setRawRow] = useState([]);
  const [Months, setMonths] = useState(MesesGenerator());
  const [isCartillaActive, setIsCartillaActive] = useState(true);
  const [filterVac, setFilterVac] = useState(false);
  const [selectGroup, setSelectGroup] = useState("");
  const [changeFilterVac, setChangeFilterVac] = useState(false);

  const findCartillas = ([initialDate, finalDate]) => {
    const dateTofind = initialDate - finalDate;
    setRow(
      rowRaw.filter((el) => {
        return new Date(el.Fecha).getTime() > Number(dateTofind);
      })
    );
  };

  const getInitialData = async () => {
    try {
      const response = await getPeriods();
      setPeriodos(response.data.periods);
    } catch (error) {
      console.log(error);
    }
  };

  const GetGroupsByPeriod = async (period) => {
    try {
      const result = await getGroupsByPeriod({ page: 1, limit: 100, period});
      setGrupos(result.data.data.groups);
    } catch (error) {
      console.log(error);
    }
  };

  const getLivestockTables = async (config) => {
    try {
      const result = await getLivestockByGroupAndSection(config);
      console.log(result);
      if (!result.data.data) {
        setlivestockPageInfo(null);
        setLivestock("El grupo seleccionado no posee un tiempo de expiración");
        return;
      }
      setlivestockPageInfo(result.data.data);
      setLivestock(result.data.data.ganado);
    } catch (error) {
      console.log("error", error);
    }
  };

  const vaccinateAll = async () => {
    if (grupoSelector !== "") {
      try {
        const result = await postVacinateAll({
          group: grupoSelector,
          section: sectionSelector,
        });
        setSelectForVaccine([]);
        setgrupoSelector("");
        setLivestock(null);
        setOpenDialog(false);
        success(`Este grupo fue vacunado correctamente`);
      } catch (error) {
        Error(`${error.response.data.msg}`);

        console.log(error);
      }
    }
  };

  const vaccinateLivestock = async () => {
    if (grupoSelector !== "") {
      try {
        await postVacinateLivestock({
          group: grupoSelector,
          section: sectionSelector,
          livestocks: selectForVaccine,
        });
        const config = {
          page: 1,
          limit: 10,
          group: grupoSelector,
          section: sectionSelector, //no - expired- vaccinate
        };
        getLivestockTables(config);
        success(`El ganado marcado fue vacunado correctamente`);
      } catch (error) {
        console.log(error);
        Error(`${error.response.data.msg}`);
      }
    }
  };

  useEffect(() => {
    setSelectForVaccine([]);
    setgrupoSelector("");
    setLivestock(null);
    if (periodSelector !== "") {
      GetGroupsByPeriod(periodSelector);
    } else {
      setGrupos([]);
    }
  }, [periodSelector]);

  const state = {
    periodos,
    grupos,
    isCartillaActive,
    periodSelector,
    grupoSelector,
    setPeriodSelector,
    setgrupoSelector,
    sectionSelector,
    setsectionSelector,
    row,
    selectGroup,
    filterVac,
    setFilterVac,
    setChangeFilterVac,
    findCartillas,
    setSelectGroup,
    Months,
    getLivestockTables,
    livestockPageInfo,
    livestockPage,
    setlivestockPage,
    setLivestock,
    setlivestockPageInfo,
    livestock,
    selectForVaccine,
    setSelectForVaccine,
    vaccinateAll,
    vaccinateLivestock,
    openDialog,
    setOpenDialog,
    /*  */
  };

  useEffect(() => {
    getInitialData();
  }, []);
  return (
    <VacunasContext.Provider value={state}>{children}</VacunasContext.Provider>
  );
};

VacunasProvider.propTypes = {
  children: node,
  rows: shape(),
  VacunasValues: shape(),
  isOpenModal: bool,
  searchBarInput: string,
  setVacunasValues: func,
  getInitialDataBool: bool,
  isEditableForm: bool,
  setRows: func,
  setGetInitialDataBool: func,
  setSearchBarInput: func,
  setIsOpenModal: func,
  setIsEditableForm: func,
};

VacunasProvider.defaultProps = {
  children: null,
  searchBarInput: "",
  rows: {},
  VacunasValues: {},
};

export default withRouter(VacunasProvider);
