import React, { useContext } from 'react';
import ConfiguracionProvider from './../Context/ConfiguracionStore/context';
import ConfiguracionComponent from '../Components/Configuracion';
import Hato from '../layout/Hato_Theme';
import { MainContext } from '../MainContext';

const Configuracion = ({children, match}) => {
  const mainCtx = useContext( MainContext );
  return (
    <Hato>
        <ConfiguracionProvider children={children}>
            <ConfiguracionComponent mainId={ mainCtx.user.id }  match={match}/>
        </ConfiguracionProvider>
    </Hato>
  )
};

export default Configuracion
