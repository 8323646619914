import { useContext, useState } from "react";
import SearchBar from "./SearchBar/SearchBar";
import SearchByMonths from "./SearchByMonths/SearchByMonths";
import Modal from "../../Components/ModalContainer";


// import Table from "./Table";
import "./styles.css";
import { PieContext } from "../../Context/PieCria/Context";
import CircularProgress from "@mui/material/CircularProgress";
import Pagination from "@mui/material/Pagination";
import Tabla from "./CriaTable";
import ModalDetails from "../Main/Details/ModalDetails";
import "./styles.css";
const PieCriaComponent = ({ item, match }) => {
  const detailsCtx = useContext(PieContext);
  const {
    Cabezas,
    isOpenModalDetails,
    openModalDetails,
    dataFromID,
    getInitialData,
    ranchPaginationInfo,
    page,
    setPage,
    inputSearch,
    loading,
    setLoading,
    modalnewSiniiga,
    setmodalnewSiniiga,
    siniigaModel,
    handleSiniigaValue,
    handleSubmitSiniigaForm,
    //private
    handlePrivateValueMethod,
    handleSubmitPrivateFormMethod,
    deletePrivateMethod,
    privateModel,
    setPrivateModel,
    modalPrivate,
    setmodalPrivate,
    privateInit
  } = detailsCtx;
  const getId = (e) => {
    openModalDetails(true, e);
  };

  const handleChangePage = (_, newPage) => {
    setLoading(true);
    setPage(newPage);
    const config = {
      page: newPage,
      limit: 10,
      search_text: inputSearch,
    };
    getInitialData(config);
  };

  const closeModal = () => {
    setmodalnewSiniiga(false);
  }

  const closeModalPrivate = () => {
    setmodalPrivate(false);
  }



  return (
    <>

      {/* priv */}
      {modalPrivate ?
        <Modal
          ActiveForm={modalPrivate}
          openCloseModal={closeModalPrivate}
        >
          {privateModel.id != '' ? <>  <h1>Numero Privado por defecto</h1>
            <div style={{ textAlign: 'center' }}>
              <div>
                <div className='label-gestation'>
                  <label>
                    Numero Privado
                  </label>
                </div>
                <input
                  value={privateModel.priv}
                  type="text"
                  className="inputRoles InputsConfig"
                  name="priv"
                  placeholder="Ingresa numero privado"
                  disabled={true}
                />
              </div>
            </div>
            <button onClick={handleSubmitPrivateFormMethod} className="newRanchButtonAccept" type="button" >Actualizar</button>
            <button onClick={closeModalPrivate} className="newRanchButtonCancel" type="button">Cancelar</button>
          </> : <>
            {/* //crear */}
            <h1>Crear Numero Privado</h1>
            <div style={{ textAlign: 'center' }}>
              <div>
                <div className='label-gestation'>
                  <label>
                    Numero Privado
                  </label>
                </div>
                <input
                  value={privateModel.priv}
                  type="text"
                  className="inputRoles InputsConfig"
                  name="priv"
                  placeholder="Ingresa numero privado"
                  onChange={handlePrivateValueMethod}
                />
              </div>
            </div>
            <button onClick={handleSubmitPrivateFormMethod} className="newRanchButtonAccept" type="button" >Crear</button>
            <button onClick={closeModalPrivate} className="newRanchButtonCancel" type="button">Cancelar</button>
          </>}

        </Modal>
        : null}

      {/* priv */}

      {/* siniga */}
      {modalnewSiniiga ?
        <Modal
          ActiveForm={modalnewSiniiga}
          openCloseModal={closeModal}
        >
          {siniigaModel.id != '' ? <>  <h1>Siniiga por defecto</h1>
            <div style={{ textAlign: 'center' }}>
              <div>
                <div className='label-gestation'>
                  <label>
                    Siniiga
                  </label>
                </div>
                <input
                  value={siniigaModel.siniiga}
                  type="text"
                  className="inputRoles InputsConfig"
                  name="siniiga"
                  placeholder="siniiga"
                  onChange={handleSiniigaValue}
                  disabled={true}
                />
              </div>
            </div>
            <button onClick={handleSubmitSiniigaForm} className="newRanchButtonAccept" type="button" >Actualizar</button>
            <button onClick={closeModal} className="newRanchButtonCancel" type="button">Cancelar</button>
          </> : <>  <h1>Crear Siniiga</h1>
            <div style={{ textAlign: 'center' }}>
              <div>
                <div className='label-gestation'>
                  <label>
                    Siniiga
                  </label>
                </div>
                <input
                  value={siniigaModel.siniiga}
                  type="text"
                  className="inputRoles InputsConfig"
                  name="siniiga"
                  placeholder="siniiga"
                  onChange={handleSiniigaValue}
                />
              </div>
            </div>
            <button onClick={handleSubmitSiniigaForm} className="newRanchButtonAccept" type="button" >Crear</button>
            <button onClick={closeModal} className="newRanchButtonCancel" type="button">Cancelar</button>
          </>}

        </Modal>
        : null}

      {/* siniga */}



      <div className="pieCriaContainer">
        <div className="header">
          <h1>Cr&iacute;a</h1>
        </div>
        <div style={{
          display: 'flex'
        }}>
          <SearchBar />
          <SearchByMonths />
        </div>

        <div className="cardContainerGanaderos">
          {loading ? (
            <div className="loading">
              <CircularProgress color="warning" size={100} />
            </div>
          ) : (
            Cabezas.length > 0 && (
              <Tabla
                getIdGanadero={getId}
                tablesInfo={Cabezas}
                setActiveRanch={() => { }}
                redirect={true}
                match={match}
                pie
              />
            )
          )}
          <div className="ganaderos-pagination">
            <Pagination
              count={ranchPaginationInfo?.totalPages}
              page={page}
              onChange={handleChangePage}
              shape="rounded"
            />
            <label style={{ marginTop: "7px" }}>
              Ganado en total : {ranchPaginationInfo?.totalLivestocks}
            </label>
          </div>
        </div>
        {isOpenModalDetails && dataFromID && (
          <ModalDetails
            closeFunction={() => {
              openModalDetails(false);
            }}
            data={dataFromID}
            getInitialData={getInitialData}
            getId={(id) => {
              getId(id);
            }}
          />
        )}
      </div>
    </>
  );
};

export default PieCriaComponent;
