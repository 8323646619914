import React, { useContext, memo } from "react";
import { GanaderosContext } from "../../../Context/GanaderosStore/context";

const SelectorRanchs = memo(() => {
  const ganaderosContext = useContext(GanaderosContext);
  const {
    getInitialData,
    setLoading,
    setPage,
    Ranchos,
    activeSelector,
    inputSearch,
    ranchsSelector, setRanchsSelector
  } = ganaderosContext;
  const handleChange = (e) => {
    setLoading(true);
    setPage(1);
    getInitialData({
      page: 1,
      limit: 10,
      search_text: inputSearch,
      active: activeSelector,
      ranch: e.target.value,
    });
    setRanchsSelector(e.target.value);
  };
  return (
    <section className="formGroup">
      <label htmlFor="ranchos_ganaderos">Ranchos:</label>
      <select
        placeholder="ranchos"
        id="ranchos_ganaderos"
        onChange={handleChange}
      >
        <option value="todos">Todos</option>
        {Ranchos.map((ranch) => (
          <option key={ranch._id} selected={ranch._id === ranchsSelector} value={ranch._id}>
            {ranch.name}
          </option>
        ))}
      </select>
    </section>
  );
});

export default SelectorRanchs;
