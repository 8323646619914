import React, { useEffect, useContext } from 'react';
import './styles.css';
import { DetailsContext } from '../../../Context/Details/context';
import { useLocation } from 'react-router-dom';

const EditProgenitorH = ({ form, handleCowFormInputs, heigth, submitErrors }) => {
  const datailsCtx = useContext(DetailsContext);
  const { Cabeza, setCabeza } = datailsCtx;
  const { pathname } = useLocation();
  const splitPathname = pathname.split('/');
  const ranchID = splitPathname[2];

  useEffect(() => {
    if (ranchID)
      setCabeza((prev) => {
        return {
          ...prev,
          ranch: ranchID,
        };
      });
  }, [ranchID]);

  return (
    <div className={form.mainClass}>
      <h1 className="titleGrupo">{form.Maintitle}</h1>

      <div className="TabsGanaderos" style={{ height: heigth }}>
        {form.part1.map((el, i) => (
          <div className="eachInputForm" key={`eachInput-${i}`}>
            <div className="contentLabel">
              <img className="iconInput" src={el.icon} alt={el.name} />
              <label className="labelInput">{el.title}</label>
            </div>

            <input
              className="inputText ganado"
              type={el.type}
              name={el.name}
              defaultValue={Cabeza[el.name]}
              onChange={(e) => handleCowFormInputs(e.target)}
            />
          </div>
        ))}
      </div>

      {submitErrors !== '' && <label style={{ color: 'red' }}>{submitErrors}</label>}
    </div>
  );
};

export default EditProgenitorH;
