import React, { createContext, useState, useEffect } from "react";
import { shape, string, node, func, bool } from "prop-types";
import { withRouter } from "react-router";
import VacunasStore from "./store";
import { toast } from "react-toastify";
import {
  getInitialDataVaccines,
  sendNewGroupService,
  sendNewVacunaService,
  toggleVaccineGroup,
  InitialDataPetitionGanaderos,
  InitialDataPetition,
  getBreeds,
} from "../../servicces";
const initialConfig = {
  page: 1,
  limit: 10,
};

export const VacunasListContext = createContext(VacunasStore);

const VacunasListProvider = ({ children }) => {
  const success = (text) => toast.success(text, { autoClose: 3000 });
  const ERROR = (text) => toast.error(text, { autoClose: 3000 });
  const [Grupo, setGrupo] = useState(VacunasStore.Grupo);
  const [newVacunas, setNewVacunas] = useState(VacunasStore.Vacuna);
  const [errorVaccineCreate, setErrorVaccineCreate] = useState("");
  const [createVaccineAlert, setCreateVaccineAlert] = useState(false);

  const [isEditable, setIsEditable] = useState(false);
  const [groupModal, setgroupModal] = useState(false);
  const [groupId, setGroupId] = useState(false);
  const [groupError, setGroupError] = useState(false);

  const [vaccineModal, setvaccineModal] = useState(false);
  const [vacunaID, setVacunaID] = useState(null);
  const [isVacunaEditable, setIsVacunaEditable] = useState(false);
  const [grupoDeVacunas, setgrupoDeVacunas] = useState([]);
  const [VacunasArr, setVacunasArr] = useState([]);
  const [VacunasRaw, setVacunasRaw] = useState([]);

  const [Loading, setLoading] = useState(true);
  const [gruposArr, setgruposArr] = useState([]);
  const [gruposInfoPage, setgruposInfoPage] = useState(null);
  const [gruposPage, setgruposPage] = useState(1);

  const getInitialGroupData = async (config) => {
    const result = await getInitialDataVaccines(config || initialConfig);
    setgruposInfoPage(result.data.data);
    setgruposArr(result.data.data.groups);
    setLoading(false);
  };

  const sendNewVacuna = async () => {
    setErrorVaccineCreate("");
    if (newVacunas.name === "") return;
    if (newVacunas.via === "") return;
    if (newVacunas.dose === "") return;
    if (newVacunas.kg === "") return;
    try {
      await sendNewVacunaService(newVacunas);
      setCreateVaccineAlert(true);
      /*  setTimeout(() => {
        setvaccineModal(false);
        setCreateVaccineAlert(false);
      }, 1500); */
      success(`La vacuna ha sido creada correctamente`);
      setvaccineModal(false);
    } catch (error) {
      setErrorVaccineCreate(error.response.data.msg);
    }
  };
  const sendNewGroup = async () => {
    setGroupError("");
    const grupRecurrence = Grupo.recurrence;
    const data = { ...Grupo };
    data.recurrence = false;
    if (grupRecurrence === "true") data.recurrence = true;
    try {
      await sendNewGroupService(data);
      getInitialGroupData();
      setgroupModal(false);
    } catch (error) {
      setGroupError(error.response.data.msg);
    }
  };

  const enableVaccineGroup = async (id) => {
    try {
      await toggleVaccineGroup(id);
      getInitialGroupData({ page: gruposPage, limit: 10 });
      success('Su vacuna ha sido actualizada correctamente')
      return {error:false, msg:''}
    } catch (error) {
      ERROR(error.response.data.msg)
      return {error:true, msg:error.response.data.msg}
    }
  };

  const [isModalGrupoActive, setModalGrupoActive] = useState(false);

  const closeActiveModalGroup = () => {
    setModalGrupoActive(!isModalGrupoActive);
  };

  const findVaccinesByGroup = (id) => {
    setgrupoDeVacunas(gruposArr.filter((el) => el._id === id));
    setVacunasArr(VacunasRaw.filter((el) => el.Grupo === id));
  };

  const state = {
    Loading,
    setLoading,
    getInitialGroupData,
    isEditable,
    vacunaID,
    setVacunaID,
    setGrupo,
    isVacunaEditable,
    sendNewGroup,
    setNewVacunas,
    sendNewVacuna,
    findVaccinesByGroup,
    gruposArr,
    isModalGrupoActive,
    setModalGrupoActive,
    closeActiveModalGroup,
    VacunasArr,
    groupModal,
    setgroupModal,
    vaccineModal,
    setvaccineModal,
    errorVaccineCreate,
    createVaccineAlert,
    groupId,
    setGroupId,
    gruposInfoPage,
    setgruposPage,
    gruposPage,
    groupError,
    enableVaccineGroup,
  };

  useEffect(() => {}, [gruposArr, VacunasArr, VacunasRaw]);

  useEffect(() => {
    getInitialGroupData(initialConfig);
  }, []);

  return (
    <VacunasListContext.Provider value={state}>
      {children}
    </VacunasListContext.Provider>
  );
};

VacunasListProvider.propTypes = {
  children: node,
  rows: shape(),
  VacunasValues: shape(),
  isOpenModal: bool,
  searchBarInput: string,
  setVacunasValues: func,
  getInitialDataBool: bool,
  isEditableForm: bool,
  setRows: func,
  setGetInitialDataBool: func,
  setSearchBarInput: func,
  setIsOpenModal: func,
  setIsEditableForm: func,
};

VacunasListProvider.defaultProps = {
  children: null,
  searchBarInput: "",
  rows: {},
  VacunasValues: {},
};

export default withRouter(VacunasListProvider);
