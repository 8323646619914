import React, { useContext, useState, useEffect } from "react";
import { PalpacionesContext } from "../../../../Context/PalpacionesStore/context";
import "./styles.css";
import { getRanchs } from "../../../../servicces";
import { useLocation } from "react-router-dom";
const RanchSelector = () => {
  const { pathname } = useLocation();
  const splitPathname = pathname.split("/");
  const ranchID = splitPathname[2];
  const PalpacionesCxt = useContext(PalpacionesContext);
  const {
    getInitialData,
    setPage,
    breedFilter,
    setLoading,
    statusFilter,
    inputSearch,
    sexFilter,
    ranchFilter,
    setRanchFilter,
  } = PalpacionesCxt;
  const [ranchList, setRanchList] = useState([]);
  useEffect(() => {
    const callranchs = async () => {
      try {
        const result = await getRanchs();
        setRanchList(result.data.data.ranchs);
      } catch (error) {
        console.log(error);
      }
    };
    callranchs();
  }, []);

  const handleChange = (e) => {
    const config = {
      page: 1,
      limit: 5,
      id: ranchID,
      search_text: inputSearch,
      sex: sexFilter,
      breed: breedFilter,
      status: statusFilter,
      ranch: e.target.value,
    };
    setRanchFilter(e.target.value);
    setLoading(true);
    setPage(1);
    getInitialData(config);
  };

  return (
    <div className="mainContainerFiltersDetails">
      <div className="inpurSearchBox colorSelect">
        <select
          defaultValue={ranchFilter}
          name="SexoDetailsSelect"
          className="SelecteSexoDetails"
          onChange={handleChange}
        >
          <option value="">Ranchos</option>
          {ranchList.map((option) => {
            return (
              <option key={option._id} value={option._id}>
                {option.name}
              </option>
            );
          })}
        </select>
      </div>
    </div>
  );
};
export default RanchSelector;
