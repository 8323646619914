import React, { useContext } from "react";
import Switch from "../../Switch/Switch";
import { ROLES_TITLES } from "../../../constants";
import { ConfiguracionContext } from "../../../../Context/ConfiguracionStore/context";
import { edit } from "../../../constants";

import "./styles.css";
const Tabla = () => {
  const configCtx = useContext(ConfiguracionContext);
  const { enableRole, RolesArr ,setrolEditting,
    setisEditRoles,} = configCtx;

  const renderTitle = ROLES_TITLES.map((el, i) => (
    <th key={`title-${i}`} className="eachCeld">
      {el}
    </th>
  ));

  const editPermission = (data) => {
    setisEditRoles(true);
    setrolEditting(data);
  }

  const PERMISSION_TYPES_SP = {
    read: "Leer",
    add: "Agregar",
    modified: "Modificar",
    delete: "Eliminar",
  };
  const PERMISSION_SECTIONS_SP = {
    breeding: "Cría",
    palpations: "Palpaciones",
    ranchers: "Ganadero",
    ranchs: "Rancho",
    vaccines: "Vacunas",
    births: "Gestación",
  };
  const renderPermissions = (permissions) =>
    Object.keys(permissions).map((sections, key) => {
      const section = permissions[sections];
      let renderTypes = "";
      Object.keys(section).forEach((res) => {
        let permision = PERMISSION_TYPES_SP[res]
        if (sections === 'ranchs' && res === 'delete' && section[res]) permision = 'Deshabilitar'
        if (section[res])
          renderTypes += `${section[res] && permision} `;
      });
      if (PERMISSION_SECTIONS_SP[sections] === "Cría") return null;
      return (
        <li className="list-per" key={key}>
          <label style={{ fontWeight: "600" }}>
            {PERMISSION_SECTIONS_SP[sections]}
          </label>
          : {renderTypes}
        </li>
      );
    });
  const renderColumns = (item, rowKey) => {
    item.edit = true;
    return Object.keys(item).map((res, key) => {
      if (res === "account") return null;
      if (res === "_id") return null;
      if (res === "edit") {
        return (
          <th className="thValues-rol">
            <img
              className="editable_Btn"
              src={edit}
              alt="edit"
              id={res._id}
              onClick={_ => {
                editPermission(item);
              }}
            />
          </th>
        );
      }
      if (res === "permissions")
        return (
          <th key={`${rowKey}-${res}-${key}`} className="thValues-rol">
            <ul>{renderPermissions(item[res])}</ul>
          </th>
        );

      if (res === "active")
        return (
          <th className="thValues-rol">
            <div className="center-switch">
              <Switch
                isActive={item[res]}
                id={item._id}
                onChange={enableRole}
              />
            </div>
          </th>
        );
      return (
        <th key={`${rowKey}-${res}-${key}`} className="thValues-rol">
          {item[res] ? item[res] : "-"}
        </th>
      );
    });
  };
  const renderRows = RolesArr.map((res, key) => {
    return (
      <tr className="eachRow" key={`row_${key}`}>
        {renderColumns(res, key)}
      </tr>
    );
  });

  return (
    <div className="MainTablasContainer">
      <table className="tableElement">
        <tbody className="tableTbody">
          <tr className="tableTitles">{renderTitle}</tr>
          {renderRows}
        </tbody>
      </table>
    </div>
  );
};

export default Tabla;
