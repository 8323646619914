import React from "react";
import Vacunasprovider from "../Context/VaccineListStore/context";
import VaccineList from "../Components/Main/VacunasLista/VacunasLista";
import Hato from "../layout/Hato_Theme";
const Vacunas = ({ children, match }) => {
  return (
      <Hato>
        <Vacunasprovider children={children}>
          <VaccineList match={match} />
        </Vacunasprovider>
      </Hato>
  );
};

export default Vacunas;
