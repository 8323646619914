import React, { useState } from 'react'
import './styles.css'

const Switch = ({ id, title, setActiveIDSwitch, isActive, setPage, setItmeToDelete }) => {
  const [active, setActive] = useState(true);

  const changePosition = (e) => {
    setActive(!active)
   if(setActiveIDSwitch)setActiveIDSwitch(e.currentTarget.id)
    setItmeToDelete && setItmeToDelete({ name: title, sActive: isActive, _id: id })
    setPage && setPage(0)
  }

  return (
    <div
      className={isActive ? "switchbutton" : "switchbuttonDeactive"}
      id={id}
      onClick={e => changePosition(e)}>
      <span
        className={
          isActive
            ? "finalPosition disabledClass"
            : "initialPosiotion"}>
      </span>
    </div>
  )
}

export default Switch
