import React, { createContext, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { node } from "prop-types";
import { withRouter } from "react-router";
import {
  login,
  recover,
  signup,
  recoverUserInSession,
} from "./servicces/AtuhServices";
import { toast } from "react-toastify";

export const MainContext = createContext();

const MainProvider = ({ children, location }) => {
  const Error = (text) => toast.error(text, { autoClose: 5000, position:'top-center' });
  const Success = (text) => toast.success(text, { autoClose: 5000, position:'top-center' });
  let history = useHistory();

  const [user, setUser] = useState({
    Name: "",
    status: false,
    id: null,
    acces: [],
  });

  const [loading, setLoading] = useState(false);

  const [formSigmIn, setFormSigmIn] = useState({
    email: "",
    password: "",
  });

  const [formSingnUp, setFormSingnUp] = useState({
    Name: "",
    email: "",
    password: "",
    confirmPassword: "",
  });

  const [formRecover, setformRecover] = useState({
    email: "",
  });

  const cleanSignIn = () =>
    setFormSigmIn({
      email: "",
      password: "",
    });

  const cleanSignUp = () =>
    setFormSingnUp({
      Name: "",
      email: "",
      password: "",
      confirmPassword: "",
    });

  const cleanRecover = () =>
    setformRecover({
      email: "",
    });

  const [isLoginForm, setIsLoginForm] = useState(true);
  const [isRecover, setIsRecover] = useState(false);
  const [statusResponse, setstatusResponse] = useState(false);
  const [msgResponse, setmsgResponse] = useState("");
  const [arrivalClass, setarrivalClass] = useState("");
  const [IsToastUse, setIsToastUse] = useState(false);
  const [UserInSession, setUserInSession] = useState({});

  const UserByToken = async (token) => {
    if (token?.length > 10) {
      const response = await recoverUserInSession(token);
      const responseUser = response.data.user;
      if (response.data.status === true) {
        setUser({
          Name: responseUser.name,
          id: responseUser._id,
          status: responseUser.active,
          Rol: responseUser.role,
          email: responseUser.email,
          account: responseUser.account,
          exp: responseUser.exp,
          iat: responseUser.iat,
        });
      } else {
        history.push("/");
      }
    } else {
      history.push("/");
    }
  };
  const loginFunction = async () => {
    setLoading(true);
    if (formSigmIn.email === "" || formSigmIn.password === "") {
      Error("Todos los campos son obligatorios")
      setmsgResponse("Todos los campos son obligatorios");
      setarrivalClass("fail");
      setIsToastUse(true);
      setLoading(false);
      return;
    }

    try {
      const response = await login(formSigmIn);
      sessionStorage.setItem("hatoToken", `${response.data.Token}`);
      response.data.User.Rol = response.data.User.role;
      setUserInSession(response.data.User);
      setUser(response.data.User);
      setLoading(false);
      window.location = "/Rancho";
    } catch (error) {
      if (error.response !== undefined) {
        if (error.response.status === 401) {
          Error("Verifica tus credenciales")
          setmsgResponse("Verifica tus credenciales");
          setarrivalClass("fail");
          setIsToastUse(true);
          setLoading(false);
        } else {
          Error("Algo salío mal, por favor intente más tarde!")
          setmsgResponse("Algo salío mal, por favor intente más tarde!");
          setarrivalClass("fail");
          setIsToastUse(true);
          setLoading(false);
        }
      }
    }
  };

  const signUpFunction = async () => {
    if (
      formSingnUp.email === "" ||
      formSingnUp.password === "" ||
      formSingnUp.confirmPassword === "" ||
      formSingnUp.Name === ""
    ) {
      Error("Todos los campos son obligatorios")
      setmsgResponse("Todos los campos son obligatorios");
      setarrivalClass("fail");
      setIsToastUse(true);
      setTimeout(() => {
        setIsToastUse(false);
        setIsLoginForm(false);
        setIsRecover(false);
      }, 2000);

      return;
    } else {
      formSingnUp.name = formSingnUp.Nombre;

      const data = {
        name: formSingnUp.Name,
        email: formSingnUp.email,
        password: formSingnUp.password,
      };
      const response = await signup(data);

      if (
        response.data.status === false &&
        response.data.msg === "Cuenta Existente"
      ) {
        Error(response.data.msg)
        setmsgResponse(response.data.msg);
        setarrivalClass("warning");
        setIsToastUse(true);
        setTimeout(() => {
          setIsToastUse(false);
          setIsLoginForm(true);
          setIsRecover(false);
        }, 2000);
      }

      if (
        response.data.status === false &&
        response.data.msg === "Error data!"
      ) {
        Error(response.data.msg)
        setmsgResponse(response.data.msg);
        setarrivalClass("fail");
        setIsToastUse(true);
        setTimeout(() => {
          setIsToastUse(false);
          setIsLoginForm(false);
          setIsRecover(false);
        }, 2000);
      }

      if (response.data.status === true) {
        Success(response.data.msg)
        setmsgResponse(response.data.msg);
        setarrivalClass("good");
        setIsToastUse(true);
        setTimeout(() => {
          setIsToastUse(false);
          setIsLoginForm(true);
          setIsRecover(false);
        }, 2000);
      }

      cleanRecover();
      cleanSignIn();
    }
  };

  const recoverFunction = async () => {
    const response = await recover(formRecover);
    cleanRecover();
    cleanSignIn();
  };

  const sendDataAuth = (formName) => {
    formName === "login"
      ? loginFunction()
      : formName === "signup"
      ? signUpFunction()
      : recoverFunction();
  };

  const getDataInputsAuth = (name, value) => {
    isRecover
      ? setformRecover({
          ...formRecover,
          [name]: value,
        })
      : isLoginForm
      ? setFormSigmIn({
          ...formSigmIn,
          [name]: value,
        })
      : setFormSingnUp({
          ...formSingnUp,
          [name]: value,
        });
  };

  useEffect(() => {}, [
    formSingnUp,
    formRecover,
    formSigmIn,
    isLoginForm,
    isRecover,
    IsToastUse,
    user,
  ]);

  const cleanUser = () => {
    setUser({
      Name: "",
      status: false,
      id: null,
      acces: [],
    });
  };
  const state = {
    user,
    setUser,
    formSigmIn,
    setFormSigmIn,
    formSingnUp,
    setFormSingnUp,
    sendDataAuth,
    formRecover,
    setformRecover,
    cleanSignIn,
    cleanSignUp,
    cleanRecover,
    getDataInputsAuth,
    setIsLoginForm,
    isRecover,
    setIsRecover,
    statusResponse,
    msgResponse,
    arrivalClass,
    IsToastUse,
    isLoginForm,
    isRecover,
    cleanUser,
    setIsToastUse,
    loading,
  };
  useEffect(() => {
    if (location.pathname.includes("mail")) {
      return;
    } else {
      UserByToken(sessionStorage.getItem("hatoToken"));
    }
  }, []);

  return <MainContext.Provider value={state}>{children}</MainContext.Provider>;
};

MainContext.propTypes = {
  children: node,
};

MainContext.defaultProps = {
  children: null,
};

export default withRouter(MainProvider);
