import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { logo } from "../Components/constants";
import { setPasswordNewUser } from "../servicces/AtuhServices";
import "./../Css-pages/Mails.css";

const Mails = ({ match }) => {
  const history = useHistory();
  const [password, setPassword] = useState({
    id: match.params.id,
    password: "",
  });
  const [ErrorPassword, setErrorPassword] = useState(false);
  const [msgPasswordError, setMsgPasswordError] = useState("");
  const [recoverSecction, setRecoverSecction] = useState(false);

  return (
    <>
      <div className="containerConfirmUser">
        {!recoverSecction && (
          <div className="userConfirM">
            <div className="setPass">
              <div className="contentControls">
                <img src={logo} alt="logo" />
                <h3 className="ad">
                  Por favor, introduce una contraseña para tu usuario
                </h3>
                <input
                  type="password"
                  name="password"
                  placeholder="nueva contraseña"
                  onChange={(e) => {
                    setPassword({
                      ...password,
                      [e.target.name]: e.target.value,
                    });
                    if (ErrorPassword) {
                      if (password.password.length < 6) setErrorPassword(false);
                    } else {
                      setErrorPassword(false);
                    }
                  }}
                />
                {ErrorPassword && <p className="error">{msgPasswordError}</p>}
                <button
                  type="button"
                  className="buttonConfirmUser"
                  onClick={async () => {
                    if (password.password.length < 6) {
                      setMsgPasswordError(
                        "La contraseña debe tener al menos 6 caracteres."
                      );
                      setErrorPassword(true);
                      return;
                    } else {
                      setErrorPassword(false);
                      setMsgPasswordError("");

                      const response = await setPasswordNewUser(password);
                      if (response.data.status) {
                        history.push("/");
                      }
                    }
                  }}
                >
                  Enviar información
                </button>
              </div>
            </div>
          </div>
        )}

        {recoverSecction && (
          <div className="recoverPass">
            <div className="recoverArea">
              <h4 className="warningPass">¿Has olvidado tu contraseña?</h4>
              <div className="contentMail">
                <p className="mailUser">mail</p>
                <input type="email" placeholder="Introduce tu corrreo" />
                <button className="recoverEmailBtn">
                  Restablecer contraseña
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Mails;
