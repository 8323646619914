import React, { useContext, useState, useEffect } from "react";
import { ListadoDeCriasContext } from "../../../../Context/GestacionCriasStore/context";
import "./styles.css";
import { getPeriods } from "../../../../servicces";
import { useLocation } from "react-router-dom";
const PeriodSelector = () => {
  const { pathname } = useLocation();
  const splitPathname = pathname.split("/");
  const ranchID = splitPathname[2];
  const criasCtx = useContext(ListadoDeCriasContext);
  const {
    getInitialData,
    setPage,
    breedFilter,
    setLoading,
    statusFilter,
    inputSearch,
    sexFilter,
    periodFilter,
    setPeriodFilter,
  } = criasCtx;
  const [periodList, setPeriods] = useState([]);
  useEffect(() => {
    const getSelectData = async () => {
      try {
        const result = await getPeriods();
        setPeriods(
          result.data.periods.filter((item) => {
            if (item.value !== "all" && item.value !== null) {
              return item;
            }
          })
        );
      } catch (error) {
        console.log(error);
      }
    };
    getSelectData();
  }, []);

  const handleChange = (e) => {
    const config = {
      page: 1,
      limit: 10,
      id: ranchID,
      search_text: inputSearch,
      sex: sexFilter,
      breed: breedFilter,
      status: statusFilter,
      period:e.target.value
    };
    setPeriodFilter(e.target.value);
    setLoading(true);
    setPage(1);
    getInitialData(config);
  };

  return (
    <div className="mainContainerFiltersDetails">
      <div className="inpurSearchBox colorSelect">
        <select
          defaultValue={periodFilter}
          name="periodSelector"
          className="SelecteSexoDetails"
          onChange={handleChange}
        >
          <option value="">Periodo</option>
          {periodList.map((option) => {
            return (
              <option key={option._id} value={option.value}>
                {option.label}
              </option>
            );
          })}
        </select>
      </div>
    </div>
  );
};
export default PeriodSelector;
