import { AUTHSERVICE } from '../config';

export const login = async (data) => {
  return await AUTHSERVICE.post('/auth/login', data);
};

export const signup = async (data) => {
  return await AUTHSERVICE.post('/auth/signup', data);
};

export const recover = async (data) => {
  return await AUTHSERVICE.post('password/sendEmail', data);
};

export const recoverUserInSession = async (token) => {
  return await AUTHSERVICE.get('/auth/recoverUserSession');
};

export const setPasswordNewUser = async (data) => {
  return await AUTHSERVICE.put('/users/setPasswordNewUser', data);
};
