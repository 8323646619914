const statusOptions = [
  {
    value: "cargada",
    label: "Cargada",
  },
  {
    value: "vacia",
    label: "Vacía",
  },
  {
    value: "desecho",
    label: "Desecho",
  },
  {
    value: "cria",
    label: "Cría",
  },
  {
    value: "desarrollo",
    label: "Desarrollo",
  },
  {
    value: "semental",
    label: "Semental",
  },
  {
    value: "vendido",
    label: "Vendido",
  },
  {
    value: "muerto",
    label: "Muerto",
  },
];
export {statusOptions}