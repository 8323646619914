const Cabeza = {
  sex: "",
  status: "",
  date: "",
  siniiga: "",
  siniigaM: "",
  rancherM: "",
  pedigreeM: "",
  ranchM: "",
  siniigaH: "",
  rancherH: "",
  pedigreeH: "",
  ranchH: "",
  breed: "",
  ranch: "",
  rancher: "",
};

const Historial = {
  palpated: "",
  reproduction: "",
  confirmation: "",
  observations:"",
  date: "",
  semen:'',
  siniigaM:'',
  status: "",
  livestockId: ""
};

export default {
  Cabeza,
  Historial,
};

/* Siniiga:'',
        Rancho:'',
        Raza:'',
        Ganadero:'',
        Sexo:'',
        Status:'',
        Fecha:'',
        Ciclo:'',
        Observaciones:'',
        SiniigaM:'',
        RanchoM:'',
        GanaderoM:'',
        PedigreeM:'',
        SiniigaH:'',
        RanchoH:'',
        GanaderoH:'',
        PedigreeH:'', */
