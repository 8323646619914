import {
    getCalves,
    createCalves,
    getGestacionesList,
    getBirthListService,
    getUrlFromPalpations,
    sendNewCabezaService,
    submitNewBirthService,
    createGestationService,
    sendNewGestationWithoutPalpation,
} from '../../servicces';
import Store from './store';
import { toast } from 'react-toastify';
import { withRouter, useLocation } from 'react-router-dom';
import React, { createContext, useEffect, useState } from 'react';

export const GestacionContext = createContext(Store);

const GestacionProvider = ({ children }) => {
    const success = (text) => toast.success(text, { autoClose: 3000 });
    const Error = (text) => toast.error(text, { autoClose: 3000 });
    const { pathname } = useLocation();
    const splitPathname = pathname.split('/');
    const cowID = splitPathname[2];
    const [info, setInfo] = useState([]);
    const [newPalpationModal, setNewPalpationModal] = useState(false);
    const [Cabeza, setCabeza] = useState(Store.Cabeza);
    const [Cabezas, setCabezas] = useState([]);
    const [CabezasRow, setCabezasRow] = useState([]);
    const [isOpenModalDetails, setisOpenModalDetails] = useState(false);
    const [urlExcel, seturlExcel] = useState('');

    //NewGestation
    const [dataGestation, setDataGestation] = useState(Store.Gestacion);
    const [openModalCreateGestation, setOpenModalCreateGestation] = useState(false);

    const [OpenModalNewGestation, setOpenModalNewGestation] = useState(false);
    const [liveStockForNewGestation, setliveStockForNewGestation] = useState('');
    const [newGestation, setnewGestation] = useState({
        date: '',
        reproduction: '',
        semen: '',
        siniigaM: '',
        dueDate: '',
        status: 'exitoso',
    });
    //end

    const [dataFromID, setdataFromID] = useState([]);
    const [listGanado, setListGanado] = useState([]);
    const [loading, setLoading] = useState(false);
    const [paginationInfo, setpaginationInfo] = useState();
    const [inputSearch, setinputSearch] = useState('');
    const [breedFilter, setBreedFilter] = useState('');
    const [ranchFilter, setRanchFilter] = useState('');
    const [sexFilter, setsexFilter] = useState('H');
    const [statusFilter, setstatusFilter] = useState('');
    const [submitErrors, setsubmitErrors] = useState('');
    const [page, setPage] = useState(1);
    const [GestationInfoModal, setGestationInfoModal] = useState(false);
    const [openNewBreedModal, setOpenNewBreedModal] = useState(false);
    const configData = {
        page: 1,
        limit: 10,
        // id: cowID,
        sex: 'H',
        breed: '',
        status: '',
        search_text: '',
        ranch: '',
    };
    const configBirthList = {
        page: 1,
        limit: 10,
        livestock: cowID,
    };

    const getInitialData = async ({ page, limit, breed, status, search_text, ranch }) => {
        setLoading(true);
        try {
            console.log(page, limit, breed, status, search_text, ranch);
            
            const response = await getGestacionesList({
                page,
                limit,
                status,
                breed,
                search_text,
                ranch,
            });
            console.log(response);
            setInfo(response.data.info);
            setpaginationInfo(response.data.data);
            setListGanado(response.data.data.livestocks);
            setLoading(false);
        } catch (error) {
            console.log('error context gestacion', error.response);
        }
    };

    const [isOpenModalGanado, setisOpenModalGanado] = useState(false);

    const [historialInfo, setHistorialInfo] = useState();
    const [livestockMotherInfo, setLivestockMotherInfo] = useState();
    const [birthInfoModal, setbirthInfoModal] = useState();

    const [historial, setHistorial] = useState([]);
    const [newHistorialValues, setNewHistorialValues] = useState(Store.Parto);
    const [historialErros, setHistorialErros] = useState('');

    const getBirthList = async ({ page, limit, livestock }) => {
        setLoading(true);
        try {
            const response = await getBirthListService({
                page,
                limit,
                livestock,
            });
            setHistorialInfo(response.data.data);
            setHistorial(response.data.data.labors);
            setLivestockMotherInfo(response.data.livestock);
            setLoading(false);
        } catch (error) {
            console.log('error context ranch', error.response);
        }
    };
    //Agregar ganado despues de finalizar gestacion
    const submitNewCowGestation = async () => {
        setsubmitErrors('');
        try {
          const response = await sendNewCabezaService(Cabeza);
          if (response.data.status) {
            getInitialData(configData);
            getDownloadUrl();
            setisOpenModalGanado(false);
            success("Se ha añadido una nueva Cría");
          }
        } catch (error) {
          setsubmitErrors(error.response.data.msg);
          console.log(error.response);
          console.log(Cabeza)
        }
      };

    const submitNewBirth = async () => {
        setHistorialErros('');
        //console.log(newHistorialValues)
        try {
            if(newHistorialValues.dueDate==="")
            {
                Error("La fecha de parto es obligatoria");
            }else{

                 const response = await submitNewBirthService(
                    newHistorialValues,
                    newHistorialValues.livestockId
                 );

           if (response.data.status) {
                //getInitialData(configData);
                // getDownloadUrl()
                setGestationInfoModal(false);
                setisOpenModalGanado(true);
            }
            }
            
        } catch (error) {
            setHistorialErros(error.response.data.msg);
            console.log(error.response);
        }
    };

    const submitCreateGestation = async (livestockId) => {
        setHistorialErros('');

        try {
            const response = await createGestationService(dataGestation, livestockId);

            if (response.data.status) {
                getInitialData(configData);
                setOpenModalCreateGestation(false);
                success('Se ha creado nueva gestación');
                setDataGestation(Store.Gestacion);
            }
        } catch (error) {
            setHistorialErros(error.response.data.msg);
            console.log(error.response);
        }
    };

    const handleCowFormInputs = ({ name, value }) => {
        setCabeza((prev) => {
            return { ...prev, [name]: value };
        });
    };

    const submitNewCow = async () => {
        setsubmitErrors('');
        try {
            const response = await sendNewCabezaService(Cabeza);
            if (response.data.status) {
                getBirthList(configBirthList);
                setOpenNewBreedModal(false);
                success('Se ha agregado una nueva cria');
            }
        } catch (error) {
            setsubmitErrors(error.response.data.msg);
            console.log(error.response);
        }
    };

    const submitNewCalves = async () => {
        setsubmitErrors('');
        try {
            const response = await createCalves(Cabeza, liveStockForNewGestation);

            if (response.data.status) {
                getBirthList(configBirthList);
                setOpenNewBreedModal(false);
                success('Se ha agregado una nueva cría');
            }
        } catch (error) {
            setsubmitErrors(error.response.data.msg);
            console.log(error.response);
        }
    };

    const submitNewgestation = async () => {
        setsubmitErrors('');
        try {  
                const response = await sendNewGestationWithoutPalpation(
                    newGestation,
                    liveStockForNewGestation
                );
                if (response.data.status) {
                    getBirthList(configBirthList);
                    setOpenNewBreedModal(false);
                    success('Se ha agregado una nueva cria');
                    setnewGestation({
                        date: '',
                        reproduction: '',
                        semen: '',
                        siniigaM: '',
                        dueDate: '',
                        status: 'exitoso',
                    });
                    setOpenModalNewGestation(false);
                
            }
        } catch (error) {
            Error('Debes rellenar todos los datos')
            setsubmitErrors(error.response.data.msg);
            console.log(error.response);
        }
    };

    const getCalvesList = async ({ page, limit, livestock }) => {
        setLoading(true);

        try {
            const response = await getCalves({
                page,
                limit,
                livestock,
            });
            setHistorialInfo(response.data.data);
            setHistorial(response.data.data.labors);
            setLivestockMotherInfo(response.data.livestock);
            setLoading(false);
        } catch (error) {
            console.log('error context ranch', error.response);
        }
    };

    const changeNewGestacionState = ({ target: { name, value } }) => {
        //console.log(name, value);
        setnewGestation({
            ...newGestation,
            [name]: value,
        });
    };

    const filterRaza = (e) => {
        if (e.target.value === '') getInitialData(configData);
        setListGanado({
            ...listGanado,
            livestocks: listGanado.livestocks.filter((el) => el.breed.name === e.target.value),
        });
    };
    const filterSexo = (sexo) => setCabeza(CabezasRow.filter((el) => el.Sexo === sexo));
    const filterSttatus = (status) => setCabeza(CabezasRow.filter((el) => el.Statuds === status));
    const filterRangoDeNacimiento = (fecha) =>
        setCabeza((el) => new Date(el.Fecha).getTime() > new Date(fecha));

    useEffect(() => {
        getInitialData(configData);
        if (cowID && pathname.includes('listado-de-crias')) getBirthList(configBirthList);
        if (cowID && pathname.includes('listado-de-partos')) getBirthList(configBirthList);
    }, [cowID]);

    const getDownloadUrl = async () => {
        try {
            const result = await getUrlFromPalpations(cowID);
            seturlExcel(result.data.filename);
        } catch (error) {
            console.log(error);
        }
    };
    const state = {
        isOpenModalGanado,
        setisOpenModalGanado,
        submitNewCowGestation,
        submitNewCalves,
        handleCowFormInputs,
        submitNewCow,
        setNewPalpationModal,
        filterRaza,
        filterSexo,
        filterSttatus,
        filterRangoDeNacimiento,
        submitErrors,
        newPalpationModal,
        Cabezas,
        info,
        isOpenModalDetails,
        dataFromID,
        getInitialData,
        listGanado,
        loading,
        setLoading,
        paginationInfo,
        setpaginationInfo,
        page,
        setPage,
        inputSearch,
        setinputSearch,
        breedFilter,
        setBreedFilter,
        sexFilter,
        setsexFilter,
        statusFilter,
        setstatusFilter,
        getBirthList,
        historialInfo,
        livestockMotherInfo,
        historial,
        newHistorialValues,
        setNewHistorialValues,
        submitNewBirth,
        historialErros,
        newPalpationModal,
        setNewPalpationModal,
        ranchFilter,
        setRanchFilter,
        urlExcel,
        getDownloadUrl,
        GestationInfoModal,
        setGestationInfoModal,
        openNewBreedModal,
        setOpenNewBreedModal,
        setCabeza,
        birthInfoModal,
        setbirthInfoModal,
        OpenModalNewGestation,
        setOpenModalNewGestation,
        changeNewGestacionState,
        Cabeza,
        liveStockForNewGestation,
        setliveStockForNewGestation,
        setnewGestation,
        newGestation,
        submitNewgestation,
        dataGestation,
        setDataGestation,
        openModalCreateGestation,
        setOpenModalCreateGestation,
        submitCreateGestation,
    };

    useEffect(() => {}, [info, isOpenModalDetails, dataFromID]);

    return <GestacionContext.Provider value={state}>{children}</GestacionContext.Provider>;
};

export default withRouter(GestacionProvider);
