import React, { useContext } from 'react';
import { LISTA_PARTOS_TITLES } from '../../../constants';
import './styles.css';
import { GestacionContext } from '../../../../Context/GestacionStore/context';
import translateDate from '../../../../utils/translateDate';
import NewBreedModal from './NewBreedModal';

const BirthsTable = ({ tablesInfo }) => {
  const GestacionCtx = useContext(GestacionContext);

  const { openNewBreedModal, setOpenNewBreedModal, setbirthInfoModal } = GestacionCtx;
  if (tablesInfo.length === 0)
    return <div className="MainTablasContainer">"No se encontraron resultados"</div>;

  const renderTitle = LISTA_PARTOS_TITLES.map((el, i) => (
    <th key={`title-${i}`} className="eachCeld">
      {el}
    </th>
  ));

  const renderColumns = (item, rowKey) => {
    return Object.keys(item).map((res, key) => {
      let Style = { minWidth: '1rem' };
      if (res === 'livestock' || res === 'account' || res === 'in_progress') return null;
      if (res === '_id') {
        return (
          <th
            style={{
              ...Style,
              ...{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              },
            }}
            key={`${rowKey}-${res}-${key}`}
            className="thValues"
            onClick={() => {
              setbirthInfoModal(item);
              setOpenNewBreedModal(true);
            }}
          >
            <label
              style={{
                width: '25px',
                borderRadius: '50%',
                backgroundColor: 'rgba(215, 108, 28, 1)',
                color: 'white',
                fontWeight: '700',
                fontSize: '20px',
                cursor: 'pointer',
              }}
            >
              +
            </label>
          </th>
        );
      }

      if (res === 'palpation') {
        // console.log(item[res]);

        return (
          <>
            {/* <th style={Style} key={`${rowKey}-${res}-${key}`} className="thValues">
              {item[res]?.date ? translateDate(item[res]?.date) : '-'}
            </th> */}
            <th style={Style} key={`${rowKey}-${res}-${key}`} className="thValues">
              {item[res]?.reproduction ?? '-'}
            </th>
            <th style={Style} key={`${rowKey}-${res}-${key}`} className="thValues">
              {item[res]?.confirmation ?? '-'}
            </th>
            <th style={Style} key={`${rowKey}-${res}-${key}`} className="thValues">
              {item[res]?.palpated ?? '-'}
            </th>

            <th style={Style} key={`${rowKey}-${res}-${key}`} className="thValues">
              {item[res]?.semen ?? '-'}
            </th>
            <th style={Style} key={`${rowKey}-${res}-${key}`} className="thValues">
              {item[res]?.siniigam ?? '-'}
            </th>
            <th style={Style} key={`${rowKey}-${res}-${key}`} className="thValues">
              {item[res]?.observations ?? '-'}
            </th>
          </>
        );
      }

      if (res === 'dueDate' || res === 'reproductionDate') {
        return (
          <th style={Style} key={`${rowKey}-${res}-${key}`} className="thValues">
            {item[res] ? translateDate(item[res]) : '-'}
          </th>
        );
      }

      return (
        <th style={Style} key={`${rowKey}-${res}-${key}`} className="thValues">
          {item[res] ? item[res] : '-'}
        </th>
      );
    });
  };

  const renderRows = tablesInfo.map((res, key) => {
    return (
      <tr className="eachRow" key={`row_${key}`}>
        {renderColumns(res, key)}
      </tr>
    );
  });

  return (
    <div className="MainTablasContainer">
      <table className="tableElement">
        <tbody className="tableTbody">
          <tr className="tableTitles">
            {/* <th className="tableActions">ACCIONES</th> */}
            {renderTitle}
          </tr>
          {renderRows}
        </tbody>
      </table>

      {openNewBreedModal && (
        <NewBreedModal
          openCloseModal={() => {
            setOpenNewBreedModal(false);
          }}
        />
      )}
    </div>
  );
};

export default BirthsTable;
