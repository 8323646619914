import React, { useEffect, useState, useContext } from "react";
import "./styles.css";
import {
  getBreeds,
  getAllGanaderos,
  InitialDataPetition,
  getSelectActiveRanchs,
} from "../../../servicces";
import { DetailsContext } from "../../../Context/Details/context";
import { useLocation } from "react-router-dom";
import { sexoOptions, statusOptions, breedTypeOptions } from "../../constants";
import { GanaderosContext } from "../../../Context/GanaderosStore/context";
const NewGanadero = ({ form, handleCowFormInputs, heigth, submitErrors }) => {
  const ganaderosContext = useContext(GanaderosContext);
  const { newGanadero, isEditable } = ganaderosContext;
  const datailsCtx = useContext(DetailsContext);
  const { setCabeza } = datailsCtx;
  const { pathname } = useLocation();
  const splitPathname = pathname.split("/");
  const ranchID = splitPathname[2];
  const [selectors, setselectors] = useState(null);
  const [loading, setloading] = useState(true);

  useEffect(() => {
    if (ranchID)
      setCabeza((prev) => {
        return {
          ...prev,
          ranch: ranchID,
        };
      });
  }, [ranchID]);

  const getSelectors = async () => {
    try {
      const ranchsResult = await getSelectActiveRanchs({
        page: 1,
        limit: 100,
        search_text: "",
      });
      const ranchersResult = await getAllGanaderos();
      const breedResult = await getBreeds();
      const configSelectors = {
        ranch: ranchsResult.data.data.ranchs,
        breed: breedResult.data.data.breeds,
        rancher: ranchersResult.data.data.ranchers,
        sex: sexoOptions.map((res) => {
          return { name: res.label, _id: res.value };
        }),
        status: statusOptions.map((res) => {
          return { name: res.label, _id: res.value };
        }),
        type: breedTypeOptions.map((res) => {
          return { name: res.label, _id: res.value };
        }),
      };
      setselectors(configSelectors);
      setloading(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getSelectors();
  }, []);

  if (loading && !selectors)
    return <div className={form.mainClass}>...loading</div>;
  return (
    <div style={{marginBottom:'30px'}} className={form.mainClass}>
      <h1 className="titleGrupo">
        {isEditable ? "Editar Ganadero" : form.Maintitle}
      </h1>

      <div className="TabsGanaderos" style={{ height: heigth }}>
        {Array.from(Array(form.parts)).map((num, i) => {
          return (
            <div
              key={`tab${i + 1}`}
              className={`Tab tab${i + 1}`}
              title={`tab${i}`}
              style={{ opacity: `${i > 0 ? "0" : "1"}` }}
            >
              {form[`part${i + 1}`].map((el, i) => (
                <div className="eachInputForm" key={`eachInput-${i}`}>
                  <div className="contentLabel">
                    <img className="iconInput" src={el.icon} alt={el.name} />
                    <label className="labelInput">{el.title}</label>
                  </div>
                  {el.type === "text" || el.type === "date" ? (
                    <input
                      value={newGanadero[el.name] ? newGanadero[el.name] : ""}
                      className="inputText ganado"
                      type={el.type}
                      name={el.name}
                      onChange={(e) => handleCowFormInputs(e.target)}
                    />
                  ) : (
                    <select
                      className="create-cow-selector ganado"
                      name={el.name}
                      onChange={(e) => handleCowFormInputs(e.target)}
                      disabled={el.title==='Ranchos'? true:false}
                      defaultValue={
                        newGanadero[el.name] ? newGanadero[el.name] : el.title==='Ranchos' ? ranchID : ''
                      }
                    >
                      <option value="">{el.label}</option>
                      {selectors[el.name]?.map((opt, k) => (
                        <option key={`${el.label}-${k}`} value={`${opt._id}`}>
                          {opt.name}
                        </option>
                      ))}
                    </select>
                  )}
                </div>
              ))}
            </div>
          );
        })}
      </div>
      {submitErrors !== "" && (
        <label style={{ color: "red" }}>{submitErrors}</label>
      )}
    </div>
  );
};

export default NewGanadero;
