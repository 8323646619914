import Hato from "../layout/Hato_Theme";
import PieCriaComponent from "../Components/PieCria";
import PieProvider  from "../Context/PieCria/Context";



const PieCria = ({match}) => {

 
  return (
    <Hato>
      <PieProvider>
        <PieCriaComponent match={match} item={match.params} />
      </PieProvider>
    </Hato>
  );
};

export default PieCria;
