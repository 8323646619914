import React, { useContext, useState, useEffect } from "react";
import { PalpacionesContext } from "../../../../Context/PalpacionesStore/context";
import "./styles.css";
import { getBreeds } from "../../../../servicces";
import { useLocation } from "react-router-dom";
const BreedSelector = () => {
  const { pathname } = useLocation();
  const splitPathname = pathname.split("/");
  const ranchID = splitPathname[2];
  const PalpacionesCxt = useContext(PalpacionesContext);
  const { getInitialData,setPage, breedFilter, setBreedFilter,setLoading,statusFilter,inputSearch,sexFilter, ranchFilter } = PalpacionesCxt;
  const [breedList, setbreedList] = useState([]);
  useEffect(() => {
    const callBreeds = async () => {
      try {
        const result = await getBreeds();
        setbreedList(result.data.data.breeds);
      } catch (error) {
        console.log(error);
      }
    };
    callBreeds();
  }, []);
  
const handleChange = (e) =>{
  const config = {
    page: 1,
    limit: 5,
    id: ranchID,
    search_text: inputSearch,
    sex: sexFilter,
    breed: e.target.value,
    ranch:  ranchFilter,
    status: statusFilter,
  };
  setBreedFilter(e.target.value)
  setLoading(true);
  setPage(1);
  getInitialData(config);
}

  return (
    <div className="mainContainerFiltersDetails">
      <div className="inpurSearchBox colorSelect">
        <select
          defaultValue={breedFilter}
          name="SexoDetailsSelect"
          className="SelecteSexoDetails"
          onChange={handleChange}
        >
          <option value="">Raza</option>
          {breedList.map((option) => {
            return (
              <option key={option._id} value={option._id}>
                {  `${option.name } - ${option.description} ` }
              </option>
            );
          })}
        </select>
      </div>
    </div>
  );
};
export default BreedSelector;
