import React, { useContext, useEffect } from 'react';
import { GestacionContext } from '../../../Context/GestacionStore/context';
import BirthsTable from './BirthsTable';
import CircularProgress from '@mui/material/CircularProgress';
import { add } from '../../constants';
import NewModalContainer from '../../NewModalContainer';

import './styles.css';
import Pagination from '@mui/material/Pagination';
import { useLocation } from 'react-router-dom';
import DownloadFileButton from '../../DownloadFileButton/DownloadFileButton';
const ListadoDePartos = ({ item, match }) => {
    const { pathname } = useLocation();
    const splitPathname = pathname.split('/');
    const cowID = splitPathname[2];
    const GestacionCtx = useContext(GestacionContext);
    const {
        setNewPalpationModal,
        historialInfo,
        listGanado,
        loading,
        setLoading,
        page,
        setPage,
        historial,
        getBirthList,
        urlExcel,
        OpenModalNewGestation,
        setOpenModalNewGestation,
        changeNewGestacionState,
        msgResponse,
        getDownloadUrl,
        setliveStockForNewGestation,
        submitNewgestation,
        setnewGestation,
    } = GestacionCtx;
    

    console.log(historial);

    useEffect(() => {
        setliveStockForNewGestation(cowID);
        getDownloadUrl();
    }, [cowID]);

    const getId = (e) => {};
    const toggleModalVisibitily = () => {
        setNewPalpationModal((prev) => !prev);
    };

    const closeModalGestation = () => {
        setOpenModalNewGestation(false);
        setnewGestation({
            date: '',
            reproduction: '',
            semen: '',
            siniigaM: '',
            dueDate: '',
            status: '',
        });
    };

    const openModalGestation = () => {
        setOpenModalNewGestation(true);
    };

    const submitNewGestationWithoutPalpation = () => {
            submitNewgestation();
    };

    const handleChangePage = (_, newPage) => {
        setLoading(true);
        setPage(newPage);
        const config = {
            page: newPage,
            limit: 10,
            livestock: cowID,
        };
        getBirthList(config);
    };

    return (
        <div className="MainDetailsComponentContainer">
            {/* modal */}

            {OpenModalNewGestation && (
                <NewModalContainer
                    ActiveForm={OpenModalNewGestation}
                    openCloseModal={() => {
                        closeModalGestation();
                    }}
                    submit={() => {
                        submitNewGestationWithoutPalpation();
                    }}
                >
                    <div>
                        <h1>Crear parto</h1>
                        {/* gestation date */}
                        <div>
                            <di className="contentLabel">
                                <label className="labelInput">Fecha de Gestación</label>
                            </di>
                            <input
                                className="inputText"
                                type={'date'}
                                onChange={changeNewGestacionState}
                                name={'date'}
                            />
                        </div>
                        {/* gestation date */}

                        {/* reproduction method */}
                        <div>
                            <di className="contentLabel">
                                <label className="labelInput">Metodo de Reproducción</label>
                            </di>
                            <input
                                className="inputText"
                                onChange={changeNewGestacionState}
                                name={'reproduction'}
                            />
                        </div>
                        {/* reproduction method */}

                        {/* Semen */}
                        <div>
                            <di className="contentLabel">
                                <label className="labelInput">Semen Toro</label>
                            </di>
                            <input
                                className="inputText"
                                onChange={changeNewGestacionState}
                                name={'semen'}
                            />
                        </div>
                        {/* Semen */}

                        {/* Sigma */}
                        <div>
                            <di className="contentLabel">
                                <label className="labelInput">Siniiga Macho</label>
                            </di>
                            <input
                                className="inputText"
                                onChange={changeNewGestacionState}
                                name={'siniigaM'}
                            />
                        </div>
                        {/* Sigma */}

                        {/* birthDate date */}
                        <div>
                            <di className="contentLabel">
                                <label className="labelInput">Fecha de Parto</label>
                            </di>
                            <input
                                className="inputText"
                                type={'date'}
                                onChange={changeNewGestacionState}
                                name={'dueDate'}
                            />
                        </div>
                        {/* birthDate date */}

                        {/* status */}
                        <div>
                            <di className="contentLabel">
                                <label className="labelInput">Estado</label>
                            </di>
                            <select
                                className="inputText"
                                onChange={changeNewGestacionState}
                                name={'status'}
                            >
                                <option value={'exitoso'}>Exitoso</option>
                                <option value={'aborto'}>Aborto</option>
                            </select>
                        </div>
                        {/* status */}
                    </div>
                    {msgResponse && (
                        <label style={{ margin: '15px 0', color: 'red' }}>{msgResponse}</label>
                    )}
                </NewModalContainer>
            )}

            {/* modal */}

            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    padding: '0 30px',
                }}
            >
                <h1 className="misRanchos">Listado de Partos</h1>
                <DownloadFileButton id={cowID}>Descargar Partos</DownloadFileButton>
            </div>
            {loading ? (
                <div className="loading">
                    <CircularProgress color="warning" size={100} />
                </div>
            ) : (
                <>
                    {listGanado.length > 0 && (
                        <BirthsTable
                            getIdGanadero={getId}
                            tablesInfo={historial}
                            setActiveRanch={() => {}}
                            redirect={true}
                            match={match}
                        />
                    )}
                </>
            )}
            <div className="addganaderos">
                <button
                    type="button"
                    className="ActiveForm"
                    onClick={() => {
                        openModalGestation();
                    }}
                >
                    <img src={add} alt="add" />
                </button>
            </div>
            <div className="ganaderos-pagination">
                <Pagination
                    count={historialInfo?.totalPages}
                    page={page}
                    onChange={handleChangePage}
                    shape="rounded"
                />
                <label style={{ marginTop: '7px' }}>
                    Partos en total : {historialInfo?.totalLabors}
                </label>
            </div>
        </div>
    );
};

export default ListadoDePartos;
