import React, { useContext, useEffect, memo } from "react";
import { find } from "../../../constants";
import { GestacionContext } from "../../../../Context/GestacionStore/context";
import "./styles.css";
import debounce from "lodash.debounce";
import { useLocation } from "react-router-dom";

const InputSearch = memo(() => {
  const { pathname } = useLocation();
  const splitPathname = pathname.split("/");
  const ranchID = splitPathname[2];

  const GestacionCtx = useContext(GestacionContext);
  const {
    getInitialData,
    setLoading,
    setinputSearch,
    setPage,
    sexFilter,
    breedFilter,
    inputSearch,
    statusFilter,
    ranchFilter,
  } = GestacionCtx;

  const searchFunction = async (config) => {
    setLoading(true);
    setPage(1);
    getInitialData(config);
  };
  const debouncedSearch = React.useRef(
    debounce(async (config) => {
      await searchFunction(config);
    }, 300)
  ).current;
  async function handleChange(e) {
    setinputSearch(e.target.value);
    const config = {
      page: 1,
      limit: 5,
      id: ranchID,
      search_text: e.target.value,
      sex: sexFilter,
      breed: breedFilter,
      status: statusFilter,
      ranch: ranchFilter,
    };
    debouncedSearch(config);
  }
  useEffect(() => {
    return () => {
      debouncedSearch.cancel();
    };
  }, [debouncedSearch]);
  return (
    <div className="inpurSearchBoxGanaderos">
      <img className="findIcon" src={find} alt="Find" />
      <input
      style={{width:'250px'}}
        className="findInput"
        type="number"
        value={inputSearch}
        name="searchBox"
        placeholder="Buscar por SINIIGA"
        onChange={handleChange}
      />
    </div>
  );
});
export default InputSearch;
