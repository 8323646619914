export default {
  searchBarInput:'',
  rosw:[],
  newGanadero:{
    name: '',
    upp: '',
    nickname: '',
    ranch: '',
    _id:''
  },
  GanaderosID:''
}