import React, { useContext } from "react";
import { admin_route, dose, siniga, vaccineColor } from "../../constants";
import { VacunasListContext } from "../../../Context/VaccineListStore/context";
import Alert from '@mui/material/Alert';

const Vacuna = () => {
  const vacunasCtx = useContext(VacunasListContext);
  const { setNewVacunas, errorVaccineCreate,createVaccineAlert } = vacunasCtx;
  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setNewVacunas((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  return (
    <div className=" addVacuna ">
    {/*   {createVaccineAlert && <Alert severity="success">vacuna creada</Alert>} */}
      <h1>Agregar Vacuna</h1>

      <div className="eachInputVacuna">
        <div className="labelsVacuna">
          <img src={vaccineColor} alt="nueva Vacuna" />

          <label> Nombre de la vacuna * </label>
        </div>

        <input type="text" name="name" onChange={handleChange} />
      </div>

      <div className="eachInputVacuna">
        <div className="labelsVacuna">
          <img src={admin_route} alt="nueva Vacuna" />

          <label> Vía de administracion</label>
        </div>
        <select
          className="eachInputVacuna-select"
          name="via"
          onChange={handleChange}
        >
          <option value="">Seleccione </option>
          <option value="oral">Oral</option>
          <option value="intramuscular">Intramuscular</option>
          <option value="subcutanea">Subcutanea</option>
          <option value="intravenosa">Intravenosa</option>
        </select>
      </div>

      <div className="eachInputVacuna">
        <div className="labelsVacuna">
          <img src={dose} alt="nueva Vacuna" />
          <label> Dosis </label>
        </div>

        <input type="text" name="dose" onChange={handleChange} />
      </div>

      <div className="eachInputVacuna">
        <div className="labelsVacuna">
          <img src={siniga} alt="nueva Vacuna" />

          <label> x Kg Peso </label>
        </div>

        <input type="text" name="kg" onChange={handleChange} />
      </div>
      {errorVaccineCreate !== "" && <label style={{color:'red'}}>{errorVaccineCreate}</label>}
    </div>
  );
};

export default Vacuna;
