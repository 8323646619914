import React, { memo, useContext } from "react";
import { ConfiguracionContext } from "../../../Context/ConfiguracionStore/context";
import UserTable from "./UserTable/UserTable";
import { ToastContainer } from "react-toastify";

const Usuarios = memo(() => {
  const configCtx = useContext(ConfiguracionContext);
  const { Usuario, setUserValues, RolesArr, sendNewUsuarioGanadero } =
    configCtx;

  const validateForm = () => {
    let valid = false;
    if (RolesArr.length === 0) valid = true;
    if (Usuario.name === "") valid = true;
    if (Usuario.email === "") valid = true;
    if (Usuario.permission === "") valid = true;
    return valid;
  };

  return (
    <div className="tabsConfigZone">
      <div className="inputsRoles">
        <div className="inputs">
          <input
            type="text"
            className="inputUsuarios InputsConfig"
            name="email"
            placeholder="Correo"
            value={Usuario.email}
            onChange={setUserValues}
          />
          <input
            type="text"
            className="inputUsuarios InputsConfig"
            name="name"
            placeholder="Nombre"
            value={Usuario.name}
            onChange={setUserValues}
          />
          <select
            name="permission"
            className="inputUsuarios InputsConfig"
            onChange={setUserValues}
            value={Usuario.permission}
          >
            <option value="">Rol</option>
            {RolesArr.map((el, i) => (
              <option key={`${i}-${el.name}`} value={el._id}>
                {el.name}
              </option>
            ))}
          </select>
          <button
            disabled={validateForm()}
            type="button"
            className="buttonsSend"
            name="newUsuario"
            onClick={sendNewUsuarioGanadero}
          >
            {" "}
            +{" "}
          </button>
        </div>
      </div>
      <ToastContainer />
      <UserTable />
    </div>
  );
});

export default Usuarios;
