

export default {
  searchBarInput: '',
  cards: [],
  newRanch: {
    inCharge: '',
    name: '',
    purpose: '',
    dimension: '',
    country: 'mexico',
    city: '',
    coordinates: {
      x: -89.62,
      y: 20.96
    },
    state: ''
  },
  RanchID: {},
  paises: [],
  estados: [],
  ciudades: []
}