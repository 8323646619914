import React from 'react';
import './styles.css'

const MainModal = ({children}) => {
    return (
        <>
            <div className="MainModalContainer">
                { children }
            </div>
        </>
    )
}

export default MainModal
