import React, { useContext } from "react";
import "./styles.css";
import { VacunasContext } from "../../../Context/VacunasStore/context";
import Filtros from "./Filtros";
import CartillaDeVacunacion from "./CartillaDeVacunacion/CartillaDeVacunacion";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
const VacunasComponent = () => {
  const history = useHistory();
  const vacunasCtx = useContext(VacunasContext);
  const { livestock } = vacunasCtx;

  const renderCartilla = () => {
    if (typeof livestock === "string")
      return <h1 className="titleGrupo">{livestock}</h1>;
    if (Array.isArray(livestock)) {
      if (livestock.length === 0)
        return <h1 className="titleGrupo">No se encontraron resultados</h1>;
      return <CartillaDeVacunacion />;
    }
    return null;
  };

  return (
    <div className="mainVacunasContainer">
      <div className="TabsVacunasContainer">
        <div className="TabsVacunas_btns">
          <button className="btn_CartillaTab" name="cartilla" type="button">
            Cartilla de vacunación
          </button>
          <button
            className="btn_CartillaTab"
            name="listadoDeVacunas"
            type="button"
            onClick={(_) => history.push("/Vacunas/listado")}
          >
            Grupo de vacunas
          </button>
        </div>
      </div>

      <Filtros />
      {renderCartilla()}
    </div>
  );
};

export default VacunasComponent;
