import { useState, useContext } from "react";
import Navbar from "../../Components/NavBar";
import Banner from "../../Components/Banner";
import "./mainthemeStyles.css";
import FloatMenu from "../../Components/Auxiliars/Flotant-Menu";
import { MainContext } from "../../MainContext";

const Hato = ({ children }) => {
  const mainCtx = useContext(MainContext);
  const { user } = mainCtx;

  const [isActiveMenu, setIsActiveMenu] = useState(false);

  const openCloseFloatMenu = (e) => {
    setIsActiveMenu(!isActiveMenu);
  };

  return (
    <div className="theme-Container">
      <div className="persistsModules">
        <div className="banner-Container">
          <Banner
            opencloseFunction={(e) => openCloseFloatMenu(e)}
            userInSession={user.Name ? user.Name : user.name}
          />
        </div>

        <div className="body-Content">
          <div className="navbar-Container">
            <Navbar currentUrl={window.location.pathname} />
          </div>

          <div className="sections-Container">{children || ""}</div>
        </div>
      </div>
      {isActiveMenu && (
        <FloatMenu
          openCloseFunction={() => openCloseFloatMenu()}
          isOwner={user.Rol === "owner" || user.Rol === "admin" || user.Rol === "superadmin"}
        />
      )}
    </div>
  );
};

export default Hato;
