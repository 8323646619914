import React, { useContext, useEffect } from 'react';
import { ListadoDeCriasContext } from '../../../Context/GestacionCriasStore/context';
import { Ganado, add } from '../../constants';
import NewGanado from '../../Forms/NewGanado';
import Modal from '../../ModalContainer';
import Tabla from './Tables';
import CircularProgress from '@mui/material/CircularProgress';
import ModalDetails from './ModalDetails';
import InputSearch from './InputSearch/InputSearch';
import BreedSelector from './BreedSelector/BreedSelector';
import SexSelector from './SexSelector/SexSelector';
import StatusSelector from './StatusSelector/StatusSelector';
import PeriodSelector from './PeriodSelector/PeriodSelector';
import Pagination from '@mui/material/Pagination';
import { useLocation } from 'react-router-dom';
import DownloadFileButton from '../../DownloadFileButton/DownloadFileButton';
import './styles.css';

const DetailsComponent = ({ item, match }) => {
  const { pathname } = useLocation();
  const splitPathname = pathname.split('/');
  const ranchID = splitPathname[2];
  const BreedCtx = useContext(ListadoDeCriasContext);
  const {
    info,
    handleCowFormInputs,
    submitNewCow,
    isOpenModalGanado,
    setisOpenModalGanado,
    isOpenModalDetails,
    openModalDetails,
    dataFromID,
    getInitialData,
    listGanado,
    loading,
    setLoading,
    paginationInfo,
    page,
    setPage,
    periodFilter,
    submitErrors,
  } = BreedCtx;

  const configGetPages = {
    page: 1,
    limit: 10,
    id: ranchID,
    period: periodFilter,
  };

  const getId = (e) => {
    openModalDetails(true, e);
  };
  const handleChangePage = (_, newPage) => {
    setLoading(true);
    setPage(newPage);
    const config = {
      page: newPage,
      limit: 10,
      id: ranchID,
      period: periodFilter,
    };
    getInitialData(config);
  };

  return (
    <div className="MainDetailsComponentContainer">
      <h1 className="misRanchos">Listado de Crias</h1>
      <div className="ranch-filters-row">
        <div className="mainContainerFiltersDetails">
          <div className="col">
            {/* <InputSearch />
            <BreedSelector />
            <SexSelector />
            <StatusSelector /> */}
            <PeriodSelector />
          </div>
          {/* <div className="col small">
            <DownloadFileButton href={urlExcel}>
              Descargar tabla
            </DownloadFileButton>
          </div> */}
        </div>
      </div>

      {loading ? (
        <div className="loading">
          <CircularProgress color="warning" size={100} />
        </div>
      ) : (
        <>
          {listGanado && (
            <>
              <Tabla
                getIdGanadero={getId}
                tablesInfo={listGanado}
                setActiveRanch={() => {}}
                redirect={true}
                match={match}
              />
            </>
          )}
          {/*  <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={listGanado?.totalLivestocks}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          /> */}
        </>
      )}
      <div className="ganaderos-pagination">
        <Pagination
          count={paginationInfo?.totalPages}
          page={page}
          onChange={handleChangePage}
          shape="rounded"
        />
        <label style={{ marginTop: '7px' }}>Ganado en total : {paginationInfo?.totalCalves}</label>
      </div>

      {isOpenModalGanado && (
        <Modal
          ActiveForm={isOpenModalGanado}
          openCloseModal={() => {
            setisOpenModalGanado(false);
          }}
          submitNewItem={submitNewCow}
        >
          <NewGanado
            form={Ganado}
            handleCowFormInputs={handleCowFormInputs}
            heigth={'600px'}
            submitErrors={submitErrors}
          />
        </Modal>
      )}

      {isOpenModalDetails && dataFromID && (
        <ModalDetails
          closeFunction={() => {
            openModalDetails(false);
          }}
          data={dataFromID}
          item={item}
          getInitialData={getInitialData}
          getId={(id) => {
            getId(id);
          }}
          info={info}
          configGetPages={configGetPages}
        />
      )}
    </div>
  );
};

export default DetailsComponent;
