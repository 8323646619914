import React, { createContext, useState, useEffect } from "react";
import { node } from "prop-types";
import { withRouter } from "react-router";
import ConfiguracionStore from "./store";
import {
  enableBreedService,
  paginatedBreeds,
  sendNewPermissionService,
  sendNewUsusarioGanaderoService,
  sendNewAdminGanaderoService,
  enableUserService,
  enableRolService,
  sendNewRazaService,
  editRazaService,
  sendNewTipoService,
  getRoles,
  getUsers,
  getAdmins,
  sendEditPermissionService
} from "./../../servicces";
import { toast } from "react-toastify";

export const ConfiguracionContext = createContext(ConfiguracionStore);
const configBreeds = {
  page: 1,
  limit: 10,
};
const ConfiguracionProvider = ({ children }) => {
  const [Rol, setRol] = useState(ConfiguracionStore.Rol);
  const [Usuario, setUsuario] = useState(ConfiguracionStore.Usuario);
  const [Admin, setAdmin] = useState(ConfiguracionStore.Admin);

  const [userPage, setUserPage] = useState(1);
  const [usersInfo, setUsersInfo] = useState(null);
  const [AdminPage, setAdminPage] = useState(1);
  const [AdminInfo, setAdminInfo] = useState(null);

  const [Tipo, setTipo] = useState(ConfiguracionStore.Tipo_de_Muerte);

  const [Raza, setRaza] = useState(ConfiguracionStore.Raza);
  const [RazasArr, setRazasArr] = useState([]);
  const [breedsInfo, setbreedsInfo] = useState(null);
  const [breedsPage, setbreedsPage] = useState(1);

  const [RolesArr, setRolesArr] = useState([]);
  const [UsusariosArr, setUsusariosArr] = useState([]);
  const [AdminsArr, setAdminsArr] = useState([]);

  const [TiposArr, setTiposArr] = useState([]);

  //Edit roles
  const [isEditRoles, setisEditRoles] = useState(false)
  const [rolEditting, setrolEditting] = useState(ConfiguracionStore.Rol)

  //Editting
  const [isEditing, setIsEditing] = useState(false);
  const [itemEditing, setitemEditing] = useState(ConfiguracionStore.EditRaza)


  const setUserValues = ({ target: { name, value } }) => {
    setUsuario({
      ...Usuario,
      [name]: value,
    });
  };

  const setAdminValues = ({ target: { name, value } }) => {
    setAdmin({
      ...Admin,
      [name]: value,
    });
  };

  const getRolFunction = ({ target: { name, value } }) => {
    setRol({
      ...Rol,
      [name]: value,
    });
  };
  const success = (text) => toast.success(text, { autoClose: 3000 });
  const ERROR = (text) => toast.error(text, { autoClose: 3000 });

  const sendNewUsuarioGanadero = async () => {
    try {
      await sendNewUsusarioGanaderoService(Usuario);
      setUsuario(ConfiguracionStore.Usuario);
      getMoreUsers();
      success(
        "Ha sido enviado un correo al email del usuario creado para su validación"
      );
    } catch (error) {
      console.log(error);
    }
  };

  const sendNewAdminGanadero = async () => {
    try {
      await sendNewAdminGanaderoService(Admin);
      setAdmin(ConfiguracionStore.Admin);
      getMoreAdmins();
      success(
        "Se ha enviado un correo al nuevo admin para verificar su cuenta"
      );
    } catch (error) {
      console.log(error);
    }
  };

  const sendNewPermision = async () => {
    try {
      await sendNewPermissionService(Rol);
      setRol(ConfiguracionStore.Rol);
      getMorePermissions();
      success("Tu permiso ha sido creado exitosamente");
    } catch (error) {
      console.log(error);
    }
  };

//Edit roles
  const sendEditPermision = async () => {
    try {
      console.log(rolEditting)
      await sendEditPermissionService(rolEditting,rolEditting._id);
      setisEditRoles(false);
      setrolEditting(ConfiguracionStore.Rol);
      getMorePermissions();
      success("Tu permiso ha sido actualizado exitosamente");
    } catch (error) {
      setisEditRoles(false);
      console.log(error);
    }
  };

  const enableUser = async (id) => {
    try {
      await enableUserService(id);
      success("El estatus ha sido modificado correctamente");
    } catch (error) {
      ERROR("Ha ocurrido un error al actualizar el estatus");
    }
  };
  const enableBreed = async (id) => {
    try {
      await enableBreedService(id);
      success("El estatus ha sido modificado correctamente");
    } catch (error) {
      ERROR("Ha ocurrido un error al actualizar el estatus");
    }
  };
  const enableRole = async (id) => {
    try {
      await enableRolService(id);
      success("El estatus ha sido modificado correctamente");
    } catch (error) {
      ERROR("Ha ocurrido un error al actualizar el estatus");
    }
  };

  const handleRazaValues = ({ target: { name, value } }) => {
    setRaza({
      ...Raza,
      [name]: value,
    });
  };

  const handleRazaEditValues = ({ target: { name, value } }) => {
    setitemEditing({
      ...itemEditing,
      [name]: value,
    });
  };

  const sendNewRaza = async () => {
    try {
      await sendNewRazaService(Raza);
      setRaza(ConfiguracionStore.Raza);
      getMoreBreeds();
    } catch (error) {
      console.log(error);
    }
  };

  const sendEditRaza = async () => {
    try {
      await editRazaService(itemEditing,itemEditing.id);
      setitemEditing(ConfiguracionStore.EditRaza);
      setIsEditing(false);
      getMoreBreeds();
    } catch (error) {
      setIsEditing(false);
      ERROR("Ha ocurrido un error al actualizar el elemento");
      console.log(error);
    }
  };

  const getTipoFunction = ({ target: { name, value } }) => {
    setTipo({
      ...Tipo,
      [name]: value,
    });
  };

  const sendNewTipo = async () => {
    const response = await sendNewTipoService(Tipo);
    if (response.data.status === true) {
      getInitialData();
    }
  };

  const cleanInputs = () => {
    setRol(ConfiguracionStore.Rol);

    setUsuario(ConfiguracionStore.Usuario);

    setRaza(ConfiguracionStore.Raza);

    setTipo(ConfiguracionStore.Tipo);
  };

  const getInitialData = async () => {
    getMoreUsers(1);
    getMoreAdmins(1);
    getMorePermissions();
    getMoreBreeds();
  };

  const getMoreBreeds = async (config) => {
    const breeds = await paginatedBreeds(config || configBreeds);
    setRazasArr(breeds.data.data.breeds);
    setbreedsInfo(breeds.data.data);
  };
  const getMoreUsers = async (page) => {
    const users = await getUsers(page);
    setUsusariosArr(users.data.data.users);
    setUsersInfo(users.data.data);
  };
  const getMoreAdmins = async (page) => {
    const admins = await getAdmins(page);
    console.log(admins);
    setAdminsArr(admins.data.data.users);
    setAdminInfo(admins.data.data);
  };
  const getMorePermissions = async () => {
    const roles = await getRoles();
    setRolesArr(roles.data.data.permissions);
  };

  useEffect(() => {
    getInitialData();
  }, []);
  const state = {
    getRolFunction,
    handleRazaValues,
    getTipoFunction,
    cleanInputs,
    setUserPage,
    userPage,
    usersInfo,
    getMoreUsers,
    Rol,
    setRol,
    Usuario,
    breedsInfo,
    breedsPage,
    setUserValues,
    getMoreBreeds,
    //send Data
    sendNewUsuarioGanadero,
    sendNewRaza,
    sendNewTipo,
    enableUser,
    enableBreed,
    enableRole,
    sendNewPermision,
    setbreedsPage,
    //Edit roles
    isEditRoles,
    setisEditRoles,
    rolEditting,
    setrolEditting,
    sendEditPermision,
    //Edit
    sendEditRaza,
    isEditing,
    setIsEditing,
    itemEditing,
    setitemEditing,
    handleRazaEditValues,
    //Arrs
    RolesArr,
    UsusariosArr,
    AdminsArr,
    Raza,
    RazasArr,
    setRazasArr,
    TiposArr,
    UsusariosArr,
    RazasArr,
    TiposArr,
    Admin,
    setAdminValues,
    sendNewAdminGanadero,
    AdminPage,
    setAdminPage,
    AdminInfo,
    setAdminInfo,
    getMoreAdmins,
    itemEditing
  };

  return (
    <ConfiguracionContext.Provider value={state}>
      {children}
    </ConfiguracionContext.Provider>
  );
};

ConfiguracionProvider.propTypes = {
  children: node,
};

ConfiguracionProvider.defaultProps = {
  children: null,
};

export default withRouter(ConfiguracionProvider);
