import React, { createContext, useState, useEffect, useRef } from "react";
import mapboxgl from "mapbox-gl";
import { shape, string, node, func } from "prop-types";
import { withRouter } from "react-router";
import RanchStore from "./store";
import {
  newRanchPetition,
  InitialDataPetition,
  updateRanch,
  disableRanchService,
  createSiniiga,
  deleteSiniiga,
  updateSiniiga,
} from "../../servicces";
import estadosMunicipios from "./estados-municipios.json";
import axios from "axios";

export const RanchContext = createContext(RanchStore);
const RanchProvider = ({ children }) => {
  mapboxgl.accessToken = process.env.REACT_APP_API_MAPBOX;

  const [searchBarInput, setSearchBarInput] = useState("");
  const [page, setPage] = useState(1);
  const [cards, setCards] = useState(RanchStore.cards);
  const [cardsRaw, setcardsRaw] = useState(RanchStore.cards);
  const [ranchID, setRanchID] = useState(RanchStore.newRanch);
  const [newRanch, setnewRanch] = useState(RanchStore.newRanch);

  const [paises, setPaises] = useState(RanchStore.paises);
  const [estados, setEstados] = useState(
    Object.keys(estadosMunicipios).map((el) => el)
  );
  const { EdoSelected, setEdoSelected } = useState("");
  const [loading, setLoading] = useState(false);

  const [ciudades, setCiudades] = useState(estadosMunicipios["Yucatán"]);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isOpenInfo, setIsOpenInfo] = useState(false);
  const [isEditableForm, setIsEditableForm] = useState(false);
  const [isActiveWarning, setisActiveWarning] = useState(false);

  const [stateSelected, setStateSelected] = useState("Yucatán");
  const [msgError, setMsgError] = useState("");
  const [infoRanchs, setInfoRanch] = useState(null);

  //siniiga
  const initSiniiga = {
    id:'',
    livestockId:'',
    siniiga:''
  }

  const [modalnewSiniiga, setmodalnewSiniiga] = useState(false)
  const [siniigaModel, setsiniigaModel] = useState(initSiniiga)
  //endsiniiga



  const [Error, setError] = useState({
    Error_responsable: false,
    Error_nombre: false,
    Error_proposito: false,
    Error_dimension: false,
  });
  useEffect(() => {
    return () => {
      setCards([]);
    };
  }, []);
  const getDataNewRanch = (name, value) => {
    /*  if (value === "") {
      setMsgError("Este campo es obligatorio");
      setError({ ...Error, [`Error_${name}`]: true });
      return
    } else if (value.length < 4 && name!=='dimension') {
      setMsgError("El campo es demasiado corto");
      setError({ ...Error, [`Error_${name}`]: true });
      return

    } else {
      if (Error[`Error_${name}`] === true) {
        setError({ ...Error, [`Error_${name}`]: false });
      }
    } */
    if (name === "state") {
      setCiudades(estadosMunicipios[value]);
    }
    setnewRanch({
      ...newRanch,
      [name]: value,
    });
    //setMsgError("");
  };

  const cleanFormNewRanch = () => {
    setnewRanch(RanchStore.newRanch);
  };

  const getInitialData = async ({ page, limit, search_text }) => {
    setLoading(true);
    try {
      const response = await InitialDataPetition({ page, limit, search_text });
      setcardsRaw(response.data.data.ranchs);
      setCards(response.data.data.ranchs);
      setInfoRanch(response.data.data);
      setLoading(false);
    } catch (error) {
      setCards([]);
      setcardsRaw([]);
      setLoading(false);
    }
  };

  const sendNewRanch = async (id) => {
    setMsgError("");
    try {
      await newRanchPetition(newRanch, id);
      getInitialData({ page: 1, limit: 12, search_text: "" });
      setIsOpenModal(false);
    } catch (error) {
      setMsgError(error.response.data.msg);
      console.log("error", error.response);
    }
  };

  const getInfoRanch = (id) => {
    const dataRanchID = cards.find((el) => el._id === id);
    setCiudades(estadosMunicipios[dataRanchID.state]);
    setnewRanch(dataRanchID);
  };

  const cleanRanchStore = () => {
    setnewRanch({
      inCharge: "",
      name: "",
      purpose: "",
      dimension: "",
      country: "",
      active: "",
      city: "",
      coordinates: {
        x: -89.62,
        y: 20.96,
      },
      state: "",
    });
  };

  const updateRanchCtx = async (data, id) => {
    const response = await updateRanch(data, id);
    if (response?.data.status) {
      getInitialData({ page, limit: 12, search_text: searchBarInput });
    }
    cleanRanchStore();
  };

  const selectEdo = async (edo) => {};

  const getDataMapBox = (xCoordinate, yCoordinate) => {
    return axios.get(
      `https://api.mapbox.com/v4/geocode/mapbox.places/${xCoordinate},${yCoordinate}.json?access_token=${process.env.REACT_APP_API_MAPBOX}`
    );
  };

  const [map, setMap] = useState(null);
  const mapContainer = useRef(null);

  const [lng, setLng] = useState(-89.62);
  const [lat, setLat] = useState(20.96);
  const [zoom, setZoom] = useState(10);

  const setIsActiveMapFunction = (from) => {
    if (from === "Ubicación") {
      // setIsActiveMap(true);
      return;
    } else {
      // setMap(null)
    }
  };

  useEffect(() => {
    if (mapContainer.current && !map) {
      setMap(
        new mapboxgl.Map({
          container: mapContainer?.current,
          style: "mapbox://styles/mapbox/streets-v11",
          center: [lng, lat],
          zoom: zoom,
        })
      );
    }

    if (map) {
      map.on("click", (event) => {
        getDataMapBox(event.lngLat.lng, event.lngLat.lat)
          .then((request) => {
            setStateSelected(request?.data.features[0]?.context[2].text);
            setTimeout(() => {
              setCiudades(
                estadosMunicipios[request?.data.features[0]?.context[2].text]
              );
            }, 300);
          })
          .catch((err) => {
            console.log(err);
          });
      });
    }
  }, [
    searchBarInput,
    cards,
    newRanch,
    paises,
    estados,
    ciudades,
    isOpenModal,
    isOpenInfo,
    EdoSelected,
    map,
    mapContainer,
    Error,
    stateSelected,
  ]);

  const setMapNull = () => {
    setMap(null);
  };

  const dissableFunction = async (id) => {
    const response = await disableRanchService(id);
    if (response.status) {
      getInitialData({ page: 1, limit: 12, search_text: "" });
      setisActiveWarning(!response.status);
    }
  };

  const findCard = (data) => {
    setCards(
      cardsRaw.filter((el) =>
        el.name.toLowerCase().includes(data.toLowerCase())
      )
    );
  };

  //siniiga
  const handleSiniigaValue = ({ target: { name, value } }) => {
    setsiniigaModel({
      ...siniigaModel,
      [name]: value,
    });
  };

  const handleSubmitSiniigaForm = async () => {
    //create
    if(siniigaModel.id == ''){
      try{
        const response = await createSiniiga(siniigaModel);
        if(response.status == 200){
          setmodalnewSiniiga(false);
        }
      }catch(ex){

      }
    }else{
      try{
        const response = await updateSiniiga(siniigaModel);

      }catch(ex){
        
      }
    }
  }

  const handleDeleteSiniiga = async (siiniga,livestock) => {
    const response = await deleteSiniiga(siiniga,livestock);
    console.log(response);
  }



  let state = {
    searchBarInput,
    setSearchBarInput,
    page,
    setPage,
    cards,
    setCards,
    newRanch,
    ranchID,
    setRanchID,
    paises,
    setPaises,
    estados,
    setEstados,
    ciudades,
    setCiudades,
    isOpenModal,
    setIsOpenModal,
    sendNewRanch,
    getDataNewRanch,
    isOpenInfo,
    setIsOpenInfo,
    getInfoRanch,
    isEditableForm,
    setIsEditableForm,
    cleanFormNewRanch,
    updateRanchCtx,
    selectEdo,
    mapContainer,
    Error,
    stateSelected,
    setIsActiveMapFunction,
    setMapNull,
    dissableFunction,
    isActiveWarning,
    setisActiveWarning,
    findCard,
    infoRanchs,
    getInitialData,
    loading,
    setLoading,
    setnewRanch,
    msgError,
    modalnewSiniiga,
     setmodalnewSiniiga,
     siniigaModel, 
     setsiniigaModel,
     initSiniiga,
     handleSiniigaValue,
     handleSubmitSiniigaForm,
     handleDeleteSiniiga
  };

  useEffect(() => {
    if (!sessionStorage.getItem("hatoToken")) return;
    getInitialData({ page: 1, limit: 12, search_text: "" });
    //eslint-disable-next-line
  }, []);

  return (
    <RanchContext.Provider value={state}>{children}</RanchContext.Provider>
  );
};
RanchProvider.propTypes = {
  children: node,
  searchBarInput: string,
  cards: shape(),
  newRanch: shape(),
  ranchID: shape(),
  paises: shape(),
  estados: shape(),
  ciudades: shape(),
  setSearchBarInput: func,
  setCards: func,
  setnewRanch: func,
  setRanchID: func,
  setPaises: func,
  setEstados: func,
  setCiudades: func,
  setIsOpenModal: func,
  sendNewRanch: func,
  getDataNewRanch: func,
};

RanchProvider.defaultProps = {
  children: null,
  searchBarInput: RanchStore.searchBarInput,
  cards: {},
  newRanch: RanchStore.newRanch,
  ranchID: RanchStore.RanchID,
  paises: {},
  estados: {},
  ciudades: {},
};

export default withRouter(RanchProvider);
