import React from 'react';
import  styles from './styles.module.css';

const  Login = ( { formSigmIn, getDataInputs }) => {

  return (
    <>
      <div className={styles.LoginContainer} >
        <input 
        className={styles.InputsLogin}
        type="text" 
        name="email" 
        placeholder="Correo" 
        onChange={(e)=> getDataInputs(e)}
        required={true}
        />
        <input 
        className={styles.InputsLogin}
        type="password" 
        name="password" 
        placeholder="Contraseña" 
        onChange={(e)=> getDataInputs(e)}
        required={true}
        />
      </div>
    </>
  )
}

export default Login;
