import React, { useContext, memo } from "react";
import { GanaderosContext } from "../../../Context/GanaderosStore/context";
const options = [
  { label: "Todos", value: "todos" },
  { label: "Si", value: "si" },
  { label: "No", value: "no" },
];

const SelectorActive = memo(() => {
  const ganaderosContext = useContext(GanaderosContext);
  const {
    getInitialData,
    setLoading,
    setPage,
    activeSelector,
    setactiveSelector,
    ranchsSelector,
    inputSearch
  } = ganaderosContext;
  const handleChange = (e) => {
    setLoading(true);
    setPage(1);
    getInitialData({
        page: 1,
        limit: 10,
        search_text: inputSearch,
        active: e.target.value,
        ranch: ranchsSelector,
      });
    setactiveSelector(e.target.value);
  };
  return (
    <section className="formGroup">
      <label htmlFor="ranchos_ganaderos">Activo:</label>
      <select
        select={activeSelector}
        placeholder="ranchos"
        id="ranchos_ganaderos"
        onChange={handleChange}
      >
        {options.map((res, key) => (
          <option
            selected={res.value === activeSelector}
            key={key}
            value={res.value}
          >
            {res.label}
          </option>
        ))}
      </select>
    </section>
  );
});

export default SelectorActive;
