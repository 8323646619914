import React, { createContext, useEffect, useState } from 'react';
import { withRouter, useLocation } from 'react-router-dom';
import {
  getDataIDCabezaService,
  getRanchoDetails,
  sendNewCabezaService,
  getUrlFromRanch,
} from '../../servicces';
import Store from './store';

export const DetailsContext = createContext(Store);

const DetailsProvider = ({ children }) => {
  const { pathname } = useLocation();
  const splitPathname = pathname.split('/');
  const ranchID = splitPathname[2];
  const [info, setInfo] = useState([]);
  const [isOpenModalGanado, setisOpenModalGanado] = useState(false);
  const [RanchoID, setRanchoID] = useState(null);
  const [Cabeza, setCabeza] = useState(Store.Cabeza);
  const [Cabezas, setCabezas] = useState([]);
  const [CabezasRow, setCabezasRow] = useState([]);
  const [isOpenModalDetails, setisOpenModalDetails] = useState(false);
  const [urlExcel, seturlExcel] = useState('');
  const [dataFromID, setdataFromID] = useState([]);
  const [listGanado, setListGanado] = useState([]);
  const [loading, setLoading] = useState(false);
  const [paginationInfo, setpaginationInfo] = useState();
  const [inputSearch, setinputSearch] = useState('');
  const [breedFilter, setBreedFilter] = useState('');
  const [sexFilter, setsexFilter] = useState('');
  const [statusFilter, setstatusFilter] = useState('');
  const [cycleFilter, setCycleFilter] = useState('');
  const [submitErrors, setsubmitErrors] = useState('');
  const [page, setPage] = useState(1);

  const configData = {
    page: 1,
    limit: 10,
    id: ranchID,
    sex: '',
    breed: '',
    status: '',
    search_text: '',
    cycle: '',
  };
  const getInitialData = async ({ page, limit, id, sex, breed, status, search_text, cycle }) => {
    setLoading(true);
    if (!id) {
      setRanchoID(id);
    }
    try {
      const response = await getRanchoDetails({
        page,
        limit,
        id,
        sex,
        breed,
        status,
        search_text,
        cycle,
      });
      setInfo(response.data.info);
      setpaginationInfo(response.data.data);
      setListGanado(response.data.data.livestocks);
      setLoading(false);
    } catch (error) {
      console.log('error context ranch', error.response);
    }
  };

  const handleCowFormInputs = ({ name, value }) => {
    setCabeza((prev) => {
      return { ...prev, [name]: value };
    });
  };

  const submitNewCow = async () => {
    setsubmitErrors('');
    try {
      const response = await sendNewCabezaService(Cabeza);
      if (response.data.status) {
        getInitialData(configData);
        getDownloadUrl();
        setisOpenModalGanado(false);
      }
    } catch (error) {
      setsubmitErrors(error.response.data.msg);
      console.log(error.response);
    }
  };

  const detailsCabeza = async (id) => {
    const response = await getDataIDCabezaService(id);
    if (response.data.status === true) {
      setdataFromID(response.data);
    }
  };

  const openModalDetails = (booleano, id) => {
    setisOpenModalDetails(booleano);
    if (booleano) {
      detailsCabeza(id);
    }
  };

  const filterRaza = (e) => {
    if (e.target.value === '') getInitialData(configData);
    setListGanado({
      ...listGanado,
      livestocks: listGanado.livestocks.filter((el) => el.breed.name === e.target.value),
    });
  };
  const filterSexo = (sexo) => setCabeza(CabezasRow.filter((el) => el.Sexo === sexo));
  const filterSttatus = (status) => setCabeza(CabezasRow.filter((el) => el.Statuds === status));
  const filterRangoDeNacimiento = (fecha) =>
    setCabeza((el) => new Date(el.Fecha).getTime() > new Date(fecha));

  useEffect(() => {
    getInitialData(configData);
  }, []);

  const getDownloadUrl = async () => {
    try {
      const result = await getUrlFromRanch(ranchID);
      seturlExcel(result.data.filename);
    } catch (error) {
      seturlExcel(null);
      console.log(error.response);
    }
  };

  const state = {
    Cabeza,
    handleCowFormInputs,
    submitNewCow,
    openModalDetails,
    setisOpenModalGanado,
    filterRaza,
    filterSexo,
    filterSttatus,
    filterRangoDeNacimiento,
    submitErrors,
    setsubmitErrors,
    isOpenModalGanado,
    Cabezas,
    info,
    isOpenModalDetails,
    dataFromID,
    getInitialData,
    listGanado,
    loading,
    setLoading,
    paginationInfo,
    setpaginationInfo,
    page,
    setPage,
    inputSearch,
    setinputSearch,
    breedFilter,
    setBreedFilter,
    sexFilter,
    setsexFilter,
    statusFilter,
    setstatusFilter,
    setCabeza,
    urlExcel,
    seturlExcel,
    cycleFilter,
    setCycleFilter,
    getDownloadUrl,
  };

  useEffect(() => {}, [info, isOpenModalDetails, dataFromID]);

  return <DetailsContext.Provider value={state}>{children}</DetailsContext.Provider>;
};

export default withRouter(DetailsProvider);
