import React from 'react';
import GestacionProvider from '../Context/GestacionCriasStore/context'
import ListadoDeCrias from '../Components/Main/ListadoDeCrias'
import Hato from '../layout/Hato_Theme';

const Crias = ({children,match}) => {
  return (
    <Hato>
      <GestacionProvider children={children}>
        <ListadoDeCrias match={match}/>
      </GestacionProvider>
    </Hato>
  )
}

export default Crias
