import React, { useContext, useState, useEffect } from "react";
import "./GanaderosComponent.css";
import SearchBar from "./SearchBar/SearchBar";
import { add } from "./../constants";
import Table from "./Table";
import Modal from "../ModalContainer";
import NewGanaderos from "./../Forms/NewGanaderos";
import { newGanaderosForm } from "../constants";
//import { TablePagination } from "@mui/material";
import Pagination from "@mui/material/Pagination";
import { GanaderosContext } from "../../Context/GanaderosStore/context";
import CircularProgress from "@mui/material/CircularProgress";
import SelectorActive from "./SelectorActive/SelectorActive";
import SelectorRanchs from "./SelectorRanchs/SelectorRanchs";

const GanaderosComponent = ({ match }) => {
  const ganaderosContext = useContext(GanaderosContext);
  const {
    sendNewGanaderos,
    setnewGanaderos,
    newGanadero,
    getDataNewGanaderos,
    isOpenModalGanaderos,
    setIsOpenModalGanaderos,
    rows,
    setFormWithRancherData,
    isEditable,
    updateGanaderoCtx,
    Ranchos,
    infoGanaderos,
    getInitialData,
    loading,
    setLoading,
    msgResponse,
    page,
    setPage,
    inputSearch,
    activeSelector,
    setIsEditable,
  } = ganaderosContext;

  const [farmersFiltered, setFarmersFiltered] = useState(rows);

  useEffect(() => {
    setFarmersFiltered(rows);
  }, [rows]);

  const getNewGanaderos = ({ name, value }) => {
    getDataNewGanaderos(name, value);
  };
  const openCloseModalFunctionGanaderos = () =>
    setIsOpenModalGanaderos(!isOpenModalGanaderos);

  const getIdGanadero = (rancher) => {
    setFormWithRancherData(rancher);
    setIsEditable(true);
    openCloseModalFunctionGanaderos();
  };

  const updateGanadero = (id) => updateGanaderoCtx(id);
  const handleChangePage = (event, newPage) => {
    setLoading(true);
    setPage(newPage);
    const config = {
      page: newPage,
      limit: 10,
      search_text: inputSearch,
      active: activeSelector,
      ranch: "todos",
    };
    getInitialData(config);
  };

  return (
    <>
      <div className="GanaderosComponentContainer">
        <h1 className="Ganaderos">Ganaderos</h1>
        <div className="filterSection">
          <SearchBar />
          <SelectorRanchs />
          <SelectorActive />
        </div>

        <div className="cardContainerGanaderos">
          {loading ? (
            <div className="loading">
              <CircularProgress color="warning" size={100} />
            </div>
          ) : (
            <div className="ganaderos-body-table">
              <Table
                getIdGanadero={getIdGanadero}
                tablesInfo={farmersFiltered}
                match={match}
                ranchos={Ranchos}
              />
            </div>
          )}

          <div className="ganaderos-pagination">
            <Pagination
              count={infoGanaderos?.totalPages}
              page={page}
              onChange={handleChangePage}
              shape="rounded"
            />
            <label style={{ marginTop: "7px" }}>
              Ganaderos en total : {infoGanaderos?.totalRanchers}
            </label>
          </div>
        </div>
      </div>
      <div className="addganaderos">
        <button
          type="button"
          className="ActiveForm"
          onClick={() => openCloseModalFunctionGanaderos()}
        >
          <img src={add} alt="add" />
        </button>
      </div>
      {isOpenModalGanaderos && (
        <Modal
          ActiveForm={isOpenModalGanaderos}
          openCloseModal={() => {
            openCloseModalFunctionGanaderos();
            setnewGanaderos({
              name: "",
              upp: "",
              nickname: "",
              ranch: "",
              _id: "",
            });
            setIsEditable(false);
          }}
          submitNewItem={(id) =>
            isEditable ? updateGanadero(id) : sendNewGanaderos()
          }
          ID={isEditable ? newGanadero._id : null}
        >
          <NewGanaderos
            form={newGanaderosForm}
            getNewGanaderos={(target) => {
              getNewGanaderos(target);
            }}
            handleCowFormInputs={getDataNewGanaderos}
            values={newGanadero}
            isEditableForm={isEditable}
            makeEditable={() => {}}
            ranchos={Ranchos}
            heigth={"15rem"}
          />
          {msgResponse && (
            <label style={{ margin: "15px 0", color: "red" }}>
              {msgResponse}
            </label>
          )}
        </Modal>
      )}
    </>
  );
};

export default GanaderosComponent;
