import React, { createContext, useEffect, useState } from "react";
import { withRouter, useLocation } from "react-router-dom";
import {
  getUrlFromPalpations,
  getPalpacionesList,
  sendNewCabezaService,
  getPalpacionHistorial,
  submitPalpationHistorical
} from "../../servicces";
import Store from "./store";
export const PalpacionesContext = createContext(Store);
const DetailsProvider = ({ children }) => {
  const { pathname } = useLocation();
  const splitPathname = pathname.split("/");
  const cowID = splitPathname[2];
  const [info, setInfo] = useState([]);
  const [newPalpationModal, setNewPalpationModal] = useState(false);
  const [Cabeza, setCabeza] = useState(Store.Cabeza);
  const [Cabezas, setCabezas] = useState([]);
  const [CabezasRow, setCabezasRow] = useState([]);
  const [isOpenModalDetails, setisOpenModalDetails] = useState(false);
  const [urlExcel, seturlExcel] = useState("");

  const [dataFromID, setdataFromID] = useState([]);
  const [listGanado, setListGanado] = useState([]);
  const [loading, setLoading] = useState(false);
  const [paginationInfo, setpaginationInfo] = useState();
  const [inputSearch, setinputSearch] = useState("");
  const [breedFilter, setBreedFilter] = useState("");
  const [ranchFilter, setRanchFilter] = useState("");
  const [sexFilter, setsexFilter] = useState("H");
  const [statusFilter, setstatusFilter] = useState("");
  const [submitErrors, setsubmitErrors] = useState("");
  const [page, setPage] = useState(1);
  const configData = {
    page: 1,
    limit: 10,
    // id: cowID,
    sex: "H",
    breed: "",
    status: "",
    search_text: "",
    ranch:''
  };
  const configHistorial = {
    page: 1,
    limit: 10,
    livestock: cowID,
  };
  const getInitialData = async ({
    page,
    limit,
    breed,
    status,
    search_text,
    ranch
  }) => {
    setLoading(true);
    try {
      const response = await getPalpacionesList({
        page,
        limit,
        status,
        breed,
        search_text,
        ranch
      });
      setInfo(response.data.info);
      setpaginationInfo(response.data.data);
      setListGanado(response.data.data.livestocks);
      setLoading(false);
    } catch (error) {
      console.log("error context ranch", error.response);
    }
  };

  const [historialInfo, setHistorialInfo] = useState();
  const [historial, setHistorial] = useState([]);
  const [newHistorialValues, setNewHistorialValues] = useState(Store.Historial);
  const [historialErros, setHistorialErros] = useState("");


  const getHistorial = async ({ page, limit, livestock }) => {
    setLoading(true);
    try {
      const response = await getPalpacionHistorial({
        page,
        limit,
        livestock,
      });
      setHistorialInfo(response.data.data);
      setHistorial(response.data.data.palpations);
      setLoading(false);
    } catch (error) {
      console.log("error context ranch", error.response);
    }
  };
  
  const submitNewHistorical = async () => {
    setHistorialErros("");
    try {

      const response = await submitPalpationHistorical(newHistorialValues);
      if (response.data.status) {
        getHistorial(configHistorial);
        getDownloadUrl()
        setNewPalpationModal(false);
      }
    } catch (error) {
      setHistorialErros(error.response.data.msg);
      console.log(error.response);
    }
  };

  const handleCowFormInputs = ({ name, value }) => {
    setCabeza((prev) => {
      return { ...prev, [name]: value };
    });
  };

  const submitNewCow = async () => {
    setsubmitErrors("");
    try {
      const response = await sendNewCabezaService(Cabeza);
      if (response.data.status) {
        getInitialData(configData);
        setNewPalpationModal(false);
      }
    } catch (error) {
      setsubmitErrors(error.response.data.msg);
      console.log(error.response);
    }
  };

  const filterRaza = (e) => {
    if (e.target.value === "") getInitialData(configData);
    setListGanado({
      ...listGanado,
      livestocks: listGanado.livestocks.filter(
        (el) => el.breed.name === e.target.value
      ),
    });
  };
  const filterSexo = (sexo) =>
    setCabeza(CabezasRow.filter((el) => el.Sexo === sexo));
  const filterSttatus = (status) =>
    setCabeza(CabezasRow.filter((el) => el.Statuds === status));
  const filterRangoDeNacimiento = (fecha) =>
    setCabeza((el) => new Date(el.Fecha).getTime() > new Date(fecha));

  useEffect(() => {
    getInitialData(configData);
    if (cowID) getHistorial(configHistorial);
  }, [cowID]);

  const getDownloadUrl = async () => {
    try {
      const result = await getUrlFromPalpations(cowID);
      seturlExcel(result.data.filename);
    } catch (error) {
      console.log(error);
    }
  };
  const state = {
    handleCowFormInputs,
    submitNewCow,
    setNewPalpationModal,
    filterRaza,
    filterSexo,
    filterSttatus,
    filterRangoDeNacimiento,
    submitErrors,
    newPalpationModal,
    Cabezas,
    info,
    isOpenModalDetails,
    dataFromID,
    getInitialData,
    listGanado,
    loading,
    setLoading,
    paginationInfo,
    setpaginationInfo,
    page,
    setPage,
    inputSearch,
    setinputSearch,
    breedFilter,
    setBreedFilter,
    sexFilter,
    setsexFilter,
    statusFilter,
    setstatusFilter,
    getHistorial,
    historialInfo,
    historial,
    setNewHistorialValues,
    submitNewHistorical,
    historialErros,
    newPalpationModal, setNewPalpationModal,
    ranchFilter, setRanchFilter,
    urlExcel,
    getDownloadUrl
  };

  useEffect(() => {}, [info, isOpenModalDetails, dataFromID]);

  return (
    <PalpacionesContext.Provider value={state}>
      {children}
    </PalpacionesContext.Provider>
  );
};

export default withRouter(DetailsProvider);
