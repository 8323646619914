import React, { useEffect, useState, useContext } from 'react';
import './styles.css';
import { getBreeds, getAllGanaderos, getSelectActiveRanchs } from '../../../servicces';
import { DetailsContext } from '../../../Context/Details/context';
import { useLocation } from 'react-router-dom';
import { sexoOptions, statusOptions, breedTypeOptions } from '../../constants';
import { GanaderosContext } from '../../../Context/GanaderosStore/context';

const NewGanado = ({ form, handleCowFormInputs, heigth, submitErrors }) => {
  const ganaderosContext = useContext(GanaderosContext);
  const { newGanadero, isEditable } = ganaderosContext;
  const datailsCtx = useContext(DetailsContext);
  const { Cabeza, setCabeza } = datailsCtx;
  const { pathname } = useLocation();
  const splitPathname = pathname.split('/');
  const ranchID = splitPathname[2];
  const [selectors, setselectors] = useState(null);
  const [loading, setloading] = useState(true);

  useEffect(() => {
    if (ranchID)
      setCabeza((prev) => {
        return {
          ...prev,
          ranch: ranchID,
        };
      });
  }, [ranchID]);

  const activeTab = 'buttonsTabs isActiveTab';
  const deactiveTab = 'buttonsTabs';
  const buttonsTabsSelection = (e) => {
    e.target.parentNode.parentNode.querySelectorAll('.Tab').forEach((el) => {
      if (el.title === e.target.title) {
        el.style.opacity = '1';
        el.style.display = 'block';
      } else {
        el.style.opacity = '0';
        el.style.display = 'none';
      }
    });
    e.target.parentNode.querySelectorAll('.buttonsTabs').forEach((element, i) => {
      element.style.borderBottom = 'none';
      element.classList.remove('isActiveTab');
    });
    e.target.classList.add('isActiveTab');
    e.target.style.borderBottom = 'solid 2px rgba(215, 108, 28, 1)';
  };

  const getSelectors = async () => {
    try {
      const ranchsResult = await getSelectActiveRanchs({
        page: 1,
        limit: 100,
        search_text: '',
      });
      const ranchersResult = await getAllGanaderos();
      const breedResult = await getBreeds();
      const configSelectors = {
        status: getStatusOptions(),
        ranch: ranchsResult.data.data.ranchs,
        breed: breedResult.data.data.breeds,
        rancher: ranchersResult.data.data.ranchers,
        sex: sexoOptions.map((res) => {
          return { name: res.label, _id: res.value };
        }),
        type: breedTypeOptions.map((res) => {
          return { name: res.label, _id: res.value };
        }),
      };

      setselectors(configSelectors);
      setloading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const getDisplayInputForm = (data) => {
    if (data.name !== 'reproductionDate') return 'block';
    else if (Cabeza.status === 'cargada') return 'block';
    else return 'none';
  };

  useEffect(() => {
    getSelectors();
  }, []);

  const getStatusOptions = () => {
    let status = [];

    if (Cabeza.sex === 'H') {
      status = statusOptions
        .filter((item) => item.value !== 'semental')
        .map((res) => {
          return { name: res.label, _id: res.value };
        });
    } else if (Cabeza.sex === 'M') {
      status = statusOptions
        .filter((item) => item.value !== 'cargada' && item.value !== 'vacia')
        .map((res) => {
          return { name: res.label, _id: res.value };
        });
    } else {
      status = statusOptions.map((res) => {
        return { name: res.label, _id: res.value };
      });
    }

    return status;
  };

  useEffect(() => {
    if (Cabeza.sex !== '') {
      const status = getStatusOptions();

      setselectors((values) => ({ ...values, status }));
      handleCowFormInputs({ name: 'status', value: '' });
    }
  }, [Cabeza.sex]);

  if (loading && !selectors) return <div className={form.mainClass}>...loading</div>;
  return (
    <div className={form.mainClass}>
      <h1 className="titleGrupo">{isEditable ? 'Editar Ganadero' : form.Maintitle}</h1>
      <div className="contentTabs">
        {Object.keys(form?.tabs).map((key, i) => (
          <button
            key={`buttonsForm-${i}`}
            className={i === 0 ? 'baseTab_Btn ' + activeTab : 'baseTab_Btn ' + deactiveTab}
            title={`tab${i}`}
            type="button"
            onClick={buttonsTabsSelection}
          >
            {form?.tabs[key]}
          </button>
        ))}
      </div>
      <div className="TabsGanaderos" style={{ height: heigth }}>
        {Array.from(Array(form.parts)).map((num, i) => {
          return (
            <div
              key={`tab${i + 1}`}
              className={`Tab tab${i + 1}`}
              title={`tab${i}`}
              style={{ opacity: `${i > 0 ? '0' : '1'}` }}
            >
              {form[`part${i + 1}`].map((el, i) => (
                <div
                  className="eachInputForm"
                  key={`eachInput-${i}`}
                  style={{ display: getDisplayInputForm(el) }}
                >
                  <div className="contentLabel">
                    <img className="iconInput" src={el.icon} alt={el.name} />
                    <label className="labelInput">{el.title}</label>
                  </div>
                  {el.type === 'text' || el.type === 'date' ? (
                    <input
                      className="inputText ganado"
                      type={el.type}
                      name={el.name}
                      onChange={(e) => handleCowFormInputs(e.target)}
                      value={el.name === 'reproductionDate' ? Cabeza.reproductionDate : undefined}
                    />
                  ) : (
                    <select
                      className="create-cow-selector ganado"
                      name={el.name}
                      onChange={(e) => {
                        handleCowFormInputs(e.target);

                        if (el.name === 'status' && e.target.value === 'cargada') {
                          handleCowFormInputs({ name: 'reproductionDate', value: '' });
                        }
                      }}
                      value={el.name === 'status' ? Cabeza.status : undefined}
                      disabled={el.title === 'Ranchos' ? true : false}
                      defaultValue={el.title === 'Ranchos' ? ranchID : ''}
                    >
                      <option value="">{el.label}</option>
                      {selectors[el.name]?.map((opt, k) => (
                        <option key={`${el.label}-${k}`} value={`${opt._id}`}>
                          {opt.description
                            ? ` ${opt.name} - ${opt.description} `
                            : ` ${opt.name}  `}
                        </option>
                      ))}
                    </select>
                  )}
                </div>
              ))}
            </div>
          );
        })}
      </div>
      {submitErrors !== '' && <label style={{ color: 'red' }}>{submitErrors}</label>}
    </div>
  );
};

export default NewGanado;
