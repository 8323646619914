import React from 'react'
import {find} from './../../constants'
import './styles.css'

const SearchBox = () => {
  return (
    <div className="inpurSearchBox">
      <img className="findIcon" src={find} alt="Find"/>
      <input className="findInput" type="text" name="searchBox"  placeholder="Buscar"/>
    </div>
  )
}
export default SearchBox
