import React, { useContext, useEffect } from 'react';
import { DetailsContext } from '../../../Context/Details/context';
import { Ganado, add } from '../../constants';
import NewGanado from '../../Forms/NewGanado';
import Modal from '../../ModalContainer';
import Tabla from './RanchTable';
import CircularProgress from '@mui/material/CircularProgress';
import ModalDetails from './ModalDetails';
import InputSearch from './InputSearch/InputSearch';
import BreedSelector from './BreedSelector/BreedSelector';
import SexSelector from './SexSelector/SexSelector';
import StatusSelector from './StatusSelector/StatusSelector';
import CycleSelector from './CycleSelector/CycleSelector';
import './styles.css';
import Pagination from '@mui/material/Pagination';
import { useLocation } from 'react-router-dom';
import DownloadFileButton from '../../../Components/DownloadFileButton/DownloadFileButton';
import { PieContext } from '../../../Context/PieCria/Context';
const DetailsComponent = ({ item, match }) => {
  const { pathname } = useLocation();
  const splitPathname = pathname.split('/');
  const ranchID = splitPathname[2];

  //details context
  const datailsCtx = useContext(DetailsContext);
  const {
    info,
    handleCowFormInputs,
    submitNewCow,
    isOpenModalGanado,
    setisOpenModalGanado,
    isOpenModalDetails,
    openModalDetails,
    dataFromID,
    getInitialData,
    listGanado,
    loading,
    setLoading,
    paginationInfo,
    page,
    setPage,
    inputSearch,
    breedFilter,
    sexFilter,
    statusFilter,
    cycleFilter,
    submitErrors,
  } = datailsCtx;

  //piecontext
  const pieCtx = useContext(PieContext);
  const {
    modalnewSiniiga,
    setmodalnewSiniiga,
    siniigaModel,
    setsiniigaModel,
    initSiniiga,
    handleSiniigaValue,
    handleSubmitSiniigaForm,
    handleDeleteSiniiga,
    handlePrivateValueMethod,
    handleSubmitPrivateFormMethod,
    deletePrivateMethod,
    privateModel,
    setPrivateModel,
    modalPrivate,
    setmodalPrivate,
    privateInit,
  } = pieCtx;

  const closeModal = () => {
    setmodalnewSiniiga(false);
  };

  const closeModalPrivate = () => {
    setmodalPrivate(false);
  };

  const configGetPages = {
    page: 1,
    limit: 10,
    id: ranchID,
    search_text: inputSearch,
    sex: sexFilter,
    breed: breedFilter,
    status: statusFilter,
    cycle: cycleFilter,
  };

  const getId = (e) => {
    openModalDetails(true, e);
  };
  const handleChangePage = (_, newPage) => {
    setLoading(true);
    setPage(newPage);
    const config = {
      page: newPage,
      limit: 10,
      id: ranchID,
      search_text: inputSearch,
      sex: sexFilter,
      breed: breedFilter,
      status: statusFilter,
      cycle: cycleFilter,
    };
    getInitialData(config);
  };

  /* const handleChangeRowsPerPage = (event) => {
    setLoading(true);
    setRowsPerPage(+event.target.value);
    setPage(0);
    getInitialData(page, +event.target.value);
  }; */

  return (
    <div className="MainDetailsComponentContainer">
      <div className="MainTitleDetails">
        {/* {
                    info[0] &&
                    <h3 className="mainTitle" >Ganado / {info[0].filter(el => item.id === el._id).name} </h3>
                } */}
      </div>
      <div className="ranch-filters-row">
        <div className="mainContainerFiltersDetails">
          <InputSearch />
          <BreedSelector />
          <SexSelector />
          <StatusSelector />
          <CycleSelector />
          <DownloadFileButton
            excelType="ganado"
            id={ranchID}
            search_text={inputSearch}
            breed={breedFilter}
            sex={sexFilter}
            status={statusFilter}
            cycle={cycleFilter}
          >
            Descargar tabla
          </DownloadFileButton>
        </div>
      </div>

      {loading ? (
        <div className="loading">
          <CircularProgress color="warning" size={100} />
        </div>
      ) : (
        <>
          {listGanado.length > 0 && (
            <>
              <Tabla
                getIdGanadero={getId}
                tablesInfo={listGanado}
                setActiveRanch={() => {}}
                redirect={true}
                match={match}
              />
            </>
          )}
          {/*  <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={listGanado?.totalLivestocks}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          /> */}
        </>
      )}
      <div className="ganaderos-pagination">
        <Pagination
          count={paginationInfo?.totalPages}
          page={page}
          onChange={handleChangePage}
          shape="rounded"
        />
        <label style={{ marginTop: '7px' }}>
          Ganado en total : {paginationInfo?.totalLivestocks}
        </label>
      </div>
      <div className="addGanadoContainerDetails">
        <button
          type="button"
          className="ActiveFormDetails"
          onClick={() => {
            setisOpenModalGanado(true);
          }}
        >
          <img src={add} alt="add" />
        </button>
      </div>

      {/* priv */}
      {modalPrivate ? (
        <Modal ActiveForm={modalPrivate} openCloseModal={closeModalPrivate}>
          {privateModel.id != '' ? (
            <>
              {' '}
              <h1>Numero Privado por defecto</h1>
              <div style={{ textAlign: 'center' }}>
                <div>
                  <div className="label-gestation">
                    <label>Numero Privado</label>
                  </div>
                  <input
                    value={privateModel.priv}
                    type="text"
                    className="inputRoles InputsConfig"
                    name="priv"
                    placeholder="Ingresa numero privado"
                    disabled={true}
                  />
                </div>
              </div>
              <button
                onClick={handleSubmitPrivateFormMethod}
                className="newRanchButtonAccept"
                type="button"
              >
                Actualizar
              </button>
              <button onClick={closeModalPrivate} className="newRanchButtonCancel" type="button">
                Cancelar
              </button>
            </>
          ) : (
            <>
              {/* //crear */}
              <h1>Crear Numero Privado</h1>
              <div style={{ textAlign: 'center' }}>
                <div>
                  <div className="label-gestation">
                    <label>Numero Privado</label>
                  </div>
                  <input
                    value={privateModel.priv}
                    type="text"
                    className="inputRoles InputsConfig"
                    name="priv"
                    placeholder="Ingresa numero privado"
                    onChange={handlePrivateValueMethod}
                  />
                </div>
              </div>
              <button
                onClick={handleSubmitPrivateFormMethod}
                className="newRanchButtonAccept"
                type="button"
              >
                Crear
              </button>
              <button onClick={closeModalPrivate} className="newRanchButtonCancel" type="button">
                Cancelar
              </button>
            </>
          )}
        </Modal>
      ) : null}
      {/* priv */}

      {/* siniga */}

      {modalnewSiniiga ? (
        <Modal ActiveForm={modalnewSiniiga} openCloseModal={closeModal}>
          {siniigaModel.id != '' ? (
            <>
              {' '}
              <h1>Siniiga por defecto</h1>
              <div style={{ textAlign: 'center' }}>
                <div>
                  <div className="label-gestation">
                    <label>Siniiga</label>
                  </div>
                  <input
                    value={siniigaModel.siniiga}
                    type="text"
                    className="inputRoles InputsConfig"
                    name="siniiga"
                    placeholder="siniiga"
                    onChange={handleSiniigaValue}
                    disabled={true}
                  />
                </div>
              </div>
              <button
                onClick={handleSubmitSiniigaForm}
                className="newRanchButtonAccept"
                type="button"
              >
                Actualizar
              </button>
              <button onClick={closeModal} className="newRanchButtonCancel" type="button">
                Cancelar
              </button>
            </>
          ) : (
            <>
              {' '}
              <h1>Crear Siniiga</h1>
              <div style={{ textAlign: 'center' }}>
                <div>
                  <div className="label-gestation">
                    <label>Siniiga</label>
                  </div>
                  <input
                    value={siniigaModel.siniiga}
                    type="text"
                    className="inputRoles InputsConfig"
                    name="siniiga"
                    placeholder="siniiga"
                    onChange={handleSiniigaValue}
                  />
                </div>
              </div>
              <button
                onClick={handleSubmitSiniigaForm}
                className="newRanchButtonAccept"
                type="button"
              >
                Crear
              </button>
              <button onClick={closeModal} className="newRanchButtonCancel" type="button">
                Cancelar
              </button>
            </>
          )}
        </Modal>
      ) : null}

      {/* siniga */}

      {isOpenModalGanado && (
        <Modal
          ActiveForm={isOpenModalGanado}
          openCloseModal={() => {
            setisOpenModalGanado(false);
          }}
          submitNewItem={submitNewCow}
        >
          <NewGanado
            form={Ganado}
            handleCowFormInputs={handleCowFormInputs}
            heigth={'650px'}
            submitErrors={submitErrors}
          />
        </Modal>
      )}

      {isOpenModalDetails && dataFromID && (
        <ModalDetails
          closeFunction={() => {
            openModalDetails(false);
          }}
          data={dataFromID}
          item={item}
          getInitialData={getInitialData}
          getId={(id) => {
            getId(id);
          }}
          info={info}
          configGetPages={configGetPages}
        />
      )}
    </div>
  );
};

export default DetailsComponent;
