import axios from 'axios';

const baseURL = process.env.REACT_APP_API_URL || 'http://localhost:3001/api';
const token = sessionStorage.getItem('hatoToken');

const requestHeaders = {
    'Content-Type': 'application/json; charset=utf-8',
    Authorization: `Bearer ${token}`,
};

const AUTHSERVICE = axios.create({ baseURL, headers: requestHeaders });


//  AUTHSERVICE.interceptors.response.use(
//    (config) => {
//      if (config?.data?.data?.msg === 'Credenciales obtenidas') {
//        sessionStorage.setItem('hatoToken', `${config?.data?.data?.Token}`);
//     }

//      return Promise.resolve(config);
//    },
//    (error) => {
//      if (!error?.response?.data?.status && error?.response?.data?.msg === 'No logged in') {
//        sessionStorage.removeItem('hatoToken');
//        window.location = '/';
//      }

//      return Promise.reject(error);
//    }
//  );

export { AUTHSERVICE };
