import React, { useState, useEffect } from "react";
import "./styles.css";

const Switch = ({ id, isActive, onChange }) => {
  const [active, setActive] = useState(isActive);

  const handleChange = (e) => {
    setActive(!active);
    onChange(id);
  };
  useEffect(() => {
    setActive(isActive);
  }, [isActive]);
  return (
    <div
      className={active ? "switchbutton" : "switchbuttonDeactive"}
      id={id}
      onClick={handleChange}
    >
      <span
        className={active ? "finalPosition disabledClass" : "initialPosiotion"}
      ></span>
    </div>
  );
};

export default Switch;
